import { useCallback, useEffect, useState } from "react";

export const useCalculator = (
    defaultIncome: number,
): { wayhomeValue: number; mortgageValue: number; handleIncomeChange: (income: number) => void } => {
    const [wayhomeValue, updateWayhomeValue] = useState<number>(0);
    const [mortgageValue, updateMortgageValue] = useState<number>(0);

    const handleIncomeChange = useCallback((income) => {
        calculateWayhomeValue(income);
        calculateMortgageValue(income);
    }, []);

    useEffect(() => {
        handleIncomeChange(defaultIncome);
    }, [handleIncomeChange, defaultIncome]);

    const calculateWayhomeValue = (income: number) => {
        const toTheNearest = 5000;
        const value = Math.round((income * 10) / toTheNearest) * toTheNearest;
        updateWayhomeValue(value > 500000 ? 500000 : value);
    };

    const calculateMortgageValue = (income: number) => {
        const toTheNearest = 5000;
        const value = Math.round((income * 3.53) / toTheNearest) * toTheNearest;
        updateMortgageValue(value);
    };

    return { wayhomeValue, mortgageValue, handleIncomeChange };
};
