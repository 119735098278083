import { action } from "typesafe-actions";

import { UIActionTypes } from "./types";

export const showLoadingIndicator = () => action(UIActionTypes.SHOW_LOADING);

export const hideLoadingIndicator = () => action(UIActionTypes.HIDE_LOADING);

export const showApiError = (error: string) => action(UIActionTypes.SHOW_API_ERROR, error);

export const hideApiError = () => action(UIActionTypes.HIDE_API_ERROR);

export const openCloseLoginModal = (payload?: { isLoginModalShowing: boolean; shouldRedirectOnLogin: boolean }) => {
    const { isLoginModalShowing, shouldRedirectOnLogin = true } = payload ?? {};
    return action(UIActionTypes.OPEN_CLOSE_SIGN_MODAL, { isLoginModalShowing, shouldRedirectOnLogin });
};
