import { useCallback, useState } from "react";

const apiBaseURL = process.env.REACT_APP_API_ENDPOINT;

export const useApi = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const callApi = useCallback(
        async (path: string, method: "GET" | "POST" | "PATCH", authToken?: string | null, data?: unknown) => {
            setError(null);
            setIsLoading(true);

            try {
                const headers = { "Content-Type": "application/json" };
                const bodyData = data ? { body: JSON.stringify(data) } : {};
                if (authToken) {
                    headers["Authorization"] = `Bearer ${authToken}`;
                }
                const response = await fetch(`${apiBaseURL}${path}`, {
                    headers,
                    method,
                    ...bodyData,
                });
                const body = await response.json();

                if (!response.ok) {
                    setError(body);
                }
                setIsLoading(false);

                return { ok: response.ok, status: response.status, body };
            } catch (caughtError) {
                setError(caughtError as string | null);
                setIsLoading(false);
                return { ok: false, status: 0, body: caughtError };
            }
        },
        [],
    );

    const get = useCallback(
        async (path: string, authToken?: string | null) => callApi(path, "GET", authToken),
        [callApi],
    );

    const post = useCallback(
        async (path: string, data: unknown, authToken?: string | null) => callApi(path, "POST", authToken, data),
        [callApi],
    );

    const patch = useCallback(
        async (path: string, data: unknown, authToken?: string | null) => callApi(path, "PATCH", authToken, data),
        [callApi],
    );

    return { isLoading, error, get, post, patch };
};
