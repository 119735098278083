import { useCallback, useState } from "react";

import { captureException } from "@sentry/browser";

import { useApi } from "./use-api";
import { useAuth } from "./use-auth";

type TListingAPI = {
    source_id: string;
    purpleco_id: string;
    listing_id: string;
    image_url: string;
    price: number;
    description: string;
    address: string;
    agent_name: string;
    agent_phone: string;
    agent_email: string;
    is_visible: boolean;
    is_suitable: boolean;
    monthly_rent: number;
    match_id: string | null;
};

export type TMappedListing = {
    sourceId: string;
    purplecoId: string;
    listingId: string;
    imageUrl: string;
    price: number;
    monthlyRent: number;
    description: string;
    address: string;
    agentName: string;
    agentPhone: string;
    agentEmail: string;
    matchId: string | null;
};

interface IMappedListing {
    listing: TMappedListing | null;
    isVisible: boolean;
    isSuitable: boolean;
}

const mapListingFromAPI = (data: TListingAPI) => ({
    sourceId: data.source_id,
    purplecoId: data.purpleco_id,
    listingId: data.listing_id,
    imageUrl: data.image_url,
    price: data.price,
    description: data.description,
    address: data.address,
    agentName: data.agent_name,
    agentPhone: data.agent_phone,
    agentEmail: data.agent_email,
    isVisible: data.is_visible,
    isSuitable: data.is_suitable,
    monthlyRent: data.monthly_rent,
    matchId: data.match_id,
});

export const useListing = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState<boolean>();
    const [isSuitable, setIsSuitable] = useState<boolean>();
    const [listing, setListing] = useState<TMappedListing | null>(null);

    const { user } = useAuth();

    const { get } = useApi();

    /**
     * Check if Zoopla or Rightmove link exists in the listing database
     */
    const getSuitableListing = useCallback(
        async ({
            sourceName,
            sourceId,
        }: {
            sourceId: string;
            sourceName: "rightmove" | "zoopla" | "";
        }): Promise<IMappedListing | null> => {
            setIsLoading(true);
            try {
                const token = await user.fbUser?.getIdToken();
                const { body, ok } = await get(
                    `/v1/off-platform-listings?source_id=${sourceId}&source=${sourceName}`,
                    token,
                );

                if (!ok) {
                    throw Error(body);
                }

                const mappedBody = mapListingFromAPI(body ?? {});

                const { isVisible: excluded, isSuitable: isMatch, ...rest } = mappedBody;

                setIsVisible(excluded);
                setIsSuitable(isMatch);
                setListing(rest);
                return { isVisible: excluded, isSuitable: isMatch, listing: rest };
            } catch (error) {
                captureException(error);
                return null;
            } finally {
                setIsLoading(false);
            }
        },
        [user, get],
    );

    return {
        getSuitableListing,
        isLoading,
        listing,
        isSuitable,
        isVisible,
    };
};
