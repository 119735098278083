import { Reducer } from "redux";

import { dataToObjectByKey } from "utils/formatter";
import { globaliseSelectors } from "utils/globalise-selectors";

import { CustomerListing, ListingsActionTypes, ListingsState } from "./types";

const initialState: ListingsState = {
    byID: {},
    totalResults: null,
    currentArea: { name: "", latitude: "", longitude: "", radius: "", userSelectedRadius: undefined },
    autoGeneratedSearchRadius: false,
};

export const listingsReducer: Reducer<ListingsState> = (state = initialState, action) => {
    switch (action.type) {
        case ListingsActionTypes.FETCH_ALL_SUCCESS:
            return {
                ...state,
                byID: dataToObjectByKey(action.payload.listings, "ID"),
                totalResults: action.payload.totalResults,
            };

        case ListingsActionTypes.FETCH_SAVED_AREA_SUCCESS:
            return {
                ...state,
                autoGeneratedSearchRadius: action.payload.autoGeneratedSearchRadius,
                currentArea: action.payload.currentAreaInfo,
            };

        case ListingsActionTypes.UPDATE_CURRENT_AREA_INFO:
            return {
                ...state,
                currentArea: action.payload,
            };

        case ListingsActionTypes.UPDATE_LISTING:
            return Object.assign({}, state, {
                byID: Object.assign({}, state.byID, {
                    [action.payload.ID]: Object.assign({}, state.byID[action.payload.ID], action.payload),
                }),
            });

        case ListingsActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                byID: {
                    ...state.byID,
                    [action.payload.listing.ID]: action.payload.listing,
                },
            };

        default:
            return state;
    }
};

export const getAllListings = (state: ListingsState) => state.byID;

export const getSingleListing = (state: ListingsState, ID: string) => state.byID[ID] || {};

export const getAllListingsAsArray = (state: ListingsState): CustomerListing[] =>
    Object.keys(state.byID).map((key) => state.byID[key]);

export const getTotalResults = (state: ListingsState) => state.totalResults;

export const getCurrentAreaInfo = (state: ListingsState) => state.currentArea;

export const getAutoGeneratedSearchRadius = (state: ListingsState) => state.autoGeneratedSearchRadius;

export const listingsSelectors = globaliseSelectors(
    {
        getAllListings,
        getAllListingsAsArray,
        getTotalResults,
        getCurrentAreaInfo,
        getSingleListing,
        getAutoGeneratedSearchRadius,
    },
    "listings",
);
