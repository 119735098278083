import React from "react";

import { PageWidth, ShortParagraphCss } from "@wayhome-uk/design-system";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ArrowBottom } from "components/icons/icon-arrow-bottom";
import { Logo } from "components/icons/icon-logo";

export interface INavbarLoginMobileHeaderProps {
    className?: string;
    isShowing: boolean;
    onClick?: () => void;
}

export const NavbarLoginMobileHeader = ({ className, onClick, isShowing }: INavbarLoginMobileHeaderProps) => (
    <Wrapper data-test-id="navbar-login-mobile-header" className={className}>
        <PageWidth>
            <Container>
                <Link data-test-id="navbar-login-mobile-logo" to={"/"} title={"Wayhome"}>
                    <StyledLogo />
                </Link>
                <ToggleButton onClick={onClick} isShowing={isShowing}>
                    {isShowing ? "Close" : "Menu"} <ArrowBottom />
                </ToggleButton>
            </Container>
        </PageWidth>
    </Wrapper>
);

const Wrapper = styled.div`
    background: ${({ theme }) => theme.neutral0};
    position: sticky;
    top: 0;
`;

const StyledLogo = styled(Logo)`
    fill: ${({ theme }) => theme.primary500};
    margin: ${({ theme }) => theme.spacing24} 0;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const ToggleButton = styled.span<{ isShowing?: boolean }>`
    ${ShortParagraphCss};
    font-weight: 600;
    display: inline-flex;
    margin-left: auto;
    color: ${({ theme }) => theme.primary500};
    padding: ${({ theme }) => theme.spacing12} ${({ theme }) => theme.spacing16};
    cursor: pointer;
    align-items: center;
    border-radius: ${({ theme }) => theme.radius1};

    font-size: ${({ theme }) => theme.type16};
    line-height: 1.6;

    :hover,
    :focus {
        color: ${({ theme }) => theme.primary800};
        text-decoration: underline;
    }

    svg {
        fill: ${({ theme }) => theme.primary500};
        display: inline-block;
        width: 10px;
        height: 6px;
        margin-left: ${({ theme }) => theme.spacing8};
        transform: ${({ isShowing }) => (isShowing ? "rotate(180deg)" : "rotate(0deg)")};
    }
`;
