import { useState } from "react";

import { captureException } from "@sentry/browser";
import useDeepCompareEffect from "use-deep-compare-effect";

import { IEstimatedBreakdownCostData } from "../pages/property-detail/types";
import { useApi } from "./use-api";
import { useAuth } from "./use-auth";

type TJokerCategory = "" | "BRONZE" | "SILVER" | "GOLD";

export interface IEstimatedBreakdownCostDataCustomer extends IEstimatedBreakdownCostData {
    ownership: number;
    monthly_rent: number;
    monthly_total: number;
}

export interface IFinancials {
    wayhome: IEstimatedBreakdownCostData;
    customer: IEstimatedBreakdownCostDataCustomer;
    funding_partner: IEstimatedBreakdownCostData;
    full_cost: IEstimatedBreakdownCostData;
}

export interface IMatch {
    agreedDeposit: number | null;
    agreedRent: number | null;
    agreedToWayhomeOffer: boolean | null;
    agreedPrice: number | null;
    applicationId: string;
    createdAt: string;
    customerId: string;
    feeReduction: number | null;
    financials: IFinancials | null;
    id: string;
    jokerCategory: TJokerCategory | null;
    listingId: string;
    offerStatus: string;
    updatedAt: string;
    lockInPeriodMonths: number | null;
}

export interface IMatchFromApi {
    agreed_deposit: number | null;
    agreed_rent: number | null;
    agreed_to_wayhome_offer: boolean | null;
    agreed_price: number | null;
    application_id: string;
    created_at: string;
    customer_id: string;
    fee_reduction: number | null;
    financials: IFinancials | null;
    id: string;
    joker_category: string | null;
    listing_id: string;
    offer_status: string;
    updated_at: string;
    lock_in_period_months: number | null;
}

export const mapMatchFromApi = (fromApi: IMatchFromApi): IMatch => ({
    id: fromApi.id,
    agreedDeposit: fromApi.agreed_deposit,
    agreedRent: fromApi.agreed_rent,
    agreedToWayhomeOffer: fromApi.agreed_to_wayhome_offer,
    agreedPrice: fromApi.agreed_price,
    applicationId: fromApi.application_id,
    createdAt: fromApi.created_at,
    customerId: fromApi.customer_id,
    feeReduction: fromApi.fee_reduction,
    jokerCategory: fromApi.joker_category as TJokerCategory,
    listingId: fromApi.listing_id,
    offerStatus: fromApi.offer_status,
    updatedAt: fromApi.updated_at,
    lockInPeriodMonths: fromApi.lock_in_period_months,
    financials: fromApi.financials,
});

export const useMatches = () => {
    const [matchesData, setMatchesData] = useState<IMatch | null>(null);
    const [isMatchesDataLoading, setIsMatchesDataLoading] = useState(true);

    const { user } = useAuth();
    const { get } = useApi();

    useDeepCompareEffect(() => {
        (async () => {
            const token = await user.fbUser?.getIdToken();
            const applicationId = user.application?.applicationId;

            if (token && applicationId) {
                try {
                    setIsMatchesDataLoading(true);
                    const { ok, body } = await get(
                        `/v1/external-matches?application_id=${applicationId}&is_active=true`,
                        token,
                    );

                    if (ok && body.length) {
                        const convertedMatchesData = mapMatchFromApi(body[0] as IMatchFromApi);
                        setMatchesData(convertedMatchesData);
                        setIsMatchesDataLoading(false);
                    }
                } catch (error) {
                    captureException(error);
                    setIsMatchesDataLoading(false);
                }
            }
        })();
    }, [user]);

    return { matchesData, isMatchesDataLoading };
};
