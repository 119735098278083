import React, { Component } from "react";

import { Theme } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components/macro";

import SliderArrows from "components/media/svg/radio-arrows.svg";
import { MINIMUM_HOUSEHOLD_INCOME } from "config/constants";
import { NewLongParagraph } from "config/theme";
import { valueToCurrency } from "utils/formatter";

export interface IProps extends IBaseComponent {
    onAfterChange: (value: number) => void;
}

export interface State {
    value: number;
}

export class InputIncome extends Component<IProps, State> {
    state = { value: MINIMUM_HOUSEHOLD_INCOME };

    handleChange = (value: number) => {
        this.setState({ value });
    };

    render() {
        return (
            <>
                <Text>
                    With a pre-tax annual household income of <span>{valueToCurrency(this.state.value)}</span>
                </Text>
                <Wrapper>
                    <Slider
                        trackStyle={{
                            display: "none",
                        }}
                        railStyle={{
                            backgroundColor: Theme.action75,
                            height: 16,
                            borderRadius: 12,
                            position: "absolute",
                        }}
                        handleStyle={{
                            backgroundImage: `url(${SliderArrows})`,
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            border: "none",
                            height: 40,
                            width: 40,
                            marginTop: -12,
                        }}
                        value={this.state.value}
                        step={5000}
                        min={MINIMUM_HOUSEHOLD_INCOME}
                        max={99000}
                        onChange={this.handleChange}
                        onAfterChange={this.props.onAfterChange}
                    />
                </Wrapper>
            </>
        );
    }
}

const Wrapper = styled.div`
    max-width: 300px;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        max-width: 420px;
    }
`;

const Text = styled.p`
    ${NewLongParagraph};
    padding: ${({ theme }) => theme.spacing24} 0 ${({ theme }) => theme.spacing32} 0;

    span {
        color: ${({ theme }) => theme.primary500};
        font-weight: 600;
    }

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing24} 0 ${({ theme }) => theme.spacing32};
    }
`;
