import React from "react";

export interface Props {
    className?: string;
}

export const ArrowBottom = ({ className }: Props) => (
    <svg className={className} width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 3.67903L1.71692 0.302848C1.3319 -0.0930976 0.698793 -0.101949 0.302848 0.283078C-0.0930976 0.668105 -0.101949 1.30121 0.283078 1.69715L4.09463 5.6168L4.09536 5.61755C4.59241 6.128 5.4096 6.1274 5.9059 5.61625L9.71692 1.69715C10.1019 1.30121 10.0931 0.668105 9.69715 0.283078C9.30121 -0.101949 8.6681 -0.0930976 8.28308 0.302848L5 3.67903Z" />
    </svg>
);
