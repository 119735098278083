import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../../hooks";
import { AppState } from "../../../store";
import { openCloseLoginModal } from "../../../store/ui";
import { logoutAction } from "../../../store/user";
import { mapWaitListStatusToNumber } from "../../../utils/application-helpers";
import { NavbarLogin } from "../../navbar-login";
import { mainMenu } from "./login-menu-data";
import { LoginModal } from "./login-modal";

export const Login: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const signInQueryParam = queryParams.get("sign-in") || "";
    const emailQueryParam = queryParams.get("email") || "";
    const passwordQueryParam = queryParams.get("password") || "";

    const { isLoginModalShowing, shouldRedirectOnLogin } = useSelector((store: AppState) => store.ui);
    const { user, signOut } = useAuth();
    const dispatch = useDispatch();

    const waitListNumericValue = mapWaitListStatusToNumber(user.application?.waitListStatus);
    const isWaitlist = waitListNumericValue === 4;

    const setIsLoginModalShowing = useCallback(
        (value: boolean) => {
            dispatch(openCloseLoginModal({ isLoginModalShowing: value, shouldRedirectOnLogin: true }));
        },
        [dispatch],
    );

    useEffect(() => {
        if (signInQueryParam) {
            setIsLoginModalShowing(true);
        }
    }, [signInQueryParam, setIsLoginModalShowing]);

    const handleLogout = async () => {
        await signOut();
        dispatch(logoutAction());
    };

    const handleLogin = () => setIsLoginModalShowing(true);
    const handleClose = () => setIsLoginModalShowing(false);
    const menuOptions = mainMenu(handleLogin, handleLogout, user.uuid, isWaitlist);

    return (
        <>
            <NavbarLogin isLoggedIn={!!user.uuid} menu={menuOptions} />
            {isLoginModalShowing ? (
                <LoginModal
                    redirectOnLogin={shouldRedirectOnLogin}
                    onClose={handleClose}
                    email={emailQueryParam}
                    password={passwordQueryParam}
                />
            ) : null}
        </>
    );
};
