import styled from "styled-components/macro";

import { NewLongParagraph } from "config/theme";

export const Button = styled.button`
    ${NewLongParagraph};
    line-height: 1;
    text-align: center;
    font-weight: 700;
    max-width: 100%;
    border-radius: ${({ theme }) => theme.radius1};
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
`;

export const PrimaryButton = styled(Button)`
    color: ${({ theme }) => theme.neutral0};
    background: ${({ theme }) => theme.primary500};
    border: none;
`;

export const SecondaryButton = styled(Button)`
    color: ${({ theme }) => theme.primary500};
    background: ${({ theme }) => theme.primary50};
    border: 1px solid ${({ theme }) => theme.primary500};
`;
