import React from "react";

import { BaseComponent } from "types";

import { Theme } from "config/theme";

interface Props extends BaseComponent {
    tickColour?: string;
    backgroundColour?: string;
}

export const IconTwitter: React.FC<Props> = ({
    className,
    tickColour = Theme.neutral800,
    backgroundColour = Theme.neutral0,
}) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.91857 21.8761C17.2205 21.8761 21.7613 14.9981 21.7613 9.0334C21.7613 8.83807 21.7613 8.64359 21.7481 8.44997C22.6314 7.81098 23.394 7.01989 24 6.11359C23.1762 6.47861 22.3023 6.71795 21.4075 6.82373C22.3496 6.25981 23.0551 5.37216 23.3919 4.32716C22.5059 4.85289 21.5365 5.22345 20.5258 5.42278C19.6723 4.51524 18.4804 4 17.2347 4C14.7561 4 12.7167 6.03947 12.7167 8.51791C12.7167 8.86174 12.756 9.2045 12.8337 9.5394C9.20633 9.3576 5.82203 7.64186 3.53119 4.8235C2.33993 6.87422 2.95631 9.53184 4.92862 10.8488C4.2104 10.8276 3.5076 10.6338 2.88 10.2839V10.3411C2.8806 12.4815 4.40311 14.3419 6.50119 14.7657C5.83672 14.9469 5.13938 14.9734 4.46314 14.8432C5.05297 16.6773 6.75385 17.9416 8.68009 17.9777C7.08209 19.2336 5.10694 19.9158 3.07448 19.9137C2.71534 19.913 2.35655 19.8913 2 19.8486C4.06396 21.1731 6.46617 21.8759 8.91857 21.8726"
            fill="white"
        />
    </svg>
);
