import React from "react";

import { BaseComponent } from "types";

import { Theme } from "config/theme";

interface Props extends BaseComponent {
    tickColour?: string;
    backgroundColour?: string;
}

export const IconFacebook: React.FC<Props> = ({
    className,
    tickColour = Theme.neutral800,
    backgroundColour = Theme.neutral0,
}) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.9345 14.165L18.4854 10.5728H15.0388V8.24168C15.0388 7.25891 15.5203 6.30098 17.064 6.30098H18.6311V3.24273C18.6311 3.24273 17.2089 3 15.8493 3C13.0106 3 11.1554 4.72039 11.1554 7.83496V10.5728H8V14.165H11.1554V22.849C11.788 22.9483 12.4365 23 13.0971 23C13.7577 23 14.4062 22.9483 15.0388 22.849V14.165H17.9345Z"
            fill="white"
        />
    </svg>
);
