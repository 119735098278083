/* eslint-disable @typescript-eslint/no-explicit-any */
interface IWindow {
    intercomSettings: Record<string, string>;
    Intercom: (event: string, eventData?: Record<string, string>) => void;
    attachEvent: (event: string, cb: () => void) => void;
}

type TWin = IWindow & Window & typeof globalThis;

export const w = window as unknown as TWin;

export const loadIntercomWidget = () => {
    const intercomAppId = process.env.REACT_APP_INTERCOM_ID as string;

    w.intercomSettings = {
        app_id: intercomAppId,
    };

    const ic = w.Intercom;

    if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
    } else {
        const d = document;

        const i = function () {
            // eslint-disable-next-line prefer-rest-params
            (i as any).c(arguments);
        };

        (i as any).q = [];

        (i as any).c = (args: any) => {
            (i as any).q.push(args);
        };

        w.Intercom = i;

        const l = () => {
            const s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${intercomAppId}`;
            const x = d.getElementsByTagName("script")[0];

            if (x && x.parentNode) {
                x.parentNode.insertBefore(s, x);
            }
        };

        if (w.attachEvent) {
            w.attachEvent("onload", l);
        } else {
            w.addEventListener("load", l, false);
        }
    }
};
