import { Theme as WayhomeTheme, pxToRem } from "@wayhome-uk/design-system";
import { IStyledComponentTheme } from "interfaces";
import { css } from "styled-components";

export const Theme: IStyledComponentTheme = {
    ...WayhomeTheme,

    multiChoiceBorderWidth: "1px",
    tinyMobileMinWidth: "450px",
    smallMobileMinWidth: "630px",
    red700: "#a03f4e",

    // spacing not in the current design system
    spacing18: `${pxToRem(18)}rem`,

    // types not in the current design system
    type40: `${pxToRem(40)}rem`,
};

export const NewHeader1 = css`
    font-family: ${({ theme }) => theme.fontFamilySerif};
    font-size: ${({ theme }) => theme.type32};
    line-height: 1.2;
    color: ${({ theme }) => theme.neutral800};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: ${({ theme }) => theme.type48};
    }
`;

export const NewHeader2 = css`
    font-family: ${({ theme }) => theme.fontFamilySerif};
    font-size: ${({ theme }) => theme.type28};
    line-height: 1.2;
    color: ${({ theme }) => theme.neutral800};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: ${({ theme }) => theme.type40};
    }
`;

export const NewLongParagraph = css`
    font-family: ${({ theme }) => theme.fontFamilySansSerif};
    font-size: ${(props) => props.theme.type16};
    line-height: 1.6;
    color: ${({ theme }) => theme.neutral800};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: ${({ theme }) => theme.type18};
    }
`;
