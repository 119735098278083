import React, { useState } from "react";

import styled from "styled-components/macro";

import { NewLongParagraph } from "config/theme";

import { PayneFamily } from "./payne-family";

export const Generic = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <PayneFamily>
            <>
                <BoldParagraph>
                    Before we helped The Payne family buy their dream home, they were struggling to save enough
                    deposit...
                </BoldParagraph>
                <Paragraph>
                    “For anyone who has good credit, reasonable household income but not a big enough deposit, this is
                    the perfect route to getting on the ladder..
                </Paragraph>
                <Paragraph>
                    It was important for us to own our home and have that sense of security, and we treat our
                    staircasing payments just as we would a mortgage.”
                </Paragraph>

                {isOpen ? (
                    <div
                        id="ExpandableSection"
                        role="region"
                        aria-labelledby="readMore"
                        data-testid="ExpandableSection"
                    >
                        <Paragraph>
                            Before they found us, they were renting and were struggling to buy that 3-bed home they
                            really wanted.
                        </Paragraph>
                        <Paragraph>
                            They did all the sensible things like reading up on the different options out there and
                            seeing what they could afford.
                        </Paragraph>
                        <Paragraph>However, they didn&apos;t have much luck.</Paragraph>
                        <Paragraph>
                            Despite earning good incomes, they couldn&apos;t get a big enough mortgage to make their
                            ideal move a reality.
                        </Paragraph>
                        <Paragraph>
                            &ldquo;We had a really nasty shock when we realised how little most high street lenders
                            would be prepared to lend us given the size of our deposit.
                        </Paragraph>
                        <Paragraph>
                            It really opened my eyes to just how unfair the mortgage system is.&rdquo;
                        </Paragraph>
                        <Paragraph>That&apos;s where we come into their story.</Paragraph>
                        <Paragraph>
                            Having found Wayhome when looking at new ways to buy, they got their homeownership search
                            started with us.
                        </Paragraph>
                        <Paragraph>
                            After viewing a few homes, they soon found just what they&apos;d been looking for. A 3-bed
                            house in the area they know and love.
                        </Paragraph>
                        <Paragraph>
                            After viewing a few homes, they found one in an area they love, made an offer and moved in —
                            just in time for Christmas.
                        </Paragraph>
                    </div>
                ) : null}

                <ReadMoreButton
                    aria-expanded={isOpen}
                    aria-controls="ExpandableSection"
                    id="readMore"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {`Read ${isOpen ? "less" : "more"} about the Payne family`}
                </ReadMoreButton>
            </>
        </PayneFamily>
    );
};

const Paragraph = styled.p`
    ${NewLongParagraph};
    font-weight: 400;
    color: ${({ theme }) => theme.neutral0};
    margin-bottom: ${({ theme }) => theme.spacing8};
`;

const BoldParagraph = styled(Paragraph)`
    font-weight: 700;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing24};
    }
`;

const ReadMoreButton = styled.button`
    ${NewLongParagraph};
    display: block;
    background: none;
    border: none;
    padding: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.neutral0};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    margin-bottom: ${({ theme }) => theme.spacing24};
    cursor: pointer;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-top: ${({ theme }) => theme.spacing24};
    }
`;
