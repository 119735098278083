export interface ICustomerMatches {
    count: number;
    latestCreatedAt: string;
    next: string;
    results: IListingData[];
    page: number;
    pageSize: number;
    matchesDisabledForUser: boolean;
}

export interface IListingData {
    address: string;
    agentEmail: string | null;
    agentName: string;
    agentPhone: string;
    canGoOnViewings: boolean;
    createdAt: string;
    deposit: number;
    distanceMeters: number;
    distanceMiles: number;
    fullRent: number;
    imageURL: string;
    isLiked: boolean;
    isViewed: boolean;
    latitude: number;
    listingID: string;
    longitude: number;
    minOwnership: number;
    ownership: number;
    price: number;
    promotionalRent: number;
    rent: number;
    rightmoveURL: string | null;
    title: string;
    totalBathrooms: 1;
    totalBedrooms: 1;
    visible: boolean;
    visibleAtSource: boolean;
    zooplaURL: string;
}

export interface CustomerListing {
    ID: string;
    title: string;
    address: string | null;
    zooplaURL: string | null;
    rightmoveURL: string | null;
    imageURL: string | null;
    totalBedrooms: number;
    totalBathrooms: number;
    rent: number;
    fullRent: number;
    deposit: number;
    price: number;
    distanceMiles: number;
    description: string;
    isLiked: boolean;
    isViewed: boolean;
    isViable: boolean;
    latitude: number;
    longitude: number;
    createdAt: string;
    ownership: number;
    minOwnership: number;
    isReadyToBuy: boolean;
    isAvailable: boolean;
    agentName: string;
    agentPhone: string;
    promotionalRent: number | null;
    promotionalRentBasedOnFullRent: number | null;
}

export interface ListingsState {
    readonly byID: { [ID: string]: CustomerListing };
    readonly totalResults: number | null;
    readonly currentArea: CurrentAreaInfo;
    readonly autoGeneratedSearchRadius: boolean;
}

export interface CurrentAreaInfo {
    name: string;
    latitude: string;
    longitude: string;
    radius: string;
    jobInformationGathered?: boolean;
    userSelectedRadius?: boolean;
}

export const ListingsActionTypes = {
    FETCH_REQUEST: "listings/FETCH_REQUEST",
    FETCH_SUCCESS: "listings/FETCH_SUCCESS",
    FETCH_ALL_REQUEST: "listings/FETCH_ALL_REQUEST",
    FETCH_ALL_SUCCESS: "listings/FETCH_ALL_SUCCESS",
    UPDATE_TOTAL_RESULTS: "listings/UPDATE_TOTAL_RESULTS",
    FETCH_SAVED_AREA_SUCCESS: "listings/FETCH_SAVED_AREA_SUCCESS",
    UPDATE_CURRENT_AREA_INFO: "listings/UPDATE_CURRENT_AREA_INFO",
    UPDATE_LISTING: "listings/UPDATE_LISTING",
    UPDATE_FILTERS: "listings/UPDATE_FILTERS",
};

export interface ListingsApiParams {
    areaName?: string;
    sortBy?: string;
    filterBy?: string;
    page?: number | string;
    pageSize?: number | string;
    latitude?: number | string;
    longitude?: number | string;
    radius?: number | string;
    minTotalBedrooms?: number | string;
    maxTotalBedrooms?: number | string;
    minRent?: number | string;
    maxRent?: number | string;
    propertyType?: string;
    feature?: string;
}

export interface CustomerListingDetails {
    ID: string;
    title?: string;
    address?: string | null;
    zooplaURL?: string | null;
    imageURL?: string | null;
    totalBedrooms?: number;
    totalBathrooms?: number;
    rent?: number;
    deposit?: number;
    price?: number;
    distanceMiles?: number;
    description?: string;
    isLiked?: boolean;
    isViewed?: boolean;
    isViable?: boolean;
    latitude?: number;
    longitude?: number;
    createdAt?: string;
    ownership?: number;
    minOwnership?: number;
}

export interface CustomerListingsApiParams extends ListingsApiParams {
    customerID: string;
    hasPropertyUnderOffer?: boolean;
    noTrack: boolean;
    userSelectedRadius?: boolean;
    locationChanged?: boolean;
}
