import React from "react";

import { PageWidth } from "@wayhome-uk/design-system";
import styled from "styled-components/macro";

import { SecondaryButton, SecondaryLink } from "components/buttons-and-links";
import RadioWithPlants from "components/media/svg/radio-with-plants.svg";
import { routePaths } from "config/route-paths";
import { NewHeader2, NewLongParagraph } from "config/theme";

export interface Props {
    className?: string;
}

export const WhereWeBuy = ({ className }: Props) => (
    <Wrapper className={className}>
        <PageWidth>
            <Container>
                <IconWrapper>
                    <Icon src={RadioWithPlants} />
                </IconWrapper>

                <div>
                    <Title>Where we buy homes</Title>
                    <Text>Find out which locations we're helping people buy homes in.</Text>
                    <SecondaryButton>
                        <SecondaryLink to={routePaths.marketing.whereWeBuy.path}>See where we buy</SecondaryLink>
                    </SecondaryButton>
                </div>
            </Container>
        </PageWidth>
    </Wrapper>
);

const Wrapper = styled.div`
    background: ${(props) => props.theme.neutral75};
    padding: ${(props) => props.theme.spacing40} 0;

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        padding: ${(props) => props.theme.spacing64} 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const IconWrapper = styled.div`
    display: inline-block;
`;

const Icon = styled.img`
    display: none;

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        display: block;
        margin-right: ${(props) => props.theme.spacing48};
        width: 289px;
        height: auto;
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    font-weight: 500;
    margin-bottom: ${(props) => props.theme.spacing24};
`;

const Text = styled.p`
    ${NewLongParagraph};
    margin-bottom: ${(props) => props.theme.spacing24};
`;
