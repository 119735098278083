import React from "react";

import { PageWidth, ShortLeadCss, VerticalSpacing } from "@wayhome-uk/design-system";
import styled, { css } from "styled-components/macro";

import { SecondaryButton } from "components/buttons-and-links";
import HowItWorks1 from "components/media/svg/how-it-works/how-it-works-1.svg";
import HowItWorks2 from "components/media/svg/how-it-works/how-it-works-2.svg";
import HowItWorks3 from "components/media/svg/how-it-works/how-it-works-3.svg";
import HowItWorks4 from "components/media/svg/how-it-works/how-it-works-4.svg";
import HowItWorks5 from "components/media/svg/how-it-works/how-it-works-5.svg";
import MobileHowItWorks1 from "components/media/svg/how-it-works/mobile-how-it-works-1.svg";
import MobileHowItWorks2 from "components/media/svg/how-it-works/mobile-how-it-works-2.svg";
import MobileHowItWorks3 from "components/media/svg/how-it-works/mobile-how-it-works-3.svg";
import MobileHowItWorks4 from "components/media/svg/how-it-works/mobile-how-it-works-4.svg";
import MobileHowItWorks5 from "components/media/svg/how-it-works/mobile-how-it-works-5.svg";
import { routePaths } from "config/route-paths";
import { NewHeader2, NewLongParagraph } from "config/theme";

export interface Props {
    className?: string;
}

export const HowItWorks = ({ className }: Props) => (
    <Wrapper>
        <PageWidth id="scroll">
            <Title>How Gradual Homeownership works</Title>
            <List>
                <ListItem>
                    <Icon1 />
                    <TextWrapper>
                        <TextHeading>You buy the bit you can afford today</TextHeading>
                        <VerticalSpacing size={8} />
                        <Text>Our funding partners pay for the rest</Text>
                    </TextWrapper>
                </ListItem>
                <ListItem>
                    <Icon2 />
                    <TextWrapper>
                        <TextHeading>The house is paid for in full</TextHeading>
                        <VerticalSpacing size={8} />
                        <Text>So, you don’t need a mortgage</Text>
                    </TextWrapper>
                </ListItem>
                <ListItem>
                    <Icon3 />
                    <TextWrapper>
                        <TextHeading>You pay rent on the bit you haven&apos;t bought yet</TextHeading>
                        <VerticalSpacing size={8} />
                        <Text>At fair market value of the home</Text>
                    </TextWrapper>
                </ListItem>
                <ListItem>
                    <Icon4 />
                    <TextWrapper>
                        <TextHeading>But, the home is yours to make your own</TextHeading>
                        <VerticalSpacing size={8} />
                        <Text>Nobody can ask you to leave</Text>
                    </TextWrapper>
                </ListItem>
                <ListItem>
                    <Icon5 />
                    <TextWrapper>
                        <TextHeading>Buy more each month or in lump sums</TextHeading>
                        <VerticalSpacing size={8} />
                        <Text>The more you buy, the less rent you pay</Text>
                    </TextWrapper>
                </ListItem>
            </List>
            <CtaWrapper>
                <SecondaryButton>
                    <SecondaryLink
                        href={routePaths.marketing.howItWorks.path}
                        data-test-id="home-page-how-it-works-about"
                    >
                        More on how it works
                    </SecondaryLink>
                </SecondaryButton>
            </CtaWrapper>
        </PageWidth>
    </Wrapper>
);

const iconWidth = 360;
const iconHeight = 272;
const iconWidthMobile = 272;
const iconHeightMobile = 309;
const textOffset = 312;

const Wrapper = styled.div`
    background: ${({ theme }) => theme.neutral50};
    padding: ${({ theme }) => `${theme.spacing24} 0  ${theme.spacing40}`};

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing96} 0;
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    margin: 0 auto ${({ theme }) => theme.spacing48};
    font-weight: 500;
    text-align: left;
    max-width: none;

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        text-align: center;
    }
`;

const Icon = css`
    display: block;
    margin: 0 auto ${({ theme }) => theme.spacing16};
    width: ${iconWidthMobile}px;
    height: ${iconHeightMobile}px;
    background-size: ${iconWidthMobile}px ${iconHeightMobile}px;
    background-position: 0 0;
    background-repeat: no-repeat;
    flex-shrink: 0;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        width: ${iconWidth}px;
        height: ${iconHeight}px;
        background-size: ${iconWidth}px ${iconHeight}px;
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
    }
`;

const Icon1 = styled.span`
    ${Icon};
    background-image: url(${MobileHowItWorks1});

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        background-image: url(${HowItWorks1});
    }
`;

const Icon2 = styled.span`
    ${Icon};
    background-image: url(${MobileHowItWorks2});

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        background-image: url(${HowItWorks2});
        right: 0;
        left: auto;
    }
`;

const Icon3 = styled.span`
    ${Icon};
    background-image: url(${MobileHowItWorks3});

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        background-image: url(${HowItWorks3});
    }
`;

const Icon4 = styled.span`
    ${Icon};
    background-image: url(${MobileHowItWorks4});

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        background-image: url(${HowItWorks4});
        right: 0;
        left: auto;
    }
`;

const Icon5 = styled.span`
    ${Icon};
    background-image: url(${MobileHowItWorks5});

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        background-image: url(${HowItWorks5});
    }
`;

const TextWrapper = styled.div`
    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding-top: 130px;
        padding-left: ${textOffset}px;
    }
`;

const TextHeading = styled.span`
    ${ShortLeadCss};
    font-weight: 700;
`;

const Text = styled.span`
    ${NewLongParagraph};
    text-align: center;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        text-align: left;
        max-width: 100%;
    }
`;

const List = styled.ol`
    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing64};
    }
`;

const ListItem = styled.li`
    position: relative;
    margin: 0 auto ${({ theme }) => theme.spacing48};
    max-width: 304px;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin: 0 0 ${({ theme }) => theme.spacing32};
        display: flex;
        max-width: 100%;
        min-height: 272px;
    }

    :nth-child(2n) {
        @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
            ${TextWrapper} {
                @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
                    padding-right: ${textOffset}px;
                    padding-left: 0;
                    margin-left: auto;
                }
            }

            ${Text} {
                text-align: right;
            }
        }
    }
`;

const CtaWrapper = styled.div`
    text-align: left;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        text-align: center;
    }
`;

const SecondaryLink = styled.a`
    color: ${({ theme }) => theme.primary500};
`;
