import React from "react";

export const MailOnline = () => (
    <svg
        width="93"
        height="24"
        viewBox="0 0 93 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Mail online"
    >
        <path
            d="M12.1578 10.9738V10.7297L12.1272 7.46431C12.1272 7.46431 12.0509 7.15913 12.5392 7.31172L12.5545 10.9738H12.1578ZM12.3866 16.1008C12.3866 16.1008 12.2188 16.1466 12.173 15.9635V13.5831H12.5697V16.2381L12.3866 16.1008ZM8.15993 10.9738L6.7256 13.1101L6.71034 7.49483L8.25149 8.30354C8.25149 8.30354 8.38882 8.19673 8.46511 8.54769V10.9586H8.15993V10.9738ZM6.13051 16.5128C6.13051 16.5128 6.02369 16.6196 5.99318 16.4365L6.02369 7.76948C6.02369 7.76948 6.05421 7.47957 6.48146 7.46431L6.51198 16.757L6.13051 16.5128ZM19.1005 16.9248C19.1005 16.9248 18.2155 16.5128 18.1087 15.4752V7.08284C18.1087 7.08284 18.2155 7.03706 17.9714 6.86921L14.8738 5.05341C14.8738 5.05341 14.7975 4.88556 14.4924 5.14496L11.8678 6.79292L9.24331 5.34333C9.24331 5.34333 9.1365 5.23652 8.99917 5.31281C8.78555 5.43488 5.688 7.0981 5.688 7.0981L3.38391 5.75532C3.38391 5.75532 3.2771 5.58747 3.13977 5.69428L0.622061 7.31172C0.622061 7.31172 0.271107 7.44905 0.75939 7.72371L0.850944 7.75423L2.60571 9.02071V16.4518L1.70544 16.9706C1.70544 16.9706 1.40026 17.1079 1.67492 17.3063L4.87928 18.9847L7.3207 17.6114C7.3207 17.6114 7.53432 17.4741 7.35122 17.2758L6.77138 16.9095L6.75612 13.5984H8.49563V16.1771L7.70217 16.6654C7.70217 16.6654 7.45803 16.8027 7.77846 17.0163L11.4558 18.9695L13.9278 17.4283C13.9278 17.4283 14.1719 17.3978 14.0041 17.2147L12.8291 16.406V13.461L14.5229 11.0044H12.8139L12.7986 7.44905L14.3855 8.27303C14.3855 8.27303 14.5839 8.21199 14.6297 8.59347V16.6349C14.6297 16.6349 14.8281 18.3134 16.8727 19L19.2531 17.3063C19.2379 17.2452 19.3447 17.1079 19.1005 16.9248Z"
            fill="#333331"
        />
        <path
            d="M24.7004 16.7264C24.7004 16.7264 23.3118 16.0245 22.5794 14.7733C22.3963 14.3918 21.9995 13.4153 22.2895 12.3624C22.2895 12.3624 22.3505 11.1875 23.7085 10.5771L25.5701 11.8283L25.5396 16.5739C25.5396 16.5891 25.2955 17.0011 24.7004 16.7264ZM31.0328 16.8638L29.5222 15.8414V11.7063C29.5222 11.7063 29.4916 11.5079 29.629 11.4927L30.2088 11.0349C30.2088 11.0349 30.3156 10.867 30.1173 10.6534C29.9189 10.4398 26.3941 8.68502 26.3941 8.68502C26.3941 8.68502 26.2568 8.48666 25.9363 8.71554C25.9363 8.71554 22.0758 10.2872 20.5347 12.2861C20.5347 12.2861 19.3445 13.0491 19.5429 16.0856V16.1313V16.1466C19.6039 16.5128 20.0769 18.588 22.9609 18.9695L25.0818 17.2758C25.0818 17.2758 25.1886 17.0774 25.3565 17.2452L28.9271 18.9695L31.0938 17.2758C31.0786 17.2452 31.1854 17.0621 31.0328 16.8638Z"
            fill="#333331"
        />
        <path
            d="M32.9708 9.08174C32.9708 9.08174 30.865 10.3635 30.6972 10.6229C30.5293 10.867 30.6667 10.9738 30.6667 10.9738C30.6667 10.9738 31.5669 11.5537 31.8111 11.63C32.0552 11.7063 31.9789 11.8436 31.9789 11.8436L32.04 16.0703C32.0705 16.1771 32.04 16.467 32.04 16.467C32.04 16.467 31.6738 16.7417 31.4907 16.9095C31.2618 17.1079 31.4449 17.291 31.4449 17.291L34.7103 18.939C34.7103 18.939 36.9228 17.352 37.1059 17.2605C37.3043 17.1842 37.1059 17.0163 37.1059 17.0163C37.1059 17.0163 36.1599 16.467 35.992 16.3907C35.8242 16.2992 35.8852 16.1466 35.8852 16.1466C35.8852 16.1466 35.7631 11.9809 35.8547 11.6147C35.931 11.279 36.0531 11.3858 36.0531 11.3858C36.0531 11.3858 36.3582 11.3095 36.6024 11.1417C36.8465 10.9738 36.6329 10.8365 36.6329 10.8365C36.6329 10.8365 33.5964 9.24959 33.3675 9.05123C33.1386 8.88338 32.9708 9.08174 32.9708 9.08174Z"
            fill="#333331"
        />
        <path
            d="M33.5507 8.53244C33.6576 8.8071 33.9627 8.74606 33.9627 8.74606C35.2445 8.22726 35.7785 6.9913 35.7785 6.9913C36.1142 5.78585 35.336 5.46541 35.336 5.46541C34.6646 5.05342 34.1611 5.38912 34.1611 5.38912C33.3829 5.81636 33.1693 6.62508 33.1693 6.62508C32.9709 7.67794 33.5507 8.53244 33.5507 8.53244Z"
            fill="#333331"
        />
        <path
            d="M37.5487 6.80817C37.5487 6.80817 41.104 5.66376 41.577 5.54169C42.05 5.43488 42.0195 5.6485 42.0195 5.6485C42.0195 5.6485 41.9127 15.7956 41.989 16.1466C42.0806 16.4975 42.2331 16.4212 42.2331 16.4212C42.2331 16.4212 43.0571 17.0011 43.2402 17.0774C43.4386 17.1537 43.3318 17.352 43.3318 17.352L41.3329 18.9084C41.3329 18.9084 38.0522 17.4588 37.7928 17.291C37.4571 17.0774 37.686 16.94 37.686 16.94L38.2658 16.3602C38.2658 16.3602 38.3726 8.05939 38.2201 7.61689C38.0522 7.17438 37.5334 7.02179 37.5334 7.02179C37.4113 6.83869 37.5487 6.80817 37.5487 6.80817Z"
            fill="#333331"
        />
        <path
            d="M54.791 12.4998C54.791 8.83763 53.6008 7.0676 51.0068 7.0676C48.5654 7.0676 47.2226 8.7003 47.2226 12.4998V12.8049C47.2226 16.5281 48.5806 18.1608 51.0068 18.1608C53.4482 18.1608 54.791 16.757 54.791 12.8354V12.4998ZM51.0068 18.9848C48.1381 18.9848 46.185 17.1079 46.185 13.0338V12.3319C46.185 8.39512 47.9703 6.25888 51.0068 6.25888C54.2112 6.25888 55.8286 8.41038 55.8286 12.3319V13.0033C55.8439 17.0164 54.0891 18.9848 51.0068 18.9848Z"
            fill="#333331"
        />
        <path
            d="M64.1447 18.7711V11.7215C64.1447 10.6992 63.336 9.98203 61.9932 9.98203C60.6504 9.98203 59.4144 10.7297 58.8499 11.9962V18.7864H57.858V9.47849H58.7736L58.8041 11.1875C59.2008 10.2109 60.4826 9.17331 62.2678 9.17331C63.9921 9.17331 65.1212 10.1346 65.1212 11.9046V18.7711H64.1447Z"
            fill="#333331"
        />
        <path d="M68.5394 6.42673H67.5629V18.7711H68.5394V6.42673Z" fill="#333331" />
        <path
            d="M71.1183 18.7711H72.1101V9.37165H71.1183V18.7711ZM70.9657 8.31879H72.2169V6.89972H70.9657V8.31879Z"
            fill="#333331"
        />
        <path
            d="M80.9145 18.7711V11.7215C80.9145 10.6992 80.1057 9.98203 78.763 9.98203C77.4202 9.98203 76.1842 10.7297 75.6196 11.9962V18.7864H74.6278V9.47849H75.5433L75.5739 11.1875C75.9706 10.2109 77.2523 9.17331 79.0376 9.17331C80.7619 9.17331 81.891 10.1346 81.891 11.9046V18.7711H80.9145Z"
            fill="#333331"
        />
        <path
            d="M88.0552 10.0125C86.3157 10.0125 84.9576 11.1875 84.9271 13.6289H91.0307C91.0154 11.1875 89.8863 10.0125 88.0552 10.0125ZM84.9119 14.4071C84.9119 17.0011 86.3157 18.1455 88.2993 18.1455C89.81 18.1455 90.8476 17.703 91.3664 17.4284V18.2523C90.7102 18.7254 89.459 18.9695 88.1162 18.9695C86.1936 18.9695 83.9048 17.8861 83.9048 14.4376V13.7662C83.9048 10.8518 85.6443 9.15805 88.0094 9.15805C90.5271 9.15805 92.0072 10.8976 92.0072 13.7357V14.4071H84.9119Z"
            fill="#333331"
        />
    </svg>
);
