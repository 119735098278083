import React, { Component, ReactNode } from "react";

import { captureException } from "@sentry/browser";
import { PageWidth } from "@wayhome-uk/design-system";
import { ErrorMessage } from "@wayhome-uk/design-system";
import styled from "styled-components";

import { Login } from "components/auth/login";

interface Props {
    className?: string;
    forceErrorState?: boolean;
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundaryLayout extends Component<Props, State> {
    state = {
        hasError: false,
    };

    componentDidMount() {
        const { forceErrorState } = this.props;

        if (forceErrorState) {
            this.setState({ hasError: true });
        }
    }

    componentDidCatch(error: Error) {
        captureException(error);
        this.setState({ hasError: true });
    }

    render() {
        const { className, children } = this.props;
        const { hasError } = this.state;

        return hasError ? (
            <div className={className}>
                <Login />
                <Container>
                    <PageWidth>
                        <StyledErrorMessage>An error occurred here. Please reload the page.</StyledErrorMessage>
                    </PageWidth>
                </Container>
            </div>
        ) : (
            children
        );
    }
}

export { ErrorBoundaryLayout };

const Container = styled.div`
    padding-top: ${(props) => props.theme.spacing160};
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin: 0;
`;

StyledErrorMessage.displayName = "ErrorMessage";
