export const numberWithCommas = (value: string | number): string => {
    const str = typeof value === "number" ? value.toString() : value;

    const matchedString = str.match(/^-?\d+(?:\.\d{0,2})?/);
    let firstElement;
    if (Array.isArray(matchedString)) {
        firstElement = matchedString[0];
    } else {
        return "";
    }

    const formattedString = firstElement.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formattedString}`;
};

export const valueToCurrency = (value: string | number): string => {
    const formattedString = formatter.numberWithCommas(value);
    return `£${formattedString}`;
};

export const currencyToNumber = (formattedCurrency: string): string => {
    const formattedString = formattedCurrency.replace(/,|£/g, "");
    const matchedString = formattedString.match(/^-?\d+(?:\.\d{0,2})?/);

    const firstElement = Array.isArray(matchedString) ? matchedString[0] : formattedString;
    const elementIsANumber = !isNaN(Number(firstElement));

    return elementIsANumber ? firstElement : "";
};

export const poundsToPence = (poundValue: string | number): number => {
    const str = typeof poundValue === "number" ? poundValue.toString() : poundValue;

    if (str.includes(".")) {
        const [poundString, penceString] = str.split(".");
        const convertedPenceString = penceString.length === 1 ? `${penceString}0` : penceString;
        return parseInt(poundString, 0) * 100 + parseInt(convertedPenceString, 0);
    }

    return parseInt(str, 0) * 100;
};

export const poundsToPenceOrUndefined = (value?: number | string) => {
    return typeof value === "number" || typeof value === "string" ? poundsToPence(value) : undefined;
};

export const poundsToPenceOrNull = (value?: number | string) => {
    return typeof value === "number" || typeof value === "string" ? poundsToPence(value) : null;
};

export const penceToPounds = (penceValue: number | null) => (penceValue ? penceValue / 100 : 0);

export const addZeros = (num: any) => {
    return num.toFixed(2);
};

export const roundToTen = (num: number) => {
    return Math.ceil(num / 10) * 10;
};

export const roundToHundred = (num: number) => {
    return Math.ceil(num / 100) * 100;
};

export const roundToThousand = (num: number) => {
    return Math.ceil(num / 1000) * 1000;
};

export const roundToFiveThousand = (num: number) => {
    return Math.ceil(num / 5000) * 5000;
};

export const roundToOneDecimal = (num: number) => {
    return Math.round(num * 10) / 10;
};

export const roundedPercentage = (num: number) => {
    return (Math.round(num * 1000) / 10).toFixed(1);
};

export const dataToObjectByKey = (data: Array<{ [key: string]: any }>, keyID: string) => {
    if (data.some((item) => item.hasOwnProperty(keyID))) {
        const newObject: { [keyID: string]: any } = {};

        data.forEach((item) => {
            newObject[item[keyID]] = item;
        });

        return newObject;
    } else {
        return data;
    }
};

export const abbreviateNumber = (num: number) => {
    if (num >= 1000) {
        return Number((num / 1000).toFixed(1)) + "K";
    }

    return num.toString();
};

const formatter = {
    dataToObjectByKey,
    numberWithCommas,
    valueToCurrency,
    currencyToNumber,
    penceToPounds,
    poundsToPence,
    poundsToPenceOrNull,
    addZeros,
    roundToTen,
    roundToHundred,
    roundToThousand,
    roundToFiveThousand,
    roundToOneDecimal,
    roundedPercentage,
    abbreviateNumber,
};

export default formatter;
