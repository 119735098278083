import React from "react";

import styled from "styled-components/macro";

import { NewHeader2 } from "../../../../../config/theme";
import PayneFamilyImage from "./payne-family.svg";

interface IPayneFamilyProps {
    children: React.ReactNode;
    styleType?: string;
}

export const PayneFamily = ({ children, styleType }: IPayneFamilyProps) => (
    <Grid>
        {styleType && styleType === "alternate" ? (
            <BoldParagraph>Meet the Payne family</BoldParagraph>
        ) : (
            <Title>Meet the Payne family</Title>
        )}
        <Image src={PayneFamilyImage} alt="the Payne family" />

        <Wrapper>{children}</Wrapper>
    </Grid>
);

const Grid = styled.div`
    display: grid;
    grid: auto / 100%;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        grid: auto / 1fr auto;
        grid-gap: ${({ theme }) => `0 ${theme.spacing24}`};
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    font-weight: 500;
    color: ${({ theme }) => theme.neutral0};
    margin-bottom: ${({ theme }) => theme.spacing24};
    line-height: 1.4;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing8};
    }
`;

const Image = styled.img`
    width: 90%;
    max-width: 458px;
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        width: 100%;
        grid-area: 1 / 2 / last-line / 3;
        margin-bottom: 0;
    }
`;

const Wrapper = styled.div`
    max-width: 695px;
`;

const Paragraph = styled.p`
    font-size: ${({ theme }) => theme.type18};
    line-height: 1.4;
    color: ${({ theme }) => theme.neutral800};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: ${({ theme }) => theme.type20};
    }
`;

const BoldParagraph = styled(Paragraph)`
    font-weight: 700;
`;
