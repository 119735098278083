import { Reducer } from "redux";

import { globaliseSelectors } from "utils/globalise-selectors";

import { UserState, userActionTypes } from "./types";

export const getInitialState = (): UserState => ({
    isIdentified: false,
    isInternal: !!localStorage?.getItem?.("noTrack"),
    utmParams: "",
    jobInformationGathered: true,
    applicant2JobInformationGathered: true,
});

export const userReducer: Reducer<UserState> = (state = getInitialState(), action) => {
    switch (action.type) {
        case userActionTypes.UPDATE:
            return { ...state, ...action.payload };
        case userActionTypes.LOGOUT:
            return {
                ...state,
                isIdentified: false,
                isInternal: localStorage ? Boolean(localStorage.getItem("noTrack")) : false,
                utmParams: "",
                jobInformationGathered: false,
                applicant2JobInformationGathered: false,
            };
        default:
            return state;
    }
};

export const getUserIdentifiedStatus = (state: UserState) => state.isIdentified;

export const getIsInternalUser = (state: UserState) => state.isInternal;

export const getUtmParams = (state: UserState) => state.utmParams;

export const getJobInformationGathered = (state: UserState) => state.jobInformationGathered;

export const getApplicant2JobInformationGathered = (state: UserState) => state.applicant2JobInformationGathered;

export const userSelectors = globaliseSelectors(
    {
        getUserIdentifiedStatus,
        getIsInternalUser,
        getUtmParams,
        getJobInformationGathered,
        getApplicant2JobInformationGathered,
    },
    "user",
);
