import React, { Suspense, lazy } from "react";

import { LoadingIndicator } from "@wayhome-uk/design-system";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { CustomerRoute } from "components/customer-route";
import { ProtectedRoute } from "components/protected-route";
import { routePaths } from "config/route-paths";
import { withSignUpRedirect } from "hoc/with-signup-redirect";
import Home from "pages/home";
import { retry } from "utils/retry";

const About = lazy(() => retry(() => import("pages/about")));
const AccountSettings = lazy(() => retry(() => import("pages/account-settings")));
const AgreementInPrinciple = lazy(() => retry(() => import("pages/agreement-in-principle")));
const ApplicationProcess = lazy(() => retry(() => import("pages/application-process")));
const BeforeWeBegin = lazy(() => retry(() => import("pages/before-we-begin")));
const BookAViewing = lazy(() => retry(() => import("pages/book-a-viewing")));
const CannotHelpRightNow = lazy(() => retry(() => import("pages/cannot-help-right-now")));
const CreditCheck = lazy(() => retry(() => import("pages/credit-check")));
const CreditCheckAddApplicant2Details = lazy(() => retry(() => import("pages/credit-check-add-applicant-2-details")));
const CreditCheckThankYou = lazy(() => retry(() => import("pages/credit-check-thank-you")));
const CookiePolicy = lazy(() => retry(() => import("pages/cookie-policy")));
const Explainer = lazy(() => retry(() => import("pages/explainer")));
const GetApproved = lazy(() => retry(() => import("pages/get-approved")));
const JoinTheWaitList = lazy(() => retry(() => import("pages/join-the-wait-list")));
const LikedSuitabilityMatches = lazy(() => retry(() => import("pages/liked-suitability-matches")));
const Login = lazy(() => retry(() => import("pages/login")));
const LoginToSeeHomes = lazy(() => retry(() => import("pages/login-to-see-homes")));
const MakeAnOffer = lazy(() => retry(() => import("pages/make-an-offer")));
const MatchedAutomatedPSF = lazy(() => retry(() => import("pages/matched-automated-PSF")));
const OfferConfirmation = lazy(() => retry(() => import("pages/offer-confirmation")));
const PageNotFound = lazy(() => retry(() => import("pages/page-not-found")));
const PasswordReset = lazy(() => retry(() => import("pages/password-reset")));
const Press = lazy(() => retry(() => import("pages/press")));
const PropertyDetail = lazy(() => retry(() => import("pages/property-detail")));
const RequestViewingSuccess = lazy(() => retry(() => import("pages/request-viewing-success")));
const SelfEmployedExpensesForm = lazy(() => retry(() => import("pages/self-employed-expenses-form")));
const SelfEmployedExpensesIntro = lazy(() => retry(() => import("pages/self-employed-expenses-intro")));
const StartCreditCheck = lazy(() => retry(() => import("pages/start-credit-check")));
const Suitability = lazy(() => retry(() => import("pages/suitability")));
const SuitabilityMatches = lazy(() => retry(() => import("pages/suitability-matches")));
const SuitabilityStageTwo = lazy(() => retry(() => import("pages/suitability-stage-two")));
const TellUsAboutTheProperty = lazy(() => retry(() => import("pages/tell-us-about-the-property")));
const WayhomeOffer = lazy(() => retry(() => import("pages/wayhome-offer")));
const WhereWeBuy = lazy(() => retry(() => import("pages/where-we-buy")));
const YouAreEligible = lazy(() => retry(() => import("pages/you-are-eligible")));
const YouAreOnTheWaitlist = lazy(() => retry(() => import("pages/you-are-on-the-waitlist")));

export const Routes = withSignUpRedirect(() => {
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Route exact={true} path={routePaths.marketing.joinTheWaitList.path} component={JoinTheWaitList} />
                <Route exact={true} path={routePaths.marketing.home.path} component={Home} />
                <Route exact={true} path={routePaths.marketing.about.path} component={About} />
                <Route exact={true} path={routePaths.marketing.login.path} component={Login} />
                <Route
                    exact={true}
                    path={routePaths.marketing.applicationProcess.path}
                    component={ApplicationProcess}
                />
                <Route exact={true} path={routePaths.misc.press.path} component={Press} />
                <Route exact={true} path={routePaths.marketing.whereWeBuy.path} component={WhereWeBuy} />
                <Route exact={true} path={routePaths.misc.cookiePolicy.path} component={CookiePolicy} />
                <Route exact={true} path={routePaths.product.suitability.path} component={Suitability} />
                <Route exact={true} path={routePaths.product.homesNotFound.path} component={LoginToSeeHomes} />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.suitabilityStageTwo.path}
                    redirectRoute={`${routePaths.marketing.applicationProcess.path}?sign-in=true`}
                    component={SuitabilityStageTwo}
                />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.suitabilityMatches.path}
                    component={SuitabilityMatches}
                />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.filteredSuitabilityMatches.path}
                    component={LikedSuitabilityMatches}
                />
                <CustomerRoute exact={true} path={routePaths.product.propertyDetail.path} component={PropertyDetail} />
                <CustomerRoute exact={true} path={routePaths.product.makeAnOffer.path} component={MakeAnOffer} />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.offerConfirmation.path}
                    component={OfferConfirmation}
                />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.offPlatformOfferConfirmation.path}
                    component={OfferConfirmation}
                />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.startCreditCheck.path}
                    component={StartCreditCheck}
                />
                <CustomerRoute exact={true} path={routePaths.product.bookAViewing.path} component={BookAViewing} />
                <Route exact={true} path={routePaths.misc.explainer.path} component={Explainer} />
                <Route
                    exact={true}
                    path={routePaths.product.creditCheckThankYou.path}
                    component={CreditCheckThankYou}
                />
                <Route
                    exact={true}
                    path={routePaths.product.creditCheckAddApplicant2Details.path}
                    component={CreditCheckAddApplicant2Details}
                />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.getApproved.path}
                    redirectRoute={`${routePaths.marketing.applicationProcess.path}?sign-in=true`}
                    component={GetApproved}
                />
                <Route exact={false} path={routePaths.product.creditCheck.path} component={CreditCheck} />
                <Route exact={false} path={routePaths.product.passwordReset.path} component={PasswordReset} />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.accountSettings.path}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                    component={AccountSettings}
                />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.estimateCosts.path}
                    redirectRoute={`${routePaths.marketing.applicationProcess.path}?sign-in=true`}
                    component={MatchedAutomatedPSF}
                />
                <Route
                    exact={true}
                    path={routePaths.product.requestViewingSuccess.path}
                    component={RequestViewingSuccess}
                />
                <Route exact={true} path={routePaths.product.cannotHelpRightNow.path} component={CannotHelpRightNow} />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.youAreEligible.path}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                    component={YouAreEligible}
                />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.youAreOnTheWaitlist.path}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                    component={YouAreOnTheWaitlist}
                />
                <Route
                    exact={false}
                    path={routePaths.product.selfEmployedExpenses.path}
                    component={SelfEmployedExpensesForm}
                />
                <Route
                    exact={false}
                    path={routePaths.product.selfEmployedExpensesIntro.path}
                    component={SelfEmployedExpensesIntro}
                />
                <Route exact={false} path={routePaths.product.beforeWeBegin.path} component={BeforeWeBegin} />
                <ProtectedRoute
                    exact={false}
                    path={routePaths.product.tellUsAboutTheProperty.path}
                    component={TellUsAboutTheProperty}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                />
                <ProtectedRoute
                    exact={false}
                    path={routePaths.product.wayhomeOffer.path}
                    component={WayhomeOffer}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                />
                <ProtectedRoute
                    exact={false}
                    path={routePaths.product.agreementInPrinciple.path}
                    component={AgreementInPrinciple}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                />
                <Route component={PageNotFound} />
            </Switch>
        </Suspense>
    );
});

const PageLoader = styled(LoadingIndicator)`
    padding: 150px 0;
`;
