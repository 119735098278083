import React from "react";

import { BaseComponent } from "types";

import { PrettyNextStepSection } from "components/next-step-section/next-step-section";

export interface IWithNextStepsLayoutProps extends BaseComponent {
    dataTestId?: string;
    children: React.ReactNode;
}

export const WithNextStepsLayout = ({ children, dataTestId }: IWithNextStepsLayoutProps) => {
    return (
        <div data-testid={dataTestId}>
            {children}
            <PrettyNextStepSection />
        </div>
    );
};
