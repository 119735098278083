export const mainMenu = (logIn: () => void, logOut: () => void, uuid: string | null, isWaitlist = true) => {
    return [
        {
            id: "eligibility",
            url: `/eligibility`,
            title: "Eligibility",
            isExternal: true,
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            type: "info",
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: !isWaitlist,
                    isInMoreMenu: isWaitlist,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "application-process",
            url: "/application-process",
            title: "Application process",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            type: "info",
            appearance: {
                small: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "where-we-buy",
            url: "/where-we-buy",
            title: "Where we buy",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            type: "info",
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: !isWaitlist,
                    isInMoreMenu: isWaitlist,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "more",
            title: "More",
            url: "",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            hasSubMenu: true,
            type: "info",
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "how-it-works",
            url: `/how-it-works`,
            title: "How it works",
            isExternal: true,
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            type: "info",
            appearance: {
                small: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "testimonials",
            url: "/testimonials",
            title: "Testimonials",
            isExternal: true,
            target: "_blank",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "intermediaries",
            url: "/intermediaries",
            title: "Intermediaries",
            isExternal: true,
            target: "_blank",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "about-us",
            url: "/about",
            title: "About us",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "faqs",
            url: "/faqs",
            title: "FAQs",
            isExternal: true,
            target: "_blank",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "our-blog",
            url: "/blog",
            title: "Our blog",
            isExternal: true,
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: true,
                    isInAccountsMenu: false,
                },
            },
        },

        {
            id: "homes",
            url: `/suitability/matches/${uuid}`,
            title: "Homes",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            type: "homes",
            appearance: {
                small: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "saved-homes",
            url: `/suitability/matches/${uuid}/filter/liked`,
            title: "Saved Homes",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            type: "homes",
            appearance: {
                small: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: isWaitlist,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "my-account",
            title: "My account",
            url: "",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            hasSubMenu: true,
            type: "sign-in",
            appearance: {
                small: {
                    isInMainMenu: false,
                    isInMoreMenu: false,
                    isInLoggedInMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },

        {
            id: "account-settings",
            url: "/account-settings",
            title: "Account settings",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: false,
                    isInAccountsMenu: true,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: false,
                    isInAccountsMenu: true,
                },
            },
        },
        {
            id: "contact-preferences",
            url: "/contact-preferences",
            title: "Contact preferences",
            isExternal: true,
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: false,
                    isInAccountsMenu: true,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: false,
                    isInAccountsMenu: true,
                },
            },
        },
        {
            id: "log-out",
            url: "",
            title: "Log out",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            handleClick: logOut,
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: false,
                    isInMoreMenu: false,
                    isInAccountsMenu: true,
                },
                large: {
                    isInMainMenu: false,
                    isInMoreMenu: false,
                    isInAccountsMenu: true,
                },
            },
        },

        {
            id: "log-in",
            url: "",
            title: "Log in",
            showWhenLoggedIn: false,
            showWhenLoggedOut: true,
            handleClick: logIn,
            type: "sign-in",
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInLoggedInMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
        {
            id: "sign-up",
            url: "/suitability",
            title: "Sign up",
            isButton: true,
            showWhenLoggedIn: false,
            showWhenLoggedOut: true,
            type: "sign-in",
            appearance: {
                small: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInLoggedInMenu: false,
                    isInAccountsMenu: false,
                },
                medium: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
                large: {
                    isInMainMenu: true,
                    isInMoreMenu: false,
                    isInAccountsMenu: false,
                },
            },
        },
    ];
};
