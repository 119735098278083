import React from "react";

import styled from "styled-components";

import { routePaths, routeWithoutParams } from "../../config/route-paths";
import { LogoLink } from "./header-links";
import { WithNavbarAndInstantSearchLayout } from "./with-navbar-and-instant-search-layout";
import { WithNextStepsLayout } from "./with-next-steps-layout";

const isPropertyDetailsPath = (pathname: string) =>
    pathname.includes(routeWithoutParams(routePaths.product.propertyDetail.path));

const isMiscPath = (pathname: string) =>
    Object.keys(routePaths.misc).some((key: string) =>
        pathname.includes(routeWithoutParams(routePaths.misc[key].path)),
    );

const isPasswordPath = (pathname: string) => pathname === routePaths.product.passwordReset.path;

const isMarketingFormPath = (pathname: string) => pathname.includes(routePaths.product.creditCheck.root);

const isProductPath = (pathname: string) => {
    if (pathname.includes(routeWithoutParams(routePaths.marketing.applicationProcess.path))) {
        return true;
    }

    return Object.keys(routePaths.product).some(
        (key: string) =>
            pathname.includes(routeWithoutParams(routePaths.product[key].path)) ||
            pathname.includes(routePaths.product[key].root),
    );
};

const isMarketingPath = (pathname: string) =>
    pathname.includes(routePaths.marketing.about.path) || pathname.includes(routePaths.marketing.faqs.path);

const passwordNav = {
    logo: {
        title: "Wayhome",
        url: "/",
    },
    menu: [
        {
            title: "Back to wayhome",
            url: "/",
        },
    ],
};

export const marketingFormNav = {
    logo: LogoLink,
    menu: [{ title: "Go to Wayhome.co.uk", url: `${routePaths.marketing.home.path}` }],
};

export const getLayout = (pathname: string, children: React.ReactNode) => {
    if (isMarketingPath(pathname)) {
        return <WithNextStepsLayout>{children}</WithNextStepsLayout>;
    }

    if (isPropertyDetailsPath(pathname)) {
        return (
            <WithNavbarAndInstantSearchLayout dataTestId="property-detail-layout">
                {children}
            </WithNavbarAndInstantSearchLayout>
        );
    }

    if (isMiscPath(pathname)) {
        return <MiscLayout dataTestId="misc-layout">{children}</MiscLayout>;
    }

    if (isPasswordPath(pathname)) {
        return (
            <WithNavbarAndInstantSearchLayout dataTestId="password-layout" navBarDetails={passwordNav}>
                {children}
            </WithNavbarAndInstantSearchLayout>
        );
    }

    if (isMarketingFormPath(pathname)) {
        return (
            <WithNavbarAndInstantSearchLayout
                dataTestId="marketing-form-layout"
                withInstantSearch={true}
                navBarDetails={marketingFormNav}
            >
                {children}
            </WithNavbarAndInstantSearchLayout>
        );
    }

    if (isProductPath(pathname)) {
        return (
            <WithNavbarAndInstantSearchLayout dataTestId="product-layout" withInstantSearch={true}>
                {children}
            </WithNavbarAndInstantSearchLayout>
        );
    }

    return <>{children}</>;
};

export const isProductBackground = (pathname: string) => {
    return Object.keys(routePaths.product).some(
        (key: string) =>
            pathname.includes(routeWithoutParams(routePaths.product[key].path)) ||
            pathname.includes(routePaths.product.creditCheck.root) ||
            pathname.includes(routePaths.marketing.whereWeBuy.path),
    );
};

export const urlWithSimpleNav = (pathname: string) => {
    return pathname === routePaths.product.passwordReset.path || pathname.includes(routePaths.product.creditCheck.root);
};

const MiscLayout = styled(WithNavbarAndInstantSearchLayout)`
    margin-top: ${({ theme }) => theme.spacing24};
    margin-bottom: ${({ theme }) => theme.spacing40};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-top: ${({ theme }) => theme.spacing64};
        margin-bottom: ${({ theme }) => theme.spacing64};
    }
`;
