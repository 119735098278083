import { captureException } from "@sentry/browser";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { showApiError } from "../ui";
import { handleApiError } from "../ui/sagas";
import { sendInteraction } from "./actions";
import { InteractionsActionTypes } from "./types";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export function* handleSendInteraction(action: ReturnType<typeof sendInteraction>) {
    const others = action.payload.listing ? { listing: action.payload.listing } : { listings: action.payload.listings };

    try {
        const response: Response = yield call(fetch, `${API_ENDPOINT}/v1/external-events`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                customer: action.payload.uuid,
                action: action.payload.action,
                applicant: action.payload.applicant,
                application: action.payload.application,
                message: JSON.stringify(action.payload.message),
                ...others,
            }),
        });

        if (!response.ok) {
            captureException("failed to send interaction to events endpoint");
            yield call(handleApiError, response);
        }
    } catch (error) {
        captureException(error);
        yield put(showApiError(error as string));
    }
}

export function* watchSendInteraction() {
    yield takeLatest(InteractionsActionTypes.SEND_INTERACTION, handleSendInteraction);
}

export function* interactionsSagas() {
    yield all([watchSendInteraction()]);
}
