import React, { useState } from "react";

import { NavbarLoginDesktop } from "./navbar-login-desktop";
import { NavbarLoginMobile } from "./navbar-login-mobile/navbar-login-mobile";
import { HeaderLink } from "./types";

export interface Props {
    className?: string;
    isLoggedIn: boolean;
    menu: HeaderLink[];
}

export const NavbarLogin = ({ className, isLoggedIn, menu }: Props) => {
    const [isShowing, handleToggle] = useState(false);

    const toggleIsShowing = () => {
        handleToggle(!isShowing);
    };

    return (
        <div data-test-id="navbar-login" className={className}>
            <NavbarLoginDesktop isLoggedIn={isLoggedIn} menu={menu} />

            <NavbarLoginMobile isShowing={isShowing} onClick={toggleIsShowing} isLoggedIn={isLoggedIn} menu={menu} />
        </div>
    );
};
