import React from "react";

import { Button, ShortParagraph } from "@wayhome-uk/design-system";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { BaseComponent } from "types";

interface IModalProps extends BaseComponent {
    title?: string;
    footerText?: string;
    footerLinkText?: string;
    footerLinkHref?: string;
    onClose?: () => void;
    canCloseModalOnFooterLinkClick?: boolean;
}

export const Modal: React.FC<IModalProps> = ({
    className,
    children,
    title,
    footerText,
    footerLinkText,
    footerLinkHref,
    onClose,
    canCloseModalOnFooterLinkClick,
}) => {
    return (
        <ModalWrapper className={className}>
            <Header>
                {title ? <HeaderTitle>{title}</HeaderTitle> : null}
                {onClose ? (
                    <CloseButton colorScheme="secondary" onClick={onClose}>
                        Close
                    </CloseButton>
                ) : null}
            </Header>
            <Body>
                {children}

                {footerText && footerLinkHref && footerLinkText ? (
                    <Footer>
                        {footerText}{" "}
                        <StyledLink to={footerLinkHref} onClick={canCloseModalOnFooterLinkClick ? onClose : undefined}>
                            {footerLinkText}
                        </StyledLink>
                    </Footer>
                ) : null}
            </Body>
        </ModalWrapper>
    );
};

const ModalWrapper = styled.div`
    margin: 0 ${(props) => props.theme.spacing16};
    background: ${(props) => props.theme.neutral75};
    border-radius: ${(props) => props.theme.radius1};
`;

export const Header = styled.div`
    padding: ${(props) => props.theme.spacing16} ${(props) => props.theme.spacing24};
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.neutral0};
    border-top-left-radius: ${(props) => props.theme.radius1};
    border-top-right-radius: ${(props) => props.theme.radius1};
`;

const HeaderTitle = styled.h2`
    ${ShortParagraph};
    color: ${(props) => props.theme.neutral800};
    font-weight: 600;
`;

const CloseButton = styled(Button)`
    margin-left: auto;
    color: ${(props) => props.theme.primary500};
    background: transparent;
    padding: 0;
`;

export const Body = styled.div`
    padding: ${(props) => props.theme.spacing24};
    color: ${(props) => props.theme.neutral800};
`;

const Footer = styled.div`
    text-align: center;
    color: ${(props) => props.theme.neutral800};
    border-top: 1px solid ${(props) => props.theme.neutral200};
    padding-top: ${(props) => props.theme.spacing24};
    margin-top: ${(props) => props.theme.spacing24};
`;

const StyledLink = styled(Link)`
    color: ${(props) => props.theme.primary500};
    font-weight: 600;
`;
