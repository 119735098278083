import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { ArrowBottom } from "components/icons/icon-arrow-bottom";

import { HeaderLink } from "../types";

export interface Props {
    className?: string;
    title: string;
    menu: HeaderLink[];
    isLast: boolean | undefined;
}

export const Dropdown = ({ className, title, menu, isLast }: Props) => {
    const [isShowing, handleToggle] = useState(false);
    return (
        <Wrapper data-test-id="navbar-dropdown" className={className}>
            <Title data-test-id="navbar-dropdown-title" isShowing={isShowing} onClick={() => handleToggle(!isShowing)}>
                {title} <ArrowBottom />
            </Title>

            <MenuWrapper isShowing={isShowing}>
                <Menu data-test-id="navbar-dropdown-items" isLast={isLast}>
                    {menu.map((link, index) => (
                        <MenuItem key={index}>
                            {link.isExternal ? (
                                <ExternalLink
                                    href={link.url}
                                    title={link.title}
                                    data-test-id={link.testId}
                                    target={link.target}
                                    rel={link.target ? "noopener noreferrer" : ""}
                                >
                                    {link.title}
                                </ExternalLink>
                            ) : (
                                <MenuLink
                                    exact
                                    activeClassName="active-link"
                                    to={link.url}
                                    title={link.title}
                                    data-test-id={`sub-menu-item-${link.id}`}
                                    onClick={() => {
                                        handleToggle(false);
                                        link.handleClick && link.handleClick();
                                    }}
                                >
                                    {link.title}
                                </MenuLink>
                            )}
                        </MenuItem>
                    ))}
                </Menu>
            </MenuWrapper>
        </Wrapper>
    );
};

const ParagraphTextCss = css`
    font-size: ${({ theme }) => theme.type16};
    line-height: 1.6;
    max-width: 544px;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: 18px;
    }
`;

const Wrapper = styled.div`
    display: inline-block;
`;

const Title = styled.span<{ isShowing?: boolean }>`
    ${ParagraphTextCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: 600;
    cursor: pointer;

    :hover,
    :focus {
        color: ${({ theme }) => theme.primary800};
        text-decoration: underline;
    }

    svg {
        fill: currentColor;
        position: relative;
        top: -1px;

        ${({ isShowing }) =>
            isShowing === true &&
            `
            transform: rotate(180deg);
            fill: ${({ theme }: any) => theme.primary300};
        `};
    }
`;

const MenuWrapper = styled.div<{ isShowing: boolean }>`
    position: relative;
    z-index: 2;
    display: none;

    ${({ isShowing }) =>
        isShowing === true &&
        `
        display: block;
    `};
`;

const Menu = styled.ul<{ isLast: boolean | undefined }>`
    position: absolute;
    top: 27px;
    right: ${({ isLast }) => isLast && "0px"};
    width: 13.625rem; /* 218px */
    background: ${({ theme }) => theme.neutral0};
    border-radius: ${({ theme }) => theme.radius1};
    box-shadow: 0px 2px 4px rgba(8, 38, 43, 0.3);
`;

const MenuItem = styled.li`
    padding: 0;
`;

const MenuLinkCss = css`
    display: block;
    border-bottom: 1px solid #deeaeb;
    padding: ${({ theme }) => theme.spacing12};

    li:last-child & {
        border-bottom: none;
    }

    ${ParagraphTextCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: 600;

    :hover,
    :focus {
        color: ${({ theme }) => theme.primary800};
        text-decoration: underline;
    }
`;

const MenuLink = styled(NavLink)`
    ${MenuLinkCss};
`;

const ExternalLink = styled.a`
    ${MenuLinkCss};
`;
