/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from "crypto-js";
import { customWindow } from "interfaces";
import { camelCase, isPlainObject } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { routePaths } from "config/route-paths";
import { IApplicant, IApplicantData, IUserProfile } from "hooks/use-auth";

import { IMatch } from "../hooks/use-matches";

/* Taken from lower down in this page:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 */
export const encodeSpecialChars = (value: string | number | boolean) => {
    return encodeURIComponent(value).replace(/[!'()*]/g, function (c) {
        return "%" + c.charCodeAt(0).toString(16);
    });
};

export const generateURLQueryString = (
    keyPairValues: Array<{ [key: string]: string | number | boolean | undefined }>,
): string => {
    return keyPairValues.reduce((queryString, value) => {
        const prefix = queryString.indexOf("?") === -1 ? "?" : "&";

        const paramKey = Object.keys(value)[0];
        const paramValue = Object.values(value)[0];

        return paramKey && paramValue
            ? `${queryString}${prefix}${paramKey}=${encodeSpecialChars(paramValue)}`
            : queryString;
    }, "");
};

export const queryStringToObject = (query: string): { [key: string]: string } => {
    return query
        .replace("?", "")
        .split("&")
        .reduce((queryStringObject, keyPairString) => {
            const [key, value] = keyPairString.split("=");

            queryStringObject[key] = decodeURIComponent(value);

            return queryStringObject;
        }, {} as Record<string, string>);
};

export const updateQueryStringValues = (query: string, keyPairValues: { [key: string]: number | string }): string => {
    return Object.keys(keyPairValues).reduce((newQuery, key) => {
        const keyPairRegex = new RegExp(`(${key}=[^&]+)`);
        const newKeyPairValue = `${key}=${encodeSpecialChars(keyPairValues[key])}`;

        if (newQuery.search(keyPairRegex) === -1) {
            return newQuery.indexOf("?") === -1 ? `?${newKeyPairValue}` : `${newQuery}&${newKeyPairValue}`;
        }
        return newQuery.replace(keyPairRegex, newKeyPairValue);
    }, query);
};

export const removeQueryStringValues = (query: string, keyPairValues: { [key: string]: number | string }): string => {
    return Object.keys(keyPairValues).reduce((newQuery, key) => {
        const keyPairRegex = new RegExp(`(${key}=[^&]*)`);

        return newQuery
            .replace(keyPairRegex, "")
            .replace(/&$/, "")
            .replace("?&", "?")
            .replace("&&", "&")
            .replace(/^\?$/, "");
    }, query);
};

export const filterQueryStringByPrefix = (query: string, prefix: string): string => {
    const params = queryStringToObject(query);

    const filteredObject = Object.entries(params)
        .filter((item) => item[0].startsWith(prefix))
        .map((item) => {
            return { [item[0]]: item[1] };
        });

    return generateURLQueryString(filteredObject);
};

export const modifyAddressForTypeFormLink = (address: string) =>
    address.replace(/[^-a-zA-Z0-9 ]/g, "").replace(/ /g, "%20");

export const needsToCompleteStage2Form = (expenditureInformationGathered = false, isSuitable = false) => {
    //only users marked as suitable need to complete the stage 2 form
    //expenditureInformationGathered is a flag used to tell if user has completed stage 2 form
    return !expenditureInformationGathered && isSuitable;
};

export const getLDSecondaryKey = () => {
    const LD_LOCAL_STORAGE_KEY = "ldSecondaryKey";
    let ldSecondaryKey = "";

    const ldSecondaryKeyFromStorage = localStorage.getItem(LD_LOCAL_STORAGE_KEY);
    if (!ldSecondaryKeyFromStorage) {
        ldSecondaryKey = uuidv4();
        localStorage.setItem(LD_LOCAL_STORAGE_KEY, ldSecondaryKey);
    } else {
        ldSecondaryKey = ldSecondaryKeyFromStorage;
    }

    return ldSecondaryKey;
};

export const getMadeOfferListingsArrayFromLocalStorage = () => {
    let madeOfferListingsArray = [];
    const madeOfferListings = localStorage.getItem("madeOfferListings");
    if (madeOfferListings) {
        madeOfferListingsArray = JSON.parse(madeOfferListings);
    }

    return madeOfferListingsArray;
};

export const getWayhomeOfferCallRequestedFromLocalStorage = () => {
    const wayhomeOfferCallRequested = localStorage.getItem("wayhomeOfferCallRequested")
        ? (localStorage.getItem("wayhomeOfferCallRequested") as string)
        : "false";
    return JSON.parse(wayhomeOfferCallRequested);
};

export const setWayhomeOfferCallRequestedFromLocalStorage = () => {
    localStorage.setItem("wayhomeOfferCallRequested", JSON.stringify(true));
};

export const getSEExpensesFormURLWithParams = (profile: Partial<IUserProfile> | null) => {
    const searchParams = new URLSearchParams();

    if (profile?.applicants) {
        profile.applicants.forEach((applicant) => {
            if (applicant?.jobStatus === "Self-employment") {
                searchParams.append("applicantID", applicant.applicantID!);
            }
        });
    }

    return `${routePaths.product.selfEmployedExpenses.path}?${searchParams}`;
};

export const getNextSearchRadius = (currentSearchRadius: number): number => {
    if (currentSearchRadius < 10) {
        return 10;
    }

    if (currentSearchRadius < 20) {
        return 20;
    }

    if (currentSearchRadius < 30) {
        return 30;
    }

    if (currentSearchRadius < 50) {
        return 50;
    }

    return currentSearchRadius;
};

export const isLeadApplicant = (applicants: IApplicantData[]) =>
    applicants.find((applicant: IApplicantData) => applicant.isLeadApplicant);

export const isSecondaryApplicant = (applicants: IApplicantData[]) =>
    applicants.find((applicant: IApplicantData) => !applicant.isLeadApplicant);

export const isMergeStageTwoAndCreditVersion = (
    hasPhone: boolean,
    isSingleApplicant: boolean,
    isNotSelfEmployed: boolean,
) => hasPhone && isSingleApplicant && isNotSelfEmployed;

export const checkJobStatusIsValid = (jobStatus?: string) =>
    jobStatus === "Employed full-time" ||
    jobStatus === "Employed part-time" ||
    jobStatus === "Self-employment" ||
    jobStatus === "Unemployment";

export const isValidPhoneNumber = (value: string) => {
    const reg = new RegExp("^0[0-9]{10}$");
    const phoneNumberIsValid = reg.test(value);

    return Boolean(phoneNumberIsValid);
};

export const isCtaOffPlatformVisible = (
    removeCustomerFromViewings: boolean,
    canGoOnViewings: boolean,
    applicants: IApplicant[],
    hasPropertyUnderOffer: boolean,
    page: undefined | string = undefined,
) => {
    const isFirstPage = page === undefined || page === "1";
    const applicationHasFailedAffordability = applicants.some(
        (applicant) => applicant.affordabilityCheckStatus === "fail",
    );

    return (
        !hasPropertyUnderOffer &&
        removeCustomerFromViewings &&
        canGoOnViewings &&
        !applicationHasFailedAffordability &&
        isFirstPage
    );
};

export const isMatchesDataAvailable = (match: IMatch | null): boolean => {
    if (!match) {
        return false;
    }
    return !!(
        match.lockInPeriodMonths &&
        match.financials &&
        match.agreedPrice &&
        match.agreedPrice &&
        match.agreedDeposit
    );
};

/**
 * @description ternary resolver
 * @param condition - condition
 * @param truthyValue - truthy value
 * @param falsyValue - falsy value
 * @returns {any}
 */
export const resolveTernary = <C = boolean, T = string, F = string>(
    condition: C,
    truthyValue: T,
    falsyValue: F,
): T | F => {
    return condition ? truthyValue : falsyValue;
};

export const isSignUpViewAndUrl = (url: string) =>
    url === "/suitability" && window.location.pathname.includes("/suitability");

export const getGaClientId = () => {
    try {
        let match: string | undefined;

        (window as any).ga((tracker: { get: (value: string) => string }) => {
            match = tracker.get("clientId");
        });

        if (match) {
            return match;
        }

        return null;
    } catch (err) {
        return null;
    }
};

export const scrollTopTop = () => {
    try {
        window.scrollTo(0, 0);
    } catch (err) {}
};

export const encryptData = (data: Record<string, any>, expiresIn?: "1h" | "60s") => {
    const validityDuration = expiresIn === "1h" ? 60 * 60 * 1000 : 60 * 1000;
    const currentDate = new Date();
    const exp = new Date(currentDate.getTime() + validityDuration);

    const stringifiedData = JSON.stringify({ ...data, exp });
    const secret = process.env.REACT_APP_PDF_GENERATOR_SECRET as string;
    const cipherData = CryptoJS.AES.encrypt(stringifiedData, secret).toString();

    return encodeURIComponent(cipherData);
};

export const parseObjectToString = (queryParams: Record<string, string | boolean | number>) => {
    return Object.entries(queryParams)
        .filter(([, value]) => value)
        .reduce((acc, [key, value], index) => (index === 0 ? `${key}=${value}` : `${acc}&${key}=${value}`), "");
};

export const convertObjectKeysToCamelCase = <T = any>(obj: any): T => {
    try {
        if (Array.isArray(obj)) {
            return obj.map((item) => convertObjectKeysToCamelCase<T>(item)) as unknown as T;
        }

        if (isPlainObject(obj)) {
            return Object.entries(obj).reduce((acc: any, [key, value]) => {
                acc[camelCase(key)] = convertObjectKeysToCamelCase<T>(value);

                return acc;
            }, {}) as T;
        }

        return obj as T;
    } catch (err) {
        return obj as typeof obj;
    }
};

export const redirectToResubmitApplication = () => {
    const { returnEmail } = queryStringToObject(window.location.search);
    const redirectPath = returnEmail ? `/cx/sign-up?returnEmail=${returnEmail}` : "/cx/sign-up";
    window.location.replace(redirectPath);
};

export const openIntercomLiveChat = () => customWindow.Intercom("show");
