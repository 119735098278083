import { push } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { routePaths } from "config/route-paths";

import { logoutAction, updateUserAction } from "./actions";
import { userActionTypes } from "./types";

export function* handleUpdate(action: ReturnType<typeof updateUserAction>) {
    if (action.payload.isInternal) {
        yield call([localStorage, "setItem"], "noTrack", "true");
    }
}

export function* handleLogout(action: ReturnType<typeof logoutAction>) {
    yield call([localStorage, "clear"]);
    yield put(push(routePaths.marketing.home.path));
}

export function* watchUpdateActions() {
    yield takeLatest(userActionTypes.UPDATE, handleUpdate);
}

export function* watchLogoutAction() {
    yield takeLatest(userActionTypes.LOGOUT, handleLogout);
}

export function* userSagas() {
    yield all([watchUpdateActions(), watchLogoutAction()]);
}
