import React from "react";

import { Accordion } from "components/faqs";
import { MINIMUM_DEPOSIT, MINIMUM_HOUSEHOLD_INCOME } from "config/constants";
import { numberWithCommas } from "utils/formatter";

import { Paragraph, UnorderedList } from "../../faq-styles";

const minDeposit = numberWithCommas(MINIMUM_DEPOSIT);
const minHouseholdIncome = numberWithCommas(MINIMUM_HOUSEHOLD_INCOME);

export const RequiredInfo = () => (
    <Accordion title="What are your eligibility criteria?" id="eligibility-criteria">
        <Paragraph>We need to know:</Paragraph>
        <UnorderedList>
            <li>How much money you have for a deposit (minimum &pound;{minDeposit})</li>
            <li>Your household income (minimum &pound;{minHouseholdIncome})</li>
            <li>How your household income is split if you’re buying with another applicant</li>
            <li>How many kids you have, if any</li>
            <li>Your credit score</li>
            <li>If you’ve been declared bankrupt</li>
        </UnorderedList>
        <Paragraph>
            We also need to see the same kind of things you show when you rent, like landlord references and employer
            references.
        </Paragraph>
        <Paragraph>
            Unfortunately, we can’t help you if you have a poor credit score, have consistently failed to pay rent or
            you have a recent and unsettled bankruptcy.
        </Paragraph>
    </Accordion>
);
