import React from "react";

import { LoadingIndicator } from "@wayhome-uk/design-system";
import { Redirect, Route, RouteProps } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "hooks/use-auth";

interface IProps extends RouteProps {
    redirectRoute: string;
}

export const ProtectedRoute: React.FC<IProps> = ({ redirectRoute, ...rest }) => {
    const { user, isAuthInitialising } = useAuth();

    if (isAuthInitialising) {
        return <PageLoader />;
    }

    return user.uuid !== null ? <Route {...rest} /> : <Redirect to={redirectRoute} />;
};

const PageLoader = styled(LoadingIndicator)`
    padding: 100px 0;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: 200px 0;
    }
`;
