import React from "react";

import { Helmet } from "react-helmet";

export interface Props {
    title: string;
    description: string;
    slug?: string;
    image?: string;
    isHomePage?: boolean;
}

const publicURL = process.env.REACT_APP_PUBLIC_URL;

export const MetaTags = ({ title, description, slug, image, isHomePage }: Props) => (
    <Helmet>
        <title>{title}</title>

        <meta name="title" content={title} />
        <meta name="description" content={description} />

        {isHomePage && <link href={publicURL} rel="canonical" />}
        {slug && <link href={`${publicURL}${slug}`} rel="canonical" />}

        <meta content="Wayhome" property="og:site_name" />
        <meta property="og:title" content={title} />
        {isHomePage && <meta property="og:url" content={publicURL} />}
        {slug && <meta property="og:url" content={`${publicURL}${slug}`} />}
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image ? `${publicURL}/${image}` : `${publicURL}/open-graph.jpg`} />
    </Helmet>
);
