import React from "react";

import styled from "styled-components";

import AmanahAdvisorsSrc from "components/media/images/amanah-advisors.png";
import IslamicCouncilSrc from "components/media/images/islamic-council-of-europe.png";

export const ShariahTrustbar = () => (
    <Wrapper data-testid="shariah-trustbar">
        <Header>We are Shariah certified</Header>
        <LogosWrapper>
            <Image src={AmanahAdvisorsSrc} aria-label="Amanah Advisors" />
            <Image src={IslamicCouncilSrc} aria-label="Islamic Council of Europe" />
        </LogosWrapper>
        <Paragraph>
            “Unambiguously Shariah compliant and requires a pretty low deposit to get going”
            <Strong>Islamic Finance Guru</Strong>
        </Paragraph>
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: ${({ theme }) => theme.neutral50};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        flex-direction: row;
    }
`;

const Header = styled.p`
    position: relative;
    font-size: ${({ theme }) => theme.type16};
    font-weight: bold;
    line-height: 1.6;
    margin-bottom: ${({ theme }) => theme.spacing8};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        font-size: ${({ theme }) => theme.type18};
        margin-right: ${({ theme }) => theme.spacing32};
        margin-bottom: 0;
    }
`;

const Image = styled.img`
    height: 3.5rem;
`;

const LogosWrapper = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    justify-content: center;
    gap: ${({ theme }) => theme.type16};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        gap: ${({ theme }) => theme.spacing32};
    }
`;

const Paragraph = styled.p`
    max-width: 27rem;
    font-size: ${({ theme }) => theme.type14};
    font-style: italic;
    line-height: 1.6;
    margin-top: ${({ theme }) => theme.spacing12};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        font-size: ${({ theme }) => theme.type16};
        margin-left: ${({ theme }) => theme.spacing32};
        margin-top: 0;
    }
`;

const Strong = styled.strong`
    display: block;
    text-align: right;
    font-style: normal;
    font-weight: 700;
    padding-top: ${({ theme }) => theme.spacing8};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        display: inline-block;
        text-align: left;
        padding-top: 0;
        padding-left: ${({ theme }) => theme.spacing8};
    }
`;
