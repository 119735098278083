import React, { useState } from "react";

import {
    Button,
    ErrorMessage,
    Input,
    Label,
    LoadingContainer,
    ShortParagraphCss,
    VerticalSpacing,
} from "@wayhome-uk/design-system";
import { Formik } from "formik";
import { useApi } from "hooks";
import { TApplication } from "interfaces";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { BaseComponent } from "types";
import { object, string } from "yup";

import { PasswordInput } from "components/form/password-input";
import { routes } from "config/routes";
import { useAuth } from "hooks/use-auth";
import { convertObjectKeysToCamelCase, redirectToResubmitApplication } from "utils/helpers";

const validation = object().shape({
    email: string()
        .required("Please fill in the email field")
        .email("The login email is not in the correct format. Please correct and try again"),
    password: string().required("Please fill in the password field"),
});

interface IProps extends BaseComponent {
    onSuccessfulLogin?: (uuid: string, token: string) => void;
    email: string;
    password: string;
}

export const LoginForm: React.FC<IProps> = ({ onSuccessfulLogin, email, password }) => {
    const { signIn } = useAuth();
    const [isLoading, setLoading] = useState(false);
    const { get } = useApi();
    const [hasLoginError, setHasLoginError] = useState<boolean>(false);
    const { applicationsMigration } = useFlags();

    const handleFormikSubmit = async ({ email, password }: { email: string; password: string }) => {
        setLoading(true);

        try {
            const userInfo = await signIn(email, password);
            const token = await userInfo?.fbUser?.getIdToken();
            if (!token) {
                throw new Error("Authentication failed");
            }

            if (applicationsMigration) {
                const applicationResponseV2 = await get("/applications/v3/customer/application", token);
                const applicationV2 = applicationResponseV2.ok
                    ? convertObjectKeysToCamelCase<TApplication>(applicationResponseV2.body)
                    : null;

                if (applicationV2?.submitted === false) {
                    return redirectToResubmitApplication();
                }

                if (applicationV2?.id) {
                    return onSuccessfulLogin?.(applicationV2.id, token);
                }
            }

            if (userInfo?.uuid) {
                return onSuccessfulLogin?.(userInfo.uuid, token);
            }

            throw new Error("UUID not found");
        } catch (error) {
            setHasLoginError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LoadingContainer isLoading={isLoading} loadingMessage="Signing in">
            <Formik onSubmit={handleFormikSubmit} initialValues={{ email, password }} validationSchema={validation}>
                {({ handleSubmit, isValid, touched, errors, setFieldValue, values }) => (
                    <form onSubmit={handleSubmit}>
                        <StyledLabel htmlFor="email">Email</StyledLabel>
                        <StyledInput
                            id="email"
                            name="email"
                            type="text"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("email", event.target.value);
                            }}
                            value={values.email}
                        />

                        <StyledLabel htmlFor="password">Password</StyledLabel>
                        <PasswordInput
                            id="password"
                            name="password"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("password", event.target.value);
                            }}
                            size="small"
                            value={values.password}
                        />

                        <VerticalSpacing size={24} />

                        {!isValid && errors.email && touched.email && (
                            <StyledErrorMessage>{errors.email}</StyledErrorMessage>
                        )}

                        {!isValid && errors.password && touched.password && (
                            <StyledErrorMessage>{errors.password}</StyledErrorMessage>
                        )}

                        {hasLoginError && (
                            <StyledErrorMessage>
                                Your email and password combination are incorrect. Please try again?
                            </StyledErrorMessage>
                        )}

                        <FlexBox>
                            <StyledLink to={routes.product.passwordReset.path}>Reset password?</StyledLink>
                            <Button type="submit">Sign In</Button>
                        </FlexBox>
                    </form>
                )}
            </Formik>
        </LoadingContainer>
    );
};

const FlexBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLink = styled(Link)`
    ${ShortParagraphCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: bold;
`;

const StyledLabel = styled(Label)`
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing4};
`;

const StyledInput = styled(Input)`
    padding-top: ${({ theme }) => theme.spacing12};
    padding-bottom: ${({ theme }) => theme.spacing12};
    margin-bottom: ${({ theme }) => theme.spacing24};
    width: 100%;
`;

const StyledErrorMessage = styled(ErrorMessage)`
    ${ShortParagraphCss};
    margin-bottom: ${({ theme }) => theme.spacing24};
    padding-left: ${({ theme }) => theme.spacing24};

    svg {
        margin-left: -${({ theme }) => theme.spacing24};
        margin-right: 0;
        height: 14px;
    }
`;
