import React from "react";

export interface Props {
    className?: string;
}

export const Logo = ({ className }: Props) => (
    <svg
        className={className}
        width="146"
        height="20"
        viewBox="0 0 146 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.3174 0.555054H21.1951C20.9621 0.555054 20.7578 0.737659 20.6955 0.961508L18.0915 11.364L12.8038 5.84085C12.7008 5.72593 12.5595 5.66832 12.4181 5.66832C12.2767 5.66832 12.1351 5.72593 12.0321 5.84085L6.74443 11.364L4.14073 0.961508C4.07839 0.737659 3.87375 0.555054 3.6414 0.555054H0.519166C0.180402 0.555054 -0.0676898 0.902319 0.0163716 1.23069L4.47792 19.0709C4.53711 19.3001 4.74396 19.4339 4.9804 19.4339H7.82558C8.11177 19.4339 8.34443 19.2287 8.34443 18.9422V15.7563C8.34443 15.6348 8.38662 15.5167 8.46439 15.4242L12.0198 11.7374C12.2273 11.4884 12.6089 11.4884 12.8164 11.7374L16.3378 15.4242C16.4156 15.5167 16.4578 15.6348 16.4578 15.7563V18.9425C16.4578 19.229 16.6904 19.4339 16.9766 19.4339H19.8561C20.0926 19.4339 20.2991 19.3001 20.3586 19.0709L24.8195 1.23069C24.9039 0.902319 24.6558 0.555054 24.3174 0.555054Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.7381 15.1458H30.9711L29.3758 19.1087C29.2971 19.3052 29.107 19.4339 28.8944 19.4339H25.5921C25.2238 19.4339 24.9725 19.0615 25.1104 18.7205L32.3233 0.87965C32.402 0.683822 32.5928 0.555054 32.8041 0.555054H36.9055C37.117 0.555054 37.3069 0.683192 37.3862 0.878706L44.6252 18.7199C44.7631 19.0608 44.5119 19.4339 44.1435 19.4339H40.8145C40.6029 19.4339 40.4127 19.3052 40.334 19.1087L38.7381 15.1458ZM37.2014 11.262L34.8543 5.40952L32.5084 11.262H37.2014Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.079 4.43479V7.83282H143.732C144.018 7.83282 144.25 8.06548 144.25 8.35167V11.1978C144.25 11.4843 144.018 11.7166 143.732 11.7166H137.079V15.5457H144.837C145.124 15.5457 145.356 15.7787 145.356 16.0649V18.911C145.356 19.1972 145.124 19.4339 144.837 19.4339H133.552C133.266 19.4339 133.033 19.1972 133.033 18.911V1.07013C133.033 0.783625 133.266 0.555054 133.552 0.555054H144.837C145.124 0.555054 145.356 0.783625 145.356 1.07013V3.91625C145.356 4.20244 145.124 4.43479 144.837 4.43479H137.079Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M117.258 17.6157C117.399 17.6157 117.541 17.5588 117.644 17.4435L123.062 8.96592L125.032 19.0523C125.095 19.2762 125.299 19.4339 125.532 19.4339H128.625C128.963 19.4339 129.212 19.11 129.127 18.7816L125.654 1.01849C125.585 0.743956 125.338 0.555054 125.055 0.555054H123.943C123.719 0.555054 123.513 0.678155 123.404 0.873983L117.445 10.1976C117.361 10.3402 117.155 10.3402 117.071 10.1976L111.12 0.868316C111.011 0.672488 110.805 0.555054 110.581 0.555054H109.461C109.178 0.555054 108.931 0.743956 108.862 1.01849L105.389 18.7816C105.304 19.11 105.552 19.4339 105.891 19.4339H108.982C109.215 19.4339 109.419 19.2746 109.482 19.0505L111.457 8.94892L116.872 17.4435C116.975 17.5588 117.116 17.6157 117.258 17.6157Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0027 12.108V18.9144C54.0027 19.2019 53.7703 19.4339 53.4838 19.4339H50.4762C50.1897 19.4339 49.9573 19.2019 49.9573 18.9144V12.108C49.9573 12.0139 49.9315 11.921 49.8824 11.8397L43.5299 1.34278C43.3199 0.997084 43.5696 0.555054 43.9739 0.555054H47.3184C47.5003 0.555054 47.6681 0.650134 47.7626 0.805349L51.9795 7.756L56.1445 0.807868C56.2377 0.650764 56.4064 0.555054 56.589 0.555054H59.9861C60.391 0.555054 60.64 0.997084 60.43 1.34278L54.0776 11.8397C54.0285 11.921 54.0027 12.0139 54.0027 12.108Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.3785 10.0449C83.3785 4.54278 87.3426 0.200867 93.141 0.200867C98.8584 0.200867 102.823 4.54278 102.823 10.0449C102.823 15.5457 98.8584 19.8347 93.141 19.8347C87.3426 19.8347 83.3785 15.5457 83.3785 10.0449ZM98.5892 10.0449C98.5892 6.75451 96.3237 4.32712 93.1145 4.32712C89.8783 4.32712 87.5589 6.75451 87.5589 10.0449C87.5589 13.3085 89.8235 15.7081 93.1145 15.7081C96.3237 15.7081 98.5892 13.3349 98.5892 10.0449Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.415 1.07422V18.9148C79.415 19.2016 79.1824 19.4339 78.8959 19.4339H75.8882C75.602 19.4339 75.3694 19.2016 75.3694 18.9148V11.7207H67.495V18.9148C67.495 19.2016 67.2626 19.4339 66.9765 19.4339H63.9685C63.6814 19.4339 63.45 19.2016 63.45 18.9148V1.07422C63.45 0.787718 63.6814 0.555054 63.9685 0.555054H66.9765C67.2626 0.555054 67.495 0.787718 67.495 1.07422V7.83691H75.3694V1.07422C75.3694 0.787718 75.602 0.555054 75.8882 0.555054H78.8959C79.1824 0.555054 79.415 0.787718 79.415 1.07422Z"
        />
    </svg>
);
