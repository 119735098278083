import React from "react";

import { LoadingContainer } from "@wayhome-uk/design-system";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useAuth } from "hooks/use-auth";

export const CustomerRoute: React.FC<RouteProps> = (props) => {
    const { user, isAuthInitialising } = useAuth();
    const { pathname, search } = useLocation();

    const uuidRegexString = /([0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/gi;

    const uuid = pathname.match(uuidRegexString) && pathname.match(uuidRegexString)[0];

    const pathnameWithUserUUID = uuid && user.uuid && pathname.replace(uuid, user.uuid);

    const userPath = search ? `${pathnameWithUserUUID}${search}` : pathnameWithUserUUID;

    return (
        <LoadingContainer isLoading={isAuthInitialising}>
            {user.uuid !== null && user.uuid !== uuid ? <Redirect to={userPath} /> : <Route {...props} />}
        </LoadingContainer>
    );
};
