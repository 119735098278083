import { FaqSectionHeading } from "./faq-section-heading";
import { ListItemHeading, OrderedList, UnorderedList } from "./list";
import { Paragraph } from "./paragraph";
import { EqualColumnsTable, Table, TableContainer, TableLabel, TableWrapper } from "./table";

export {
    ListItemHeading,
    OrderedList,
    UnorderedList,
    FaqSectionHeading,
    Paragraph,
    TableWrapper,
    Table,
    TableContainer,
    TableLabel,
    EqualColumnsTable,
};
