import React from "react";

import { PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import IconFloral from "components/media/svg/icon-floral-double.svg";
import { CtaButton } from "components/next-step-section/cta-button";
import { NewHeader2 } from "config/theme";

export interface IProps extends IBaseComponent {
    title?: string;
}

export const NextStepSection: React.FC<IProps> = ({ className, title = "See if we’re right for you" }) => (
    <Wrapper className={className} data-testid="next-step-section">
        <PageWidth>
            <Container>
                <Title>{title}</Title>
                <CtaButton />
            </Container>
        </PageWidth>
    </Wrapper>
);

const Wrapper = styled.div``;

const Title = styled.h2`
    ${NewHeader2};
    margin-bottom: ${({ theme }) => theme.spacing24};
`;

const Container = styled.div`
    padding: ${({ theme }) => theme.spacing40} 0;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing96} 0;
    }
`;

export const PrettyNextStepSection = styled(NextStepSection)`
    background: ${({ theme }) => theme.neutral75};

    ${Container} {
        @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
            background: url(${IconFloral}) 100% 100% no-repeat;
        }
    }
`;

PrettyNextStepSection.displayName = "PrettyNextStepSection";
