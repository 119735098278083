import React from "react";

import styled from "styled-components";

import { DailyMirror, EveningStandard, MailOnline, Sun, Telegraph, ThisIsMoney } from "./logos";

export const FeaturedIn = () => (
    <Wrapper>
        <Header> Featured in</Header>
        <LogosWrapper>
            <Telegraph />
            <Sun />
            <ThisIsMoney />
            <DailyMirror />
            <MailOnline />
            <EveningStandard />
        </LogosWrapper>
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: ${({ theme }) => theme.primary75};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        flex-direction: row;
    }
`;

const Header = styled.p`
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.6;
    position: relative;
    padding: 0 0.75rem 0.25rem;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        font-size: 1rem;
        padding: 0 2rem 0 0;
    }

    ::after {
        content: "";
        position: absolute;
        background-color: ${({ theme }) => theme.neutral800};
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;

        @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
            width: 1px;
            height: 100%;
            bottom: auto;
            left: auto;
            top: 0;
            right: 0;
        }
    }
`;

const LogosWrapper = styled.div`
    flex-wrap: wrap;
    display: flex;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        margin-left: 1.25rem;
    }

    > * {
        margin: 1rem 0.5625rem;
        margin-bottom: 0;
        height: 1.5rem;

        @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
            margin: 0 0.75rem;
            height: 2rem;
        }
    }
`;
