import React, { useEffect, useRef } from "react";

import Player from "@vimeo/player";
import styled from "styled-components/macro";

import { useCookieFirst } from "hooks/use-cookie-first";
import analytics from "utils/analytics";
import { getDoNotTrackValue, getVimeoIframeSrc } from "utils/video";

export interface IExplainerVideoProps {
    className?: string;
}

export const trackVideoIsPlayed = (value: { seconds: number }) => {
    analytics.sendEvent("explainer_video_play", {
        explainer_video_play: value.seconds,
    });
};

export const trackVideoIsPaused = (value: { seconds: number }) => {
    analytics.sendEvent("explainer_video_pause", {
        explainer_video_pause: value.seconds,
    });
};

export const trackFullVideoIsViewed = () => {
    analytics.sendEvent("explainer_video_watched", {
        explainer_video_watched: true,
    });
};

export const trackHalfOfVideoIsViewed = () => {
    analytics.sendEvent("explainer_video_watched_50", {
        explainer_video_watched_50: true,
    });
};

export const ExplainerVideo = ({ className }: IExplainerVideoProps) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const context = useCookieFirst();

    useEffect(() => {
        const iframe = iframeRef.current;

        if (iframe) {
            const player = new Player(iframe);
            const cuePointKey = { customKey: "half-of-video-viewed" };

            player.addCuePoint(47, cuePointKey);

            player.on("play", trackVideoIsPlayed);
            player.on("pause", trackVideoIsPaused);
            player.on("ended", trackFullVideoIsViewed);
            player.on("cuepoint", trackHalfOfVideoIsViewed);
        }
    }, [context]);

    return (
        <Wrapper data-testid="explainer-video" className={className}>
            <StyledIframe
                ref={iframeRef}
                src={getVimeoIframeSrc({
                    path: "https://player.vimeo.com/video/686185283",
                    queryObject: {
                        texttrack: "en-GB",
                        title: "false",
                        portrait: "false",
                        byline: "false",
                        dnt: getDoNotTrackValue(context.consent),
                    },
                })}
                title="wayhome-explainer-video"
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const StyledIframe = styled.iframe`
    width: 100%;
    height: 55vw;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        height: 400px;
    }
`;
