export const userActionTypes = {
    UPDATE: "user/UPDATE",
    LOGOUT: "user/LOGOUT",
};

export interface UserState {
    isIdentified: boolean;
    isInternal: boolean;
    utmParams: string | null;
    jobInformationGathered: boolean;
    applicant2JobInformationGathered: boolean;
}
