import { action } from "typesafe-actions";

import {
    CurrentAreaInfo,
    CustomerListing,
    CustomerListingDetails,
    CustomerListingsApiParams,
    ListingsActionTypes,
} from "./types";

export const fetchAllListingsRequest = (apiParams: CustomerListingsApiParams) => {
    return action(ListingsActionTypes.FETCH_ALL_REQUEST, { apiParams });
};

export const fetchAllListingsSuccess = (listings: CustomerListing[], totalResults: number) =>
    action(ListingsActionTypes.FETCH_ALL_SUCCESS, { listings, totalResults });

export const fetchSingleListingRequest = (
    ID: string,
    uuid: string,
    noTrack: boolean,
    hasPropertyUnderOffer?: boolean,
) => action(ListingsActionTypes.FETCH_REQUEST, { ID, uuid, noTrack, hasPropertyUnderOffer });

export const fetchSingleListingSuccess = (listing: CustomerListing, uuid: string) =>
    action(ListingsActionTypes.FETCH_SUCCESS, { listing, uuid });

export const fetchSavedAreaSuccess = (currentAreaInfo: CurrentAreaInfo, autoGeneratedSearchRadius = false) =>
    action(ListingsActionTypes.FETCH_SAVED_AREA_SUCCESS, { currentAreaInfo, autoGeneratedSearchRadius });

export const updateCurrentArea = (currentAreaInfo: CurrentAreaInfo) =>
    action(ListingsActionTypes.UPDATE_CURRENT_AREA_INFO, currentAreaInfo);

export const updateSingleListing = (listingDetails: CustomerListingDetails) =>
    action(ListingsActionTypes.UPDATE_LISTING, listingDetails);
