import React from "react";

import { Accordion } from "components/faqs";

import { EqualColumnsTable, Paragraph, TableContainer, TableLabel, TableWrapper } from "../../faq-styles";

export const SharedOwnership = () => (
    <Accordion title="Is this like shared ownership schemes?" id="shared-ownership">
        <Paragraph>
            No. But because you part-own and part-rent your home, it’s easy to get us confused with Shared Ownership
            schemes. We’re actually very different. Here’s how:
        </Paragraph>
        <TableWrapper>
            <TableLabel>Swipe right and left to view full table</TableLabel>
            <TableContainer>
                <EqualColumnsTable cols={2}>
                    <tbody>
                        <tr>
                            <th>
                                <span>Shared Ownership</span>
                            </th>
                            <th>
                                <span>Wayhome</span>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <span>Usually only new builds</span>
                            </td>
                            <td>
                                <span>Older properties with established value</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>High service fees and charges</span>
                            </td>
                            <td>
                                <span>No, or low service fees and charges</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Difficult to buy more of (each time you need a new mortgage)</span>
                            </td>
                            <td>
                                <span>
                                    Easy to buy more of (everything you pay above rent goes towards your ownership)
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Expensive to buy more of (each time you need a solicitor)</span>
                            </td>
                            <td>
                                <span>Easy to buy more of (from as little as &pound;50 with no fees)</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Difficult to sell</span>
                            </td>
                            <td>
                                <span>Easy to sell and move on if you want</span>
                            </td>
                        </tr>
                    </tbody>
                </EqualColumnsTable>
            </TableContainer>
        </TableWrapper>
        <Paragraph>
            For some people, Shared Ownership might be a better way to buy a home. We want to help people who can’t find
            a Shared Ownership scheme that works for them.
        </Paragraph>
    </Accordion>
);
