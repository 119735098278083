import React from "react";

import styled from "styled-components/macro";

export interface IBackgroundOverlayProps {
    className?: string;
    dataTestId?: string;
    children?: JSX.Element;
}

export const BackgroundOverlay = ({ className, dataTestId, children }: IBackgroundOverlayProps) => (
    <Wrapper className={className} data-testid={dataTestId}>
        <Overlay />
        <ModalWrapper>{children}</ModalWrapper>
    </Wrapper>
);

const Wrapper = styled.div`
    position: fixed;
    z-index: 5;
`;

const ModalWrapper = styled.div`
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    width: 100%;
    max-width: 488px;
    margin: 0 auto;
    position: fixed;

    @media (min-width: ${(props) => props.theme.largeMinWidth}) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        right: auto;
    }
`;

const Overlay = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.neutral1000};
    opacity: 0.8;
`;
