import { push, replace } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { routePaths } from "config/route-paths";
import { penceToPounds, roundToHundred, roundToOneDecimal, roundToTen, roundToThousand } from "utils/formatter";
import { generateURLQueryString, getNextSearchRadius } from "utils/helpers";

import { showApiError } from "../ui";
import { handleApiError } from "../ui/sagas";
import {
    fetchAllListingsRequest,
    fetchAllListingsSuccess,
    fetchSavedAreaSuccess,
    fetchSingleListingRequest,
    fetchSingleListingSuccess,
} from "./actions";
import { CustomerListing, ICustomerMatches, ListingsActionTypes } from "./types";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

const getPromotionalRentBasedOnOwnership = (ownership: number, promotionalRent: number, rent: number) => {
    const promotionRentBasedOnOwnershipRent = (1.0 - ownership) * promotionalRent;
    const roundedPromotionalRent = roundToTen(penceToPounds(promotionRentBasedOnOwnershipRent));

    //only return roundedPromotionalRent if it doesn't equal rent
    return roundedPromotionalRent === rent ? null : roundedPromotionalRent;
};

export const mapSingleListingMatchServiceData = (data: any): CustomerListing => {
    return {
        ID: data.listingID,
        title: data.title,
        address: data.address,
        zooplaURL: data.zooplaURL,
        rightmoveURL: data.rightmoveURL,
        imageURL: data.imageURL,
        totalBedrooms: data.totalBedrooms,
        totalBathrooms: data.totalBathrooms,
        rent: roundToTen(penceToPounds(data.rent)),
        fullRent: roundToTen(penceToPounds(data.fullRent)),
        deposit: roundToHundred(penceToPounds(data.deposit)),
        price: roundToThousand(penceToPounds(data.price)),
        distanceMiles: roundToOneDecimal(data.distanceMiles),
        description: data.description,
        isLiked: data.isLiked,
        isViewed: data.isViewed,
        isViable: data.isViable,
        latitude: data.latitude,
        longitude: data.longitude,
        createdAt: data.createdAt,
        ownership: data.ownership,
        minOwnership: data.minOwnership,
        isReadyToBuy: data.canGoOnViewings,
        isAvailable: data.visible,
        agentName: data.agentName,
        agentPhone: data.agentPhone,
        promotionalRentBasedOnFullRent: data.promotionalRent ? penceToPounds(data.promotionalRent) : null,
        promotionalRent: data.promotionalRent
            ? getPromotionalRentBasedOnOwnership(
                  data.ownership,
                  data.promotionalRent,
                  roundToTen(penceToPounds(data.rent)),
              )
            : null,
    };
};

export const mapMultipleListingMatchServiceData = (data: any[]): CustomerListing[] =>
    data.map((item) => mapSingleListingMatchServiceData(item));

export function* handleFetchSingle(action: ReturnType<typeof fetchSingleListingRequest>) {
    if (action.payload.hasPropertyUnderOffer) {
        yield call((args) => window.location.assign(args), `${PUBLIC_URL}/buying/status`);
        return;
    }
    try {
        const response: Response = yield call(
            fetch,
            `${API_ENDPOINT}/v1/customer-matches/${action.payload.uuid}/detail/${action.payload.ID}${
                action.payload.noTrack ? "?noTrack=true" : ""
            }`,
        );

        if (response.ok) {
            const body: ICustomerMatches = yield response.json();

            if (body.matchesDisabledForUser) {
                yield put(push(routePaths.product.homesNotFound.path));
            }

            const mappedData = mapSingleListingMatchServiceData(body);

            yield put(fetchSingleListingSuccess(mappedData, action.payload.uuid));
        } else {
            yield call(handleApiError, response);
        }
    } catch (error) {
        yield put(showApiError(error as string));
    }
}

export function* handleFetchAll(action: ReturnType<typeof fetchAllListingsRequest>) {
    try {
        const {
            areaName,
            customerID,
            radius,
            filterBy,
            sortBy,
            page,
            pageSize,
            longitude,
            latitude,
            minTotalBedrooms,
            maxTotalBedrooms,
            minRent,
            maxRent,
            propertyType,
            hasPropertyUnderOffer,
            noTrack,
            userSelectedRadius,
            locationChanged,
            feature,
        } = action.payload.apiParams;

        if (hasPropertyUnderOffer) {
            yield call((args) => window.location.assign(args), `${PUBLIC_URL}/buying/status`);
            return;
        }

        const radiusValue = radius ? radius : `${process.env.REACT_APP_DEFAULT_RADIUS}`;
        const radiusUnit = radiusValue ? "miles" : undefined;
        const sortByValue = sortBy || "";
        const queryString = generateURLQueryString([
            { radius: radiusValue },
            { radiusUnit },
            { forLikedTab: filterBy === "liked" },
            { sortBy: sortByValue },
            { page },
            { pageSize },
            { longitude },
            { latitude },
            { minTotalBedrooms },
            { maxTotalBedrooms },
            { minRent },
            { maxRent },
            { propertyType },
            { noTrack },
            { feature },
        ]);

        const response: Response = yield call(fetch, `${API_ENDPOINT}/v1/customer-matches/${customerID}${queryString}`);

        if (response.ok) {
            const body: ICustomerMatches = yield response.json();

            const shouldIncreaseSearchRadius =
                locationChanged &&
                !userSelectedRadius &&
                body.count === 0 &&
                radiusValue < parseInt(`${process.env.REACT_APP_MAX_RADIUS}`);

            if (shouldIncreaseSearchRadius) {
                const newRadius = getNextSearchRadius(parseInt(`${radiusValue}`));

                yield put(
                    fetchSavedAreaSuccess(
                        {
                            name: `${areaName}`,
                            longitude: `${longitude}`,
                            latitude: `${latitude}`,
                            radius: `${newRadius}`,
                            userSelectedRadius: false,
                        },
                        shouldIncreaseSearchRadius,
                    ),
                );

                yield put(
                    replace(
                        window.location.pathname + window.location.search.replace(/radius=\d+/, `radius=${newRadius}`),
                    ),
                );

                return;
            }

            yield put(
                fetchSavedAreaSuccess({
                    name: `${areaName}`,
                    longitude: `${longitude}`,
                    latitude: `${latitude}`,
                    radius: `${radius}`,
                    userSelectedRadius,
                }),
            );

            if (body.matchesDisabledForUser) {
                yield put(push(routePaths.product.homesNotFound.path));
            }

            const mappedData = mapMultipleListingMatchServiceData(body.results);

            yield put(fetchAllListingsSuccess(mappedData, body.count));
        } else {
            yield call(handleApiError, response);
        }
    } catch (error) {
        yield put(showApiError(error as string));
    }
}

export function* watchFetchSingleRequests() {
    yield takeLatest(ListingsActionTypes.FETCH_REQUEST, handleFetchSingle);
}

export function* watchFetchAllRequests() {
    yield takeLatest(ListingsActionTypes.FETCH_ALL_REQUEST, handleFetchAll);
}

export function* listingsSagas() {
    yield all([watchFetchAllRequests(), watchFetchSingleRequests()]);
}
