import React from "react";

export const DailyMirror = () => (
    <svg
        width="45"
        height="24"
        viewBox="0 0 45 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Daily Mirror"
    >
        <g>
            <path d="M43.9982 5H0.5V19H43.9982V5Z" fill="#333331" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.0752 10.2146C34.1498 10.2146 35.7288 11.3331 35.7299 13.2681C35.7311 15.1575 34.1498 16.3106 32.0752 16.3106C30.0005 16.3106 28.4163 15.1575 28.4204 13.2681C28.4249 11.3331 30.0005 10.2146 32.0752 10.2146ZM6.90464 7.80018L9.00125 12.4304H9.02627L11.1216 7.80018H14.284V16.1995L11.9247 16.1999L11.9298 11.8107H11.9063L10.0522 16.1999H7.97134L6.1158 11.8107H6.0949L6.10138 16.1999H3.74207V7.80018H6.90464ZM17.6037 10.3719V16.1995H15.2645V10.3719H17.6037ZM22.7333 10.2377C23.0053 10.2377 23.3576 10.3114 23.5732 10.3658L23.152 12.0017C22.9448 11.9296 22.8887 11.9155 22.6168 11.9155C21.4931 11.9155 20.8402 12.5978 20.8402 13.7387V16.1995H18.589V10.3719H20.7129V11.4457H20.7387C21.1403 10.7075 21.7233 10.2377 22.7333 10.2377ZM27.9888 10.2377C28.2608 10.2377 28.6131 10.3114 28.8286 10.3658L28.4075 12.0017C28.2004 11.9296 28.1442 11.9155 27.8723 11.9155C26.7486 11.9155 26.0957 12.5978 26.0957 13.7387V16.1995H23.8443V10.3719H25.9686V11.4457H25.9943C26.3958 10.7075 26.9786 10.2377 27.9888 10.2377ZM40.3245 10.2377C40.5965 10.2377 40.9487 10.3114 41.1643 10.3658L40.7432 12.0017C40.536 11.9296 40.4798 11.9155 40.2078 11.9155C39.0842 11.9155 38.4313 12.5978 38.4313 13.7387V16.1995H36.18V10.3719H38.3041V11.4457H38.3299C38.7315 10.7075 39.3143 10.2377 40.3245 10.2377ZM32.0291 11.872C31.1322 11.872 30.6639 12.3958 30.6639 13.2681C30.6639 14.1295 31.1322 14.6821 32.0498 14.6821C32.9678 14.6821 33.4864 14.1295 33.4864 13.2681C33.4864 12.3958 32.9678 11.872 32.0291 11.872ZM16.4354 7.30536C17.1878 7.30536 17.7977 7.82191 17.7977 8.45895C17.7977 9.09599 17.1878 9.61228 16.4354 9.61228C15.6829 9.61228 15.0731 9.09599 15.0731 8.45895C15.0731 7.82191 15.6829 7.30536 16.4354 7.30536Z"
                fill="white"
            />
        </g>
    </svg>
);
