import React from "react";

import { Accordion } from "components/faqs";
import { MINIMUM_DEPOSIT } from "config/constants";
import { numberWithCommas } from "utils/formatter";

import { Paragraph } from "../../faq-styles";

const minDeposit = numberWithCommas(MINIMUM_DEPOSIT);

export const RequiredDeposit = () => (
    <Accordion title="How much deposit do I need?" id="required-deposit">
        <Paragraph>You need a 5% deposit, which should be at least &pound;{minDeposit}.</Paragraph>
    </Accordion>
);
