import React from "react";

import styled from "styled-components";

import { filteredMenuByLoggedIn, getSubMenu } from "../helpers";
import { HeaderLink } from "../types";
import { NavbarLoginMobileBody } from "./navbar-login-mobile-body";
import { NavbarLoginMobileHeader } from "./navbar-login-mobile-header";

export interface INavbarLoginMobileProps {
    className?: string;
    isShowing: boolean;
    onClick?: () => void;
    isLoggedIn: boolean;
    menu: HeaderLink[];
}

export interface State {
    headerHeight: number;
}

export const NavbarLoginMobile = ({ className, isShowing, isLoggedIn, onClick, menu }: INavbarLoginMobileProps) => {
    const filteredMobileMenuOptions = menu
        .filter((option: HeaderLink) => filteredMenuByLoggedIn(option, isLoggedIn))
        .map((option: HeaderLink) => getSubMenu(option, menu, "small", "more", "isInMoreMenu"))
        .filter((option: HeaderLink) => option.appearance.small.isInMainMenu);

    filteredMobileMenuOptions.splice(filteredMobileMenuOptions.length - 1, 0, filteredMobileMenuOptions[3]);
    filteredMobileMenuOptions.splice(3, 1);

    return (
        <Wrapper data-test-id="navbar-login-mobile" className={className}>
            <NavbarLoginMobileOverlay isShowing={isShowing} />
            <NavbarMobileWrapper>
                <NavbarLoginMobileHeader isShowing={isShowing} onClick={onClick} />
                <NavbarLoginMobileBody isShowing={isShowing} onClick={onClick} mobileMenu={filteredMobileMenuOptions} />
            </NavbarMobileWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: block;
    position: relative;
    z-index: 1;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        display: none;
    }
`;

const NavbarLoginMobileOverlay = styled.div<{ isShowing: boolean }>`
    ${({ isShowing, theme }) =>
        isShowing === true &&
        `
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: ${theme.neutral900};
        opacity: 0.9;
    `}
`;

const NavbarMobileWrapper = styled.div`
    position: relative;
`;
