import styled from "styled-components/macro";

import { NewHeader2 } from "config/theme";

export const FaqSectionHeading = styled.h2`
    ${NewHeader2};
    margin-bottom: ${({ theme }) => theme.spacing24};
    font-weight: 500;
    max-width: 800px;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing40};
    }
`;
