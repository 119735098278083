import React, { Component } from "react";

import { ShortLeadCss } from "@wayhome-uk/design-system";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components/macro";

import IconMinus from "components/media/svg/icon-minus.svg";
import IconPlus from "components/media/svg/icon-plus.svg";

export interface Props {
    className?: string;
    title: string;
    children: React.ReactNode;
    id?: string;
}

export interface State {
    isShowing: boolean;
}

export class Accordion extends Component<Props & RouteComponentProps, State> {
    state = { isShowing: false };

    componentDidMount() {
        const { hash } = this.props.location;
        const { id } = this.props;

        const hashName = hash.replace("#", "");

        if (hashName === id) {
            this.setState({ isShowing: true });
        }
    }

    handleToggle = () => {
        this.setState((prevState) => ({ isShowing: !prevState.isShowing }));
    };

    render() {
        const { className, title, children, id } = this.props;
        return (
            <Wrapper isShowing={this.state.isShowing} className={className} data-test-id="faq-item" id={id}>
                <AccordionHeader as="h3" isShowing={this.state.isShowing} onClick={this.handleToggle}>
                    <IconWrapper isShowing={this.state.isShowing} />
                    {title}
                </AccordionHeader>
                <AccordionText isShowing={this.state.isShowing}>{children}</AccordionText>
            </Wrapper>
        );
    }
}

const AccordionHeader = styled.div<{ isShowing: boolean }>`
    ${ShortLeadCss};
    color: ${(props) => props.theme.primary500};
    font-weight: 600;
    margin-bottom: ${(props) => props.theme.spacing16};
    position: relative;
    cursor: pointer;

    &:before {
        position: absolute;
        content: " ";
        background: url(${(props) => (props.isShowing ? IconMinus : IconPlus)}) center center no-repeat;
        left: 0;
        top: 3px;
        width: ${(props) => props.theme.spacing16};
        height: ${(props) => props.theme.spacing16};

        @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
            top: 3px;
            width: ${(props) => props.theme.spacing24};
            height: ${(props) => props.theme.spacing24};
        }
    }
`;

const IconWrapper = styled.span<{ isShowing: boolean }>``;

const AccordionText = styled.div<{ isShowing: boolean }>`
    display: ${(props) => (props.isShowing ? "block" : "none")};
    color: ${(props) => props.theme.neutral700};
    margin-bottom: ${(props) => props.theme.spacing16};

    :last-child {
        margin-bottom: 0;
    }
`;

const Wrapper = styled.div<{ isShowing: boolean }>`
    margin-bottom: ${(props) => props.theme.spacing16};
    scroll-margin-top: 84px;

    ${AccordionHeader},
    ${AccordionText} {
        padding-left: ${(props) => props.theme.spacing32};

        @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
            padding-left: ${(props) => props.theme.spacing40};
        }
    }

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        margin-bottom: ${(props) => props.theme.spacing32};
        scroll-margin-top: ${(props) => props.theme.spacing96};
    }
`;

AccordionText.displayName = "AccordionText";

export default withRouter(Accordion);
