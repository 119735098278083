import React from "react";

import { Accordion } from "components/faqs";
import { MINIMUM_HOUSEHOLD_INCOME } from "config/constants";
import { numberWithCommas } from "utils/formatter";

import { Paragraph } from "../../faq-styles";

const minHouseholdIncome = numberWithCommas(MINIMUM_HOUSEHOLD_INCOME);

export const RequiredIncome = () => (
    <Accordion title="What household income do I need to have?" id="required-income">
        <Paragraph>You need to have a minimum household income of &pound;{minHouseholdIncome} before tax.</Paragraph>
    </Accordion>
);
