import { ComponentType } from "react";

/* Adapted from https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5 */

export const maxRetries = 5;

export const retry = (fn: any, retriesLeft = maxRetries, interval = 1000) => {
    let module: Promise<{ default: ComponentType<any> }>;

    module = new Promise((resolve, reject) => {
        retryLazy.callLazy(fn, retriesLeft, interval, resolve, reject);
    });

    return module;
};

export const callLazy = (fn: any, retriesLeft: number, interval: number, resolve: any, reject: any) => {
    return fn()
        .then(resolve)
        .catch((error: Error) => {
            retryLazy.retryOnError(fn, retriesLeft, error, interval, resolve, reject);
        });
};

export const retryOnError = (fn: any, retriesLeft: number, error: any, interval: number, resolve: any, reject: any) => {
    setTimeout(() => {
        if (retriesLeft === 1) {
            reject(error);
            return;
        }

        retryLazy.retry(fn, retriesLeft - 1, interval).then(resolve, reject);
    }, interval);
};

const retryLazy = {
    retry,
    retryOnError,
    callLazy,
};

export default retryLazy;
