import React from "react";

import { ShortLeadLabelCss } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import { NewHeader2 } from "config/theme";
import { valueToCurrency } from "utils/formatter";

export interface IProps extends IBaseComponent {
    homeValue: number;
}

export const HomeOutput: React.FC<IProps> = ({ className, homeValue }) => (
    <>
        <Wrapper className={className}>
            <Title as="span">Wayhome budget</Title>
            <Output as="span">{valueToCurrency(homeValue)}</Output>
        </Wrapper>
    </>
);

const Wrapper = styled.div`
    display: inline-block;
    padding: ${({ theme }) => theme.spacing24};
    border-radius: ${({ theme }) => theme.radius1};
    background: ${({ theme }) => theme.neutral800};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing16};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        max-width: 300px;
        margin-right: ${({ theme }) => theme.spacing16};
        margin-bottom: 0;
    }
`;

const Title = styled.span`
    ${ShortLeadLabelCss};
    display: block;
    color: ${({ theme }) => theme.neutral0};
    margin-bottom: ${({ theme }) => theme.spacing8};
`;

const Output = styled.span`
    ${NewHeader2};
    display: block;
    font-weight: 600;
    line-height: 1;
    color: ${({ theme }) => theme.neutral0};
`;
