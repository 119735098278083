import React from "react";

import { LongParagraph, PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import IconDolls from "components/media/svg/icon-dolls.svg";
import { MINIMUM_HOUSEHOLD_INCOME } from "config/constants";
import { NewHeader2 } from "config/theme";
import { useCalculator } from "hooks/use-calculator";
import { BankOutput } from "pages/home/bank-output";
import { HomeOutput } from "pages/home/home-output";
import { InputIncome } from "pages/home/input-income";

export const CompareBudgets: React.FC<IBaseComponent> = ({ className }) => {
    const { wayhomeValue, mortgageValue, handleIncomeChange } = useCalculator(MINIMUM_HOUSEHOLD_INCOME);
    return (
        <div className={className}>
            <PageWidth>
                <Container>
                    <Title>Buy a home worth up to 10x your income</Title>
                    <>
                        <InputIncome onAfterChange={handleIncomeChange} />
                        <OutputContainer>
                            <HomeOutput homeValue={wayhomeValue} />
                            <BankOutput bankValue={mortgageValue} />
                        </OutputContainer>
                        <CalculatorSubText>
                            *Based on UK average first time buyer loan to income multiple May 2021{" "}
                            <UKFinance>(UK Finance)</UKFinance>.
                        </CalculatorSubText>
                    </>
                </Container>
            </PageWidth>
        </div>
    );
};

const Container = styled.div`
    padding: ${({ theme }) => theme.spacing48} 0;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing64} 0;
    }

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        background: url(${IconDolls}) 100% center no-repeat;
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    font-weight: 500;
    max-width: 800px;
`;

const OutputContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${({ theme }) => theme.spacing48};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-top: ${({ theme }) => theme.spacing48};
        margin-bottom: ${({ theme }) => theme.spacing16};
    }
`;

const CalculatorSubText = styled(LongParagraph)`
    color: ${({ theme }) => theme.neutral800};
    margin-top: ${({ theme }) => theme.spacing16};
    margin-bottom: ${({ theme }) => theme.spacing32};
    max-width: 100%;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing40};
    }
`;

const UKFinance = styled.strong`
    font-weight: 500;
`;
