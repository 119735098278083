import React from "react";

import { ShortLeadLabelCss } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import { NewHeader2 } from "config/theme";
import { valueToCurrency } from "utils/formatter";

export interface IProps extends IBaseComponent {
    bankValue: number;
}

export const BankOutput: React.FC<IProps> = ({ className, bankValue }) => (
    <Wrapper className={className}>
        <Title as="span">A bank may lend*</Title>
        <Output as="span">{valueToCurrency(bankValue)}</Output>
    </Wrapper>
);

const Wrapper = styled.div`
    display: inline-block;
    padding: ${({ theme }) => theme.spacing24};
    border-radius: ${({ theme }) => theme.radius1};
    background-color: ${({ theme }) => theme.neutral50};
    width: 100%;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        max-width: 300px;
    }
`;

const Title = styled.span`
    ${ShortLeadLabelCss};
    display: block;
    color: ${({ theme }) => theme.neutral800};
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing8};
`;

const Output = styled.span`
    ${NewHeader2};
    display: block;
    font-weight: 600;
    line-height: 1;
`;
