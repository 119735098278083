import React from "react";

import * as Sentry from "@sentry/browser";
import { auth, initializeApp } from "firebase/app";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";

import { getLDSecondaryKey } from "utils/helpers";

import App from "./App";
import { history } from "./store";
import { getConfiguredStore } from "./store";

const {
    REACT_APP_SENTRY_KEY,
    REACT_APP_SENTRY_ID,
    REACT_APP_ENV,
    REACT_APP_DOMAIN_NAME,
    REACT_APP_FIREBASE_KEY,
    REACT_APP_FIREBASE_DOMAIN,
    REACT_APP_FIREBASE_TENANT,
    REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
} = process.env;

if (REACT_APP_SENTRY_KEY && REACT_APP_SENTRY_ID && REACT_APP_ENV && REACT_APP_DOMAIN_NAME) {
    const whiteListRegex = new RegExp(`https?:\\/\\/(www\\.)?(${REACT_APP_DOMAIN_NAME})\\.(com|co.uk)`);

    Sentry.init({
        dsn: `https://${REACT_APP_SENTRY_KEY}@sentry.io/${REACT_APP_SENTRY_ID}`,
        environment: REACT_APP_ENV,
        whitelistUrls: [whiteListRegex],
        ignoreErrors: ["Non-Error promise rejection captured"],
    });
}

initializeApp({
    apiKey: REACT_APP_FIREBASE_KEY,
    authDomain: REACT_APP_FIREBASE_DOMAIN,
});
auth().tenantId = REACT_APP_FIREBASE_TENANT || null;

const store = getConfiguredStore();

if (REACT_APP_LAUNCH_DARKLY_CLIENT_ID) {
    const ldSecondaryKey = getLDSecondaryKey();

    (async () => {
        const LDProvider = await asyncWithLDProvider({
            clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
            user: {
                key: "anonymous",
                secondary: ldSecondaryKey,
            },
        });

        ReactDOM.render(
            <LDProvider>
                <App store={store} history={history} />
            </LDProvider>,
            document.getElementById("root") as HTMLElement,
        );
    })();
} else {
    ReactDOM.render(<App store={store} history={history} />, document.getElementById("root") as HTMLElement);
}
