import { LongLeadCss, ShortLead } from "@wayhome-uk/design-system";
import styled from "styled-components/macro";

export const ListItemHeading = styled(ShortLead)`
    color: ${(props) => props.theme.neutral800};
    font-weight: 600;
    margin-bottom: ${(props) => props.theme.spacing8};
`;

export const OrderedList = styled.ol`
    margin-bottom: ${(props) => props.theme.spacing16};
    padding-left: ${(props) => props.theme.spacing16};
    li {
        ${LongLeadCss};
        list-style-type: decimal;
    }

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        padding-left: ${(props) => props.theme.spacing24};
    }
`;

export const UnorderedList = styled.ul`
    li {
        ${LongLeadCss};
        margin-bottom: ${(props) => props.theme.spacing16};
        padding-left: ${(props) => props.theme.spacing16};
        position: relative;

        &:before {
            content: " ";
            width: ${(props) => props.theme.spacing8};
            height: ${(props) => props.theme.spacing8};
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 5px;
            transform: translate(0, 50%);
            background: ${(props) => props.theme.neutral400};
            @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
                top: 10px;
            }
        }
    }
`;
