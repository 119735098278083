export interface IWindow {
    Intercom: (event: string, data?: Record<string, string>) => void;
    gtag: (config: string, targetId: string, additionalConfigInfo?: Record<string, string | boolean | number>) => void;
    ga: (
        methodName: string,
        trackerName: string,
        eventName: string,
        eventValue: string | Record<string, string | boolean | number>,
    ) => void;
}

/** Typed customized windows object */
export const customWindow = window as unknown as IWindow & Window & typeof globalThis;
