import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

export const PrimaryLink = styled(NavLink)`
    color: ${({ theme }) => theme.neutral0};
`;

export const SecondaryLink = styled(NavLink)`
    color: ${({ theme }) => theme.primary500};
`;
