import { all, put, takeLatest } from "redux-saga/effects";

import { ListingsActionTypes } from "store/listings";
import { hideLoadingIndicator, openCloseLoginModal, showApiError, showLoadingIndicator } from "store/ui/actions";

export function* handleApiRequests() {
    yield put(showLoadingIndicator());
}

export function* handleApiSuccess() {
    yield put(hideLoadingIndicator());
}

export function* handleOpenCloseModal() {
    yield put(openCloseLoginModal());
}

export function* handleApiError(response: Response) {
    const body: {
        error: string;
    } = yield response.json();

    yield put(showApiError(body.error));
}

function* watchApiRequests() {
    yield takeLatest([ListingsActionTypes.FETCH_ALL_REQUEST], handleApiRequests);
}

function* watchApiSuccess() {
    yield takeLatest([ListingsActionTypes.FETCH_ALL_SUCCESS], handleApiSuccess);
}

function* UISagas() {
    yield all([watchApiRequests(), watchApiSuccess()]);
}

export { UISagas };
