import { LongLead } from "@wayhome-uk/design-system";
import styled from "styled-components/macro";

export const Paragraph = styled(LongLead)`
    margin-bottom: ${(props) => props.theme.spacing16};
    strong {
        font-weight: 600;
    }
    a {
        font-weight: 700;
        color: ${(props) => props.theme.primary500};
    }
`;
