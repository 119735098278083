import React, { useEffect } from "react";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { GlobalStyles, Header2, MuiTheme, ShortParagraph } from "@wayhome-uk/design-system";
import styled from "styled-components";
import { ThemeProvider } from "styled-components/macro";

import IconWayhomeLogo from "components/media/svg/icon-wayhome-logo.svg";
import { MetaTags } from "components/meta-tags";
import { Maintenance } from "components/svgs";
import { Theme } from "config/theme";
import { shutdownIntercom } from "utils/analytics";

export const MaintenanceMode = () => {
    useEffect(() => {
        shutdownIntercom();
    }, []);

    return (
        <ConstrainedWrapper>
            <MetaTags
                title="Wayhome | Gradual homeownership"
                description="Wayhome helps you buy a home when a mortgage doesn’t work. Part buy, part rent a home you actually love with a 5% deposit. Then gradually buy us out over time."
            />
            <ThemeProvider theme={Theme}>
                <MuiThemeProvider theme={MuiTheme}>
                    <GlobalStyles />
                    <BlankNavHeader>
                        <img src={IconWayhomeLogo} alt="wayhome-logo" />
                    </BlankNavHeader>
                    <ImageWrapper>
                        <Maintenance />
                        <Header>We&apos;re making improvements to our website.</Header>
                        <Paragraph>It&apos;ll be back to normal shortly.</Paragraph>
                    </ImageWrapper>
                </MuiThemeProvider>
            </ThemeProvider>
        </ConstrainedWrapper>
    );
};

const ConstrainedWrapper = styled.div`
    width: 100%;

    @media (min-width: ${Theme.mediumMinWidth}) {
        max-width: ${Theme.largeMaxWidth};
        margin: 0 auto;
    }
`;
const BlankNavHeader = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
    width: 100%;

    @media (min-width: ${Theme.mediumMinWidth}) {
        justify-content: flex-start;
        padding: 2rem 150px;
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;

    svg {
        width: 100%;
        max-width: 400px;
        min-width: 30%;
        margin-bottom: 2rem;

        @media (min-width: ${Theme.mediumMinWidth}) {
            margin-bottom: 4rem;
        }
    }

    @media (min-width: ${Theme.mediumMinWidth}) {
        padding-top: 4rem;

        svg {
            max-width: 700px;
            min-width: 40%;
        }
    }
`;

const Header = styled(Header2)`
    margin: 0 0 1rem 0;
    font-size: ${Theme.type18};
    text-align: center;
    font-weight: 500;

    @media (min-width: ${Theme.mediumMinWidth}) {
        font-size: ${Theme.type28};
    }
`;

const Paragraph = styled(ShortParagraph)`
    margin: 0;
    font-size: ${Theme.type14};

    @media (min-width: ${Theme.mediumMinWidth}) {
        font-size: ${Theme.type24};
    }
`;
