export const InteractionsActionTypes = {
    SEND_INTERACTION: "interactions/SEND_INTERACTION",
};

export type SendInteractionArgs = {
    uuid: string;
    action: string;
    listings?: string | string[];
    message?: string | number | Record<string, unknown>;
    application?: string;
    applicant?: string;
    listing?: string;
};
