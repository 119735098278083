import { memo, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { UISelectors } from "store/ui";

interface IProps {
    visitedUrl: Map<string, number>;
}

const DefaultKey = "init-enter";

const scrollTo = (scrollnumber = 0): number =>
    window.requestAnimationFrame(() => {
        window.scrollTo(0, scrollnumber);
    });

const getScrollPage = (): number => {
    const docScrollTop = document?.documentElement?.scrollTop || 0;

    return window.pageYOffset || docScrollTop;
};

function ScrollRestoration({ history, visitedUrl }: RouteComponentProps & IProps) {
    const isLoading = useSelector(UISelectors.getUILoadingState);
    const [shouldScroll, setShouldScroll] = useState(false);
    const locationKey = history?.location?.key;

    useEffect(() => {
        window.addEventListener("popstate", () => setShouldScroll(true));

        return () => {
            window.removeEventListener("popstate", () => setShouldScroll(false));
        };
    }, []);

    useEffect(() => {
        const existingRecord = visitedUrl.get(locationKey || DefaultKey);

        if (existingRecord !== undefined && !isLoading) {
            scrollTo(existingRecord);
            setShouldScroll(false);
        }
    }, [shouldScroll, isLoading, locationKey, visitedUrl]);

    return null;
}

export default withRouter(
    memo(ScrollRestoration, (prevProps, nextProps) => {
        const { location: prevLocation, visitedUrl, history } = prevProps;
        const { location: nextLocation } = nextProps;

        const key = prevLocation.key || DefaultKey;

        const locationChanged =
            nextLocation.pathname !== prevLocation.pathname || nextLocation.search !== prevLocation.search;

        const scroll = getScrollPage();

        if (locationChanged) {
            if (history.action !== "POP") {
                scrollTo(0);
                visitedUrl.set(key, scroll);
            } else {
                visitedUrl.set(key, scroll);
            }
        }

        return false;
    }),
);
