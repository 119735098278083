import React from "react";

import { BaseComponent } from "types";

import { Theme } from "config/theme";

interface Props extends BaseComponent {
    tickColour?: string;
    backgroundColour?: string;
}

export const IconLinkedIn: React.FC<Props> = ({
    className,
    tickColour = Theme.neutral800,
    backgroundColour = Theme.neutral0,
}) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.5 3H6.5C4.567 3 3 4.567 3 6.5V19.5C3 21.433 4.567 23 6.5 23H19.5C21.433 23 23 21.433 23 19.5V6.5C23 4.567 21.433 3 19.5 3Z"
            fill="white"
        />
        <path d="M8.63125 10.3564H5.80937V20.5814H8.63125V10.3564Z" fill="#023841" />
        <path
            d="M18.0625 10.356C15.9812 9.56223 14.475 10.906 13.8312 11.656V10.356H11.0187V20.581H13.8312V15.1872C13.8152 14.5036 14.0651 13.8404 14.5281 13.3372C14.6603 13.1816 14.8232 13.055 15.0065 12.9652C15.1899 12.8754 15.3898 12.8244 15.5937 12.8154C15.8219 12.811 16.0485 12.8527 16.2601 12.9381C16.4717 13.0235 16.6638 13.1507 16.825 13.3122C17.1745 13.6775 17.3655 14.1661 17.3562 14.6716V20.581H20.5312V14.2497C20.5312 14.2497 20.8844 11.4122 18.0625 10.356Z"
            fill="#023841"
        />
        <path
            d="M7.21875 8.94395C8.19215 8.94395 8.98125 8.15485 8.98125 7.18145C8.98125 6.20804 8.19215 5.41895 7.21875 5.41895C6.24535 5.41895 5.45625 6.20804 5.45625 7.18145C5.45625 8.15485 6.24535 8.94395 7.21875 8.94395Z"
            fill="#023841"
        />
    </svg>
);
