export const bannerUsedDirectlyInComponent = (pathName: string): boolean => {
    const isCreditCheckFormPage = pathName.includes("/credit-check") && !pathName.includes("/thank-you");
    const isSuitabilityPage = pathName === "/suitability" || pathName === "/suitability-stage-2";
    const isMatchesPage =
        pathName.includes("/suitability/matches/") &&
        !pathName.includes("/listing") &&
        !pathName.includes("/offer-confirmed") &&
        !pathName.includes("/liked");

    return Boolean(isMatchesPage || isCreditCheckFormPage || isSuitabilityPage || pathName === "/password-reset");
};

export const getBackgroundColorByPathName = (pathName: string): string => {
    const isCreditCheckFormPage = pathName.includes("/credit-check") && !pathName.includes("/thank-you");
    const isMatchesPage =
        pathName.includes("/suitability/matches/") &&
        !pathName.includes("/listing") &&
        !pathName.includes("/offer-confirmed") &&
        !pathName.includes("/liked");

    if (isMatchesPage) {
        return "neutral200";
    }
    if (isCreditCheckFormPage) {
        return "neutral50";
    }
    if (pathName === "/suitability" || pathName === "/suitability-stage-2") {
        return "neutral50";
    }
    if (pathName === "/password-reset") {
        return "neutral0";
    }
    return "";
};
