import React from "react";

import { SecondaryButton, SecondaryLink } from "components/buttons-and-links";
import { routePaths } from "config/route-paths";

export const CtaButton = () => (
    <SecondaryButton>
        <SecondaryLink data-test-id="cta-next-step" to={routePaths.product.suitability.path}>
            Sign Up
        </SecondaryLink>
    </SecondaryButton>
);
