import React from "react";

import { ShortParagraphCss } from "@wayhome-uk/design-system";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { routes } from "config/routes";

export const AuthMessage = () => {
    return (
        <Message>
            In order to provide you with a better service, we&apos;ve introduced new security measures. If you&apos;ve
            already registered but have yet to create a password, you can create one now by{" "}
            <StyledLink to={routes.product.passwordReset.path}>resetting your password via this link</StyledLink>.
        </Message>
    );
};

export const Message = styled.div`
    ${ShortParagraphCss};
    background: ${({ theme }) => theme.action300};
    border-radius: ${({ theme }) => theme.radius1};
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    color: ${({ theme }) => theme.action900};
`;

const StyledLink = styled(Link)`
    ${ShortParagraphCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: bold;
`;
