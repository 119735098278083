import { TConsent } from "hooks/use-cookie-first/type";

export const getDoNotTrackValue = (consent: TConsent | null): string => {
    if (consent) {
        return `${!consent.functional}`;
    }

    const consentData = localStorage.getItem("cookiefirst-consent");
    if (consentData) {
        const { functional } = JSON.parse(consentData);

        return `${!functional}`;
    }

    return "true";
};

export const getVimeoIframeSrc = ({ path, queryObject }: { path: string; queryObject: { [key: string]: string } }) => {
    const queryString = new URLSearchParams(queryObject).toString();
    const iframeSrc = `${path}?${queryString}`;

    return iframeSrc;
};
