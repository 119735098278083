import { isNil, omitBy } from "lodash";

import { routePaths } from "config/route-paths";
import {
    IApplicant,
    IApplicantProfileV2,
    IApplicationStatus,
    ICreditStatus,
    IUserProfile,
    IUserProfileV2,
    TWaitListStatus,
} from "hooks/use-auth";

import { IApplicantsStatus } from "../pages/application-process/types";
import { penceToPounds, roundToFiveThousand, roundToTen } from "./formatter";

export interface IApplicationAPIResponseStatus {
    credit_decision?: string;
    application_id: string;
    customer_id: string;
    primary_applicant_id: string;
    is_suitable: boolean;
    can_go_on_viewings: boolean;
    has_property_under_offer: boolean;
    remove_customer_from_viewings: boolean | null;
    applicants: IApplicantAPIResponse[];
    waitlist_status?: TWaitListStatus;
}

export interface IApplicantAPIResponse {
    id: string;
    first_name: string;
    last_name: string;
    credit_status: ICreditStatus;
    income_verification_status: string | null;
    income_verification_url?: string;
}

const formatHomePrice = (homePrice: number) =>
    roundToFiveThousand(penceToPounds(homePrice > 50000000 ? 50000000 : homePrice));

export const convertApplicationStatusResponse = (applicationStatusData: IApplicationAPIResponseStatus) => {
    return applicationStatusData.applicants
        ? {
              applicationId: applicationStatusData.application_id,
              customerId: applicationStatusData.customer_id,
              primaryApplicantId: applicationStatusData.primary_applicant_id,
              isSuitable: applicationStatusData.is_suitable,
              canGoOnViewings: applicationStatusData.can_go_on_viewings,
              creditDecision: applicationStatusData.credit_decision,
              hasPropertyUnderOffer: applicationStatusData.has_property_under_offer,
              removeCustomerFromViewings: applicationStatusData.remove_customer_from_viewings,
              applicants: applicationStatusData.applicants.map((applicant: IApplicantAPIResponse) => {
                  return {
                      creditStatus: applicant.credit_status,
                      firstName: applicant.first_name,
                      applicantId: applicant.id,
                      affordabilityCheckStatus: applicant.income_verification_status ?? "",
                      lastName: applicant.last_name,
                      affordabilityCheckUrl: applicant.income_verification_url ?? undefined,
                  };
              }),
          }
        : null;
};

export const convertProfileResponse = (
    profileData: IUserProfileV2,
    searchesData?: { areaName: string; searchesId: string },
) => {
    return {
        applicants: profileData.applicants?.map((applicant: IApplicantProfileV2) => convertApplicantsData(applicant)),
        application: {
            waitListStatus: profileData.application?.waitlist_status as TWaitListStatus,
            signUpReason: profileData.application?.sign_up_reason,
            aipIssuedAt: profileData.application?.aip_issued_at,
            aipExpiryDate: profileData.application?.aip_expiry_date,
            maxRentAfterCredit:
                profileData.application?.max_rent_after_credit &&
                roundToTen(penceToPounds(profileData.application?.max_rent_after_credit)),
        },
        signUpReason: profileData.application?.sign_up_reason,
        mainIncome: penceToPounds(profileData.current_partner1_income),
        applicant2Income: penceToPounds(profileData.current_partner2_income),
        deposit: penceToPounds(profileData.current_deposit),
        desiredArea: searchesData ? searchesData.areaName : profileData.area,
        searchesId: searchesData ? searchesData.searchesId : undefined,
        latitude: profileData.latitude,
        longitude: profileData.longitude,
        incomeVerified: profileData.income_verified,
        phone: profileData.phone,
        expenditureInformationGathered: profileData.expenditure_information_gathered,
        maxRentAfterAffordability:
            profileData.max_rent_after_affordability &&
            roundToTen(penceToPounds(profileData.max_rent_after_affordability)),
        maxHomePrice: profileData.max_home_price && formatHomePrice(profileData.max_home_price),
        currentDependantsPreschool: profileData.current_dependants_preschool,
        currentDependantsPrimary: profileData.current_dependants_primary,
        currentDependantsSecondary: profileData.current_dependants_secondary,
        currentDependantsSixteenPlus: profileData.current_dependants_sixteen_plus,
        consentToProceed: profileData.application?.consent_to_proceed,
    };
};

export const convertApplicantsData = (applicantsData: IApplicantProfileV2) => ({
    applicantID: applicantsData.id,
    isLeadApplicant: applicantsData.is_lead_applicant,
    jobStatus: applicantsData.job_status ?? "",
    jobEmployer: applicantsData.job_employer,
    jobIndustry: applicantsData.job_industry,
    jobSector: applicantsData.job_sector,
    jobTitle: applicantsData.job_title,
    selfEmployedBusinessType: applicantsData.self_employed_business_type,
    selfEmployedExpensesSubmitted: applicantsData.has_self_employed_expenses,
    dateOfBirth: applicantsData.date_of_birth,
    email: applicantsData.email ?? "",
    phone: applicantsData.phone ?? "",
});

export const getApplicationIsSuitable: (applicationStatus: IApplicationStatus) => boolean = (applicationStatus) =>
    applicationStatus.isSuitable;

export const getApplicantByID = (application: IApplicationStatus, applicantID: string) =>
    application.applicants.find((applicant: IApplicant) => applicant.applicantId === applicantID) as IApplicant;

export const getPrimaryApplicant = (application: IApplicationStatus) =>
    application.applicants.find(
        (applicant: IApplicant) => applicant.applicantId === application.primaryApplicantId,
    ) as IApplicant;

export const getSecondaryApplicant = (application: IApplicationStatus) =>
    application.applicants.find(
        (applicant: IApplicant) => applicant.applicantId !== application.primaryApplicantId,
    ) as IApplicant;

export const getApplicantName = (applicant: IApplicant, placeholderName: string) =>
    applicant.firstName && applicant.lastName ? getFullName(applicant.firstName, applicant.lastName) : placeholderName;

const getFullName = (firstName: string, lastName: string) => firstName + " " + lastName;

export const getHasApplicationFailedCredit = (applicantStatus: IApplicantsStatus | null) =>
    applicantStatus?.applicant1.creditCheckStatus === "fail" ||
    applicantStatus?.applicant2.creditCheckStatus === "fail";

export const getHasApplicationFailedAffordability = (applicantStatus: IApplicantsStatus | null) =>
    applicantStatus?.applicant1.affordabilityCheck === "Failed" ||
    applicantStatus?.applicant2.affordabilityCheck === "Failed";

export const hasSecondApplicant: (application: IApplicationStatus) => boolean = (application) =>
    application?.applicants.length === 2;

export const getCanShowSeeHomesButton = (applicantStatus: IApplicantsStatus | null) =>
    applicantStatus?.hasSecondApplicant
        ? applicantStatus?.applicant1.affordabilityCheck === "Complete" &&
          applicantStatus?.applicant2.affordabilityCheck === "Complete"
        : applicantStatus?.applicant1.affordabilityCheck === "Complete";

export const getApplicant1CreditCheckLink: (
    application: IApplicationStatus,
    query?: { [key: string]: string },
) => string = (application, query) => {
    const { customerId } = application;
    const path = `/${routePaths.product.creditCheck.root}/${customerId}`;
    const primaryApplicantId = getPrimaryApplicant(application).applicantId;
    const secondApplicantID = getSecondaryApplicant(application)?.applicantId;

    const queryObject = {
        applicantID: primaryApplicantId,
        secondApplicantID,
        ...query,
    };

    const filteredQueryObject = omitBy(queryObject, isNil);
    const queryString = new URLSearchParams(filteredQueryObject).toString();
    const route = `${path}?${queryString}`;

    return route;
};

export const getApplicant2CreditCheckLink: (
    application: IApplicationStatus,
    query?: { [key: string]: string },
) => string = (application, query) => {
    const { customerId } = application;
    const path = `/${routePaths.product.creditCheck.root}/${customerId}`;
    const secondApplicantID = getSecondaryApplicant(application)?.applicantId;

    const queryObject = {
        applicantID: secondApplicantID,
        isApplicant2: "true",
        ...query,
    };

    const queryString = new URLSearchParams(queryObject).toString();
    const route = `${path}?${queryString}`;

    return route;
};

export const haveAllApplicantsCompletedCreditCheck = (applicationStatus: IApplicantsStatus) => {
    const hasSecondApplicant = applicationStatus?.hasSecondApplicant;
    const applicant1CreditCheckStatus = applicationStatus?.applicant1.creditCheckStatus || "";
    const applicant2CreditCheckStatus = applicationStatus?.applicant2.creditCheckStatus || "";
    const isCreditCheckCompleted = !hasSecondApplicant
        ? applicant1CreditCheckStatus === "Complete"
        : applicant1CreditCheckStatus === "Complete" && applicant2CreditCheckStatus === "Complete";

    return isCreditCheckCompleted;
};

export const getCreditNotStarted = (application: IApplicationStatus) =>
    application.applicants.every((applicant) => applicant.creditStatus === "not-started");

export const isSEExpensesFormRequired = (application: IApplicationStatus, profile: Partial<IUserProfile>) => {
    const creditNotStarted = getCreditNotStarted(application);
    const hasSoleTraderOrLimitedCompanyApplicant = profile.applicants?.some(
        (applicant) =>
            applicant.selfEmployedBusinessType === "Sole Trader" ||
            applicant.selfEmployedBusinessType === "Limited Company",
    );

    const hasSubmittedSEExpenses = profile.applicants?.some((applicant) => applicant.selfEmployedExpensesSubmitted);

    return creditNotStarted && hasSoleTraderOrLimitedCompanyApplicant && !hasSubmittedSEExpenses;
};

export const applicantCreditCheckNotStarted: (applicant: IApplicant) => boolean = (applicant) =>
    applicant.creditStatus === "not-started";

export const applicantCreditCheckSubmitted: (applicant: IApplicant) => boolean = (applicant) =>
    applicant.creditStatus === "submitted";

/**
 *
 * @function mapWaitListStatusToNumber
 * @param waitListStatus
 * @returns
 *
 * - Stage -1: Not Qualified for WaitList
 * - Stage 0: Not started
 * - Stage 1: Qualified For WaitList
 * - Stage 2: Can Do Affordability
 * - Stage 3: Priority WaitList
 * - Stage 4: Covered By First Fund
 */
export const mapWaitListStatusToNumber = (waitListStatus?: TWaitListStatus) => {
    switch (waitListStatus) {
        case "first_fund":
            return 4;
        case "priority_waitlist":
            return 3;
        case "can_do_affordability":
            return 2;
        case "qualified":
            return 1;
        case "not_qualified":
            return -1;
        default:
            return 0;
    }
};

export const resolveWaitListStatusText = (value?: TWaitListStatus) => {
    switch (value) {
        case "can_do_affordability":
            return "Complete";
        case "qualified":
            return "Complete";
        case "not_qualified":
            return "";
        default:
            return "";
    }
};

export const hasAIPExpired = (aipExpiryDate?: string | null) => {
    if (!aipExpiryDate) {
        return false;
    }

    const todaysDate = new Date(Date.now()).toISOString().split("T")[0];
    return new Date(todaysDate).toISOString() > new Date(aipExpiryDate).toISOString();
};
