import { ShortParagraphCss } from "@wayhome-uk/design-system";
import styled from "styled-components/macro";

export const TableWrapper = styled.div`
    margin-left: -${(props) => props.theme.spacing32};

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        margin-left: -${(props) => props.theme.spacing40};
    }

    @media (min-width: ${(props) => props.theme.largeMinWidth}) {
        margin-left: 0;
    }
`;

export const Table = styled.table`
    margin-bottom: ${(props) => props.theme.spacing24};
    border-collapse: separate;

    @media (max-width: ${(props) => props.theme.smallMaxWidth}) {
        min-width: 320px;
        max-width: 520px;
    }

    td,
    th {
        border: 1px solid ${(props) => props.theme.neutral300};
        padding: ${(props) => props.theme.spacing8};
        text-align: left;
        border-bottom: none;
        :not(:last-child) {
            border-right: none;
        }

        @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
            padding: ${(props) => props.theme.spacing8} ${(props) => props.theme.spacing16};
        }
    }

    th {
        font-weight: 600;
    }

    tr:last-child {
        td {
            border-bottom: 1px solid ${(props) => props.theme.neutral300};
        }
    }

    span {
        ${ShortParagraphCss};
    }
`;

export const EqualColumnsTable = styled(Table)<{ cols?: number }>`
    ${(props) =>
        props.cols === 2 &&
        `
            th {
                width: 50%;
            }
    `}

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        ${(props) =>
            props.cols === 2 &&
            `
            max-width: 624px;
        `}
    }

    @media (max-width: ${(props) => props.theme.smallMaxWidth}) {
        min-width: 320px;
    }

    ${(props) =>
        props.cols === 3 &&
        `
            th {
                width: 33%;
            }
    `}
`;

export const TableContainer = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;

    @media (min-width: ${(props) => props.theme.largeMinWidth}) {
        overflow-x: auto;
    }
`;

export const TableLabel = styled.span`
    ${ShortParagraphCss};
    display: inline-block;
    position: relative;
    top: 1px;
    background: ${(props) => props.theme.neutral500};
    color: ${(props) => props.theme.neutral0};
    border-top-left-radius: ${(props) => props.theme.radius1};
    border-top-right-radius: ${(props) => props.theme.radius1};
    padding: ${(props) => props.theme.spacing8};
    display: none;

    @media (max-width: 320px) {
        display: inline-block;
    }
`;
