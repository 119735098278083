import React, { ComponentType } from "react";

import { loadIntercomWidget, w as winD } from "utils/load-intercom-widget";

export const withIntercom = (WrappedComponent: ComponentType<any>) => {
    if (!winD.Intercom) {
        loadIntercomWidget();
    }

    return (props: any) => {
        return <WrappedComponent {...props} />;
    };
};
