import { ShortParagraphCss } from "@wayhome-uk/design-system";
import styled, { DefaultTheme, createGlobalStyle } from "styled-components";

export const GlobalStyleComp = createGlobalStyle`
  body {
    min-width: 320px;
  }
`;

export const IntercomInlineLink = styled.button<{ color?: keyof DefaultTheme }>`
    ${ShortParagraphCss}
    background: transparent;
    border: none;
    padding: 0;
    color: ${({ theme, color }) => (color ? theme[color] : theme.primary500)};
    cursor: pointer;
    font-weight: bold;
    font-size: inherit;
`;
