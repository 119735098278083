/* eslint-disable @typescript-eslint/no-explicit-any */
import { customWindow as win } from "interfaces";

export const identify = (uuid: string) => {
    try {
        exports.identifyHeap(uuid);
    } catch (error) {}

    try {
        exports.identifyIntercom(uuid);
    } catch (error) {}

    try {
        exports.identifyGa(uuid);
    } catch (error) {}
};

export const sendEvent = (eventName: string, eventProps: string | object) => {
    try {
        exports.sendEventToHeap(eventName, eventProps);
    } catch (error) {}
};

export const addUserProperties = (userProps: { [key: string]: any }) => {
    try {
        exports.addUserPropsToHeap(userProps);
    } catch (error) {}
};

export const recordSplitTestVersion = (testName: string, pathname: string) => {
    try {
        const hotjarTestName = generateHotjarTestName(testName, pathname);
        exports.addEventPropsToHeap({ branch: testName });
        exports.triggerHotjar(hotjarTestName);
    } catch (error) {}
};

const throwIfGAScriptUnavailable = () => {
    if (win.gtag === undefined || win.ga === undefined) {
        throw new Error("GA script is not available");
    }
};

export const identifyGa = (uuid: string) => {
    throwIfGAScriptUnavailable();

    if (uuid === "") {
        throw new Error("uuid cannot be empty");
    }

    (window as any).gtag("set", { user_id: uuid });
    (window as any).ga("set", "userId", uuid);
};

export const sendEventToGA = (eventName: string, eventValue: string) => {
    throwIfGAScriptUnavailable();

    if (eventName === "") {
        throw new Error("eventName cannot be empty");
    }

    if (eventValue === "") {
        throw new Error("eventValue cannot be empty");
    }

    (window as any).ga("send", "event", eventName, eventValue);

    (window as any).gtag("event", eventName, { eventValue });
};

const throwIfHeapScriptUnavailable = () => {
    if ((window as any).heap === undefined) {
        throw new Error("heap script is not available");
    }
};

export const identifyHeap = (uuid: string) => {
    throwIfHeapScriptUnavailable();

    if ((window as any).heap.identify === undefined) {
        throw new Error("heap's identify function is undefined");
    }

    if (uuid === "") {
        throw new Error("uuid cannot be empty");
    }

    (window as any).heap.identify(uuid);
};

export const sendEventToHeap = (eventName: string, eventProps: Record<string, any> | string) => {
    throwIfHeapScriptUnavailable();

    if ((window as any).heap.track === undefined) {
        throw new Error("heap's track function is undefined");
    }

    if (eventName === "") {
        throw new Error("eventName cannot be empty");
    }

    if (Object.keys(eventProps).length === 0) {
        throw new Error("eventProps cannot be empty");
    }

    (window as any).heap.track(eventName, eventProps);
};

export const addUserPropsToHeap = (userProps: object) => {
    throwIfHeapScriptUnavailable();

    if ((window as any).heap.addUserProperties === undefined) {
        throw new Error("heap's addUserProperties function is undefined");
    }

    if (Object.keys(userProps).length === 0) {
        throw new Error("userProps cannot be empty");
    }

    (window as any).heap.addUserProperties(userProps);
};

export const addEventPropsToHeap = (eventProps: object) => {
    throwIfHeapScriptUnavailable();

    if ((window as any).heap.addEventProperties === undefined) {
        throw new Error("heap's addEventProperties function is undefined");
    }

    if (Object.keys(eventProps).length === 0) {
        throw new Error("eventProps cannot be empty");
    }

    (window as any).heap.addEventProperties(eventProps);
};

export const identifyIntercom = (uuid: string) => {
    if (win.Intercom === undefined) {
        throw new Error("Intercom script is not available");
    }

    if (uuid === "") {
        throw new Error("uuid cannot be empty");
    }

    win.Intercom("update", { user_id: uuid });
};

export const bootIntercomForAuthenticatedUsers = (uuid: string, intercomHash: string) => {
    if (win.Intercom === undefined) {
        throw new Error("Intercom script is not available");
    }

    win.Intercom("boot", {
        api_base: process.env.REACT_APP_INTERCOM_APP_BASE_URL as string,
        app_id: process.env.REACT_APP_INTERCOM_ID as string,
        user_id: uuid,
        user_hash: intercomHash,
    });
};

export const bootIntercomForUnAuthenticatedUsers = () => {
    if (win.Intercom === undefined) {
        throw new Error("Intercom script is not available");
    }

    win.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_ID as string,
    });
};

export const shutdownIntercom = () => {
    if (win.Intercom === undefined) {
        throw new Error("Intercom script is not available");
    }

    win.Intercom("shutdown");
};

export const sendEventToFB = (eventName: string, isCustomEvent = false) => {
    if ((window as any).fbq === undefined) {
        throw new Error("fbq script is not available");
    }

    if (eventName === "") {
        throw new Error("eventName cannot be empty");
    }
    if (isCustomEvent) {
        (window as any).fbq("trackCustom", eventName, { currency: "GBP", value: 0 });
    } else {
        (window as any).fbq("track", eventName);
    }
};

const throwIfHotjarReqsNotMet = (arg?: string) => {
    if ((window as any).hj === undefined) {
        throw new Error("Hotjar script is not available");
    }

    if (arg && arg.search(/[^a-zA-Z\d-_]/) > -1) {
        throw new Error("only test names with alphanumeric characters, dashes and underscores are allowed");
    }
};

export const generateHotjarTestName = (testName: string, pagePath: string): string => {
    const page = pagePath === "/" ? "-home" : pagePath.replace(/\//g, "-");
    return `${testName}${page}`;
};

export const triggerHotjar = (testName: string) => {
    throwIfHotjarReqsNotMet(testName);

    (window as any).hj("trigger", testName);
};

export const sendEventToHotjar = (event: string) => {
    throwIfHotjarReqsNotMet(event);

    (window as any).hj("event", event);
};

export const addUserAttributesToHotjar = (uuid: string, attributes: { [key: string]: string | boolean | number }) => {
    throwIfHotjarReqsNotMet();

    (window as any).hj("identify", uuid, attributes);
};

const exports = {
    identify,
    identifyHeap,
    identifyIntercom,
    identifyGa,
    sendEvent,
    sendEventToFB,
    sendEventToHeap,
    sendEventToGA,
    addUserPropsToHeap,
    addEventPropsToHeap,
    addUserProperties,
    recordSplitTestVersion,
    triggerHotjar,
    sendEventToHotjar,
    addUserAttributesToHotjar,
    bootIntercomForAuthenticatedUsers,
    bootIntercomForUnAuthenticatedUsers,
    shutdownIntercom,
};

export default exports;
