import React from "react";

import styled from "styled-components/macro";

import { NewHeader2, NewLongParagraph } from "config/theme";
import { useCookieFirst } from "hooks/use-cookie-first";
import { getDoNotTrackValue, getVimeoIframeSrc } from "utils/video";

export const Shariah = () => {
    const context = useCookieFirst();

    return (
        <Wrapper>
            <Section>
                <Title>Shariah compliant way to buy a home</Title>
                <BoldParagraph>
                    The way Gradual Homeownership works aligns with the Islamic structures of Diminishing Musharakah and
                    Ijarah. We share the risk, reward, and responsibility of jointly owning an asset, in this case, a
                    property.
                </BoldParagraph>
                <BoldParagraph>Check out what the Islamic Finance Guru has to say about us!</BoldParagraph>
            </Section>
            <StyledIframe
                src={getVimeoIframeSrc({
                    path: "https://player.vimeo.com/video/583720570",
                    queryObject: {
                        texttrack: "en-GB.captions",
                        title: "false",
                        portrait: "false",
                        byline: "false",
                        dnt: getDoNotTrackValue(context.consent),
                    },
                })}
                title="homeownership-review-video"
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        display: flex;
        align-items: center;
    }
`;

const Section = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing16};
    max-width: 700px;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-right: ${({ theme }) => theme.spacing24};
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    font-weight: 500;
    color: ${({ theme }) => theme.neutral0};
    margin-bottom: ${({ theme }) => theme.spacing24};
    line-height: 1.4;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing8};
    }
`;

const BoldParagraph = styled.p`
    ${NewLongParagraph};
    font-weight: 700;
    color: ${({ theme }) => theme.neutral0};
    margin-bottom: ${({ theme }) => theme.spacing8};
`;

const StyledIframe = styled.iframe`
    width: 100%;
    height: 55vw;
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        height: 17rem;
        margin-bottom: 0;
    }
`;
