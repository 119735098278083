import React from "react";

import { NavbarRouterSimple } from "@wayhome-uk/design-system";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID || "", process.env.REACT_APP_ALGOLIA_KEY || "");

interface NavBarDetailsProps {
    url: string;
    title: string;
}

export interface IWithNavbarAndInstantSearchLayoutProps {
    className?: string;
    children: React.ReactNode;
    dataTestId: string;
    navBarDetails?: { logo: NavBarDetailsProps; menu: NavBarDetailsProps[] };
    withInstantSearch?: boolean;
}

export const WithNavbarAndInstantSearchLayout = ({
    className,
    children,
    dataTestId,
    navBarDetails,
    withInstantSearch,
}: IWithNavbarAndInstantSearchLayoutProps) => (
    <div className={className} data-testid={dataTestId}>
        {navBarDetails && <NavbarRouterSimple logo={navBarDetails.logo} menu={navBarDetails.menu} />}

        {withInstantSearch ? (
            <InstantSearch indexName="location_search_202203" searchClient={searchClient}>
                {children}
            </InstantSearch>
        ) : (
            <div> {children}</div>
        )}
    </div>
);
