import { useEffect } from "react";

export const useLoadScript = () => {
    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            return;
        }

        const cfScript = document.createElement("script");
        cfScript.src = `https://consent-eu.cookiefirst.com/sites/wayhome.co.uk-${
            process.env.REACT_APP_COOKIES_FIRST_API_KEY || ""
        }/consent.js`;
        cfScript.async = true;

        const firstScript = document.getElementsByTagName("script")[0];
        if (firstScript && firstScript.parentNode) {
            firstScript.parentNode.insertBefore(cfScript, firstScript);
        }

        const removalScript = document.createElement("script");
        removalScript.innerHTML = `
        (function () {
            window.cf_delete_cookie =
                window.cf_delete_cookie ||
                function (cookie) {
                    var name = cookie.unmasked_name || cookie.name;
                    var path = cookie.path || "/";
                    var domain = cookie.host_key || "";
                    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=" + path + "; domain=" + domain;

                    if (window.localStorage.getItem(name)) {
                        window.localStorage.removeItem(name);
                    }
                    if (window.sessionStorage.getItem(name)) {
                        window.sessionStorage.removeItem(name);
                    }
                };

            function remove_cookies(consent = {}) {
                if (typeof consent !== "object") {
                    throw new Error("Unknown error");
                }

                window.CookieFirst.fetchLatestScan()
                    .then(function (result) {
                        if (!result || !Array.isArray(result.cookies)) {
                            return;
                        }

                        result.cookies.forEach(function (cookie) {
                            if (consent[cookie.cat] || cookie.http_only) {
                                return;
                            }

                            window.cf_delete_cookie(cookie);
                        });
                    })
                    .catch(console.error);
            }

            function handle_consent_event(e) {
                var consent = e.detail || { necessary: true };
                if (!window.CookieFirst) {
                    window.addEventListener("cf_init", function () {
                        remove_cookies(consent);
                    });

                    return;
                }

                remove_cookies(consent);
            }

            window.addEventListener("cf_consent", handle_consent_event);
            window.addEventListener("cf_consent_loaded", handle_consent_event);
        })();
        `;

        document.head.appendChild(removalScript);

        return () => {
            if (firstScript && firstScript.parentNode) {
                firstScript.parentNode.removeChild(cfScript);
            }

            document.head.removeChild(removalScript);
        };
    }, []);
};
