import React from "react";

import { PageWidth } from "@wayhome-uk/design-system";
import styled from "styled-components/macro";

import { ITestimonialProps, TUserKind } from "../../../components/testimonial/types";
import { Generic, SelfEmployed, Shariah } from "./kind";

export const Testimonial = ({ userKind }: ITestimonialProps) => {
    const renderTestimonial = (kind: TUserKind) => {
        switch (kind) {
            case "shariah":
                return <Shariah />;
            case "self-employed":
                return <SelfEmployed />;
            default:
                return <Generic />;
        }
    };

    return (
        <Wrapper data-testid="testimonial">
            <PageWidth>
                {renderTestimonial(userKind)}
                {userKind !== "shariah" ? (
                    <StyledSecondaryLink href="/testimonials" data-testid="testimonial-cta-link">
                        Hear from our homeowners
                    </StyledSecondaryLink>
                ) : null}
            </PageWidth>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background: ${({ theme }) => theme.primary600};
    padding: ${({ theme }) => `${theme.spacing24} 0`};

    @media (min-width: ${(props) => props.theme.mediumMinWidth}) {
        padding: ${({ theme }) => `${theme.spacing48} 0`};
    }
`;

const StyledSecondaryLink = styled.a`
    display: inline-block;
    color: ${({ theme }) => theme.primary500};
    background: ${({ theme }) => theme.primary50};
    border: 1px solid ${({ theme }) => theme.primary500};
    border-radius: ${({ theme }) => theme.spacing4};
    font-weight: bold;
    text-decoration: none;
    padding: ${({ theme }) => theme.spacing12};
    text-align: left;
    width: 100%;
    max-width: 240px;
`;
