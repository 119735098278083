import React, { useState } from "react";

import { Input, ShortLeadLabelCss, ShortParagraphLabelCss } from "@wayhome-uk/design-system";
import styled, { css } from "styled-components/macro";
import { BaseComponent } from "types";

export type InputPasswordSize = "small" | "large";

interface Props extends BaseComponent {
    id?: string;
    name?: string;
    onBlur?: (event: React.FocusEvent<any>) => void;
    onChange?: (event: React.ChangeEvent<any>) => void;
    isValid?: boolean;
    size?: InputPasswordSize;
    value?: string;
}

export const PasswordInput = ({ className, id, name, onChange, onBlur, isValid, size, value }: Props) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <InputWrapper className={className} data-testid={`password-input-${id}`}>
            <StyledInput
                id={id}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                isValid={isValid}
                inputSize={size}
                type={showPassword ? "text" : "password"}
                value={value}
                data-testid="password-input"
            />
            {showPassword ? (
                <Button
                    data-testid={`password-input-hide-button-${id}`}
                    size={size}
                    type="button"
                    onClick={() => setShowPassword(false)}
                >
                    Hide
                </Button>
            ) : (
                <Button
                    data-testid={`password-input-show-button-${id}`}
                    size={size}
                    type="button"
                    onClick={() => setShowPassword(true)}
                >
                    Show
                </Button>
            )}
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    position: relative;
`;

const StyledInput = styled(Input)<{ inputSize?: InputPasswordSize }>`
    width: 100%;

    ${({ inputSize }) => {
        switch (inputSize) {
            case "small" as string | undefined:
                return css`
                    ${ShortParagraphLabelCss};
                    line-height: 1;
                    height: 42px;
                `;
            case "large" as string | undefined:
                return css`
                    ${ShortLeadLabelCss};
                `;

            default:
                return css`
                    ${ShortLeadLabelCss};
                `;
        }
    }}
`;

const Button = styled.button<{ size?: InputPasswordSize }>`
    color: ${(props) => props.theme.primary500};
    background: transparent;
    font-weight: 700;
    border: 0;
    outline: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px;

    ${({ size }) => {
        switch (size) {
            case "small" as string | undefined:
                return css`
                    ${ShortParagraphLabelCss};
                    height: 40px;
                    width: 74px;
                    padding: ${(props) => props.theme.spacing12};
                    border-left: 1px solid ${(props) => props.theme.neutral50};
                `;
            case "large" as string | undefined:
                return css`
                    width: 96px;
                    ${ShortLeadLabelCss};
                    padding: ${(props) => props.theme.spacing16};
                    border-left: 2px solid ${(props) => props.theme.neutral50};
                `;

            default:
                return css`
                    ${ShortLeadLabelCss};
                `;
        }
    }}
`;
