import React from "react";

import { Grid, GridItem, PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import IconFixerUpper from "components/media/svg/icon-fixer-upper.svg";
import IconNewBuild from "components/media/svg/icon-new-build.svg";
import IconRent from "components/media/svg/icon-rent.svg";
import { NewHeader2 } from "config/theme";
import { IconWithText, IconWithTextContent, IconWithTextTitle, Paragraph } from "pages/home/icon-with-text";

export const HomeRestrictions: React.FC<IBaseComponent> = ({ className }) => (
    <>
        <Wrapper className={className}>
            <PageWidth>
                <Title>Home Restrictions</Title>
                <Grid container spacing={8}>
                    <GridItem item md={4}>
                        <StyledIconWithText iconSrc={IconNewBuild}>
                            <IconWithTextTitle>No new-builds</IconWithTextTitle>
                            <IconWithTextContent>
                                <Paragraph>
                                    We only show you homes that have been sold before so we can work out a fair price
                                    for them.
                                </Paragraph>
                            </IconWithTextContent>
                        </StyledIconWithText>
                    </GridItem>
                    <GridItem item md={4}>
                        <StyledIconWithText iconSrc={IconFixerUpper}>
                            <IconWithTextTitle>No fixer-uppers</IconWithTextTitle>
                            <IconWithTextContent>
                                <Paragraph>
                                    We only show you homes that don’t need structural work. We don’t want you living in
                                    a building site.
                                </Paragraph>
                            </IconWithTextContent>
                        </StyledIconWithText>
                    </GridItem>
                    <GridItem item md={4}>
                        <StyledIconWithText iconSrc={IconRent}>
                            <IconWithTextTitle>No buy-to-lets</IconWithTextTitle>
                            <IconWithTextContent>
                                <Paragraph>
                                    We want to help people looking for a home, not an investment opportunity.
                                </Paragraph>
                            </IconWithTextContent>
                        </StyledIconWithText>
                    </GridItem>
                </Grid>
            </PageWidth>
        </Wrapper>
    </>
);

const Wrapper = styled.div`
    overflow-x: hidden;
    padding: ${({ theme }) => theme.spacing40} 0;
    background: ${({ theme }) => theme.primary500};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing96} 0;
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    color: ${({ theme }) => theme.neutral0};
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing40};
    }
`;

const StyledIconWithText = styled(IconWithText)`
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing48};
    }
`;
