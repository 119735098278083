import React from "react";

import { PageWidth } from "@wayhome-uk/design-system";
import { useFlags } from "launchdarkly-react-client-sdk";
import styled from "styled-components";

import Flake from "components/media/svg/flake.svg";
import Snowflake from "components/media/svg/snowflake.svg";

import { bannerUsedDirectlyInComponent, getBackgroundColorByPathName } from "./helpers";

interface ISnowflakeBannerProps {
    isRenderedAtAppLevel?: boolean;
}

export const SnowflakeBanner = ({ isRenderedAtAppLevel }: ISnowflakeBannerProps) => {
    const { showChristmasShutdown } = useFlags();
    const backgroundColor = getBackgroundColorByPathName(window.location.pathname);
    const bannerIsUsedDirectlyInComponent =
        !isRenderedAtAppLevel && bannerUsedDirectlyInComponent(window.location.pathname);

    const banner = () => (
        <Wrapper backgroundColor={backgroundColor}>
            <FlakeLine />
            <PageWidth>
                <Snowflakes />
            </PageWidth>
        </Wrapper>
    );

    if (!showChristmasShutdown) {
        return null;
    }

    //add checks to prevent banner being displayed twice, on components that use the Banner directly in that components and at App level
    if (isRenderedAtAppLevel && !bannerUsedDirectlyInComponent(window.location.pathname)) {
        return banner();
    }

    if (bannerIsUsedDirectlyInComponent) {
        return banner();
    }

    return null;
};

const Wrapper = styled.div<{ backgroundColor: string }>`
    background-color: ${({ theme, backgroundColor }) => (backgroundColor ? theme[backgroundColor] : "none")};
`;

const FlakeLine = styled.div`
    height: 4px;
    width: 100%;
    background-image: url(${Flake});
    background-repeat: space;
    margin-bottom: ${({ theme }) => theme.spacing4};
`;

const Snowflakes = styled.div`
    height: 25px;
    width: 100%;
    background-image: url(${Snowflake});
    background-repeat: space;
`;
