import React from "react";

import { PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import { SecondaryButton, SecondaryLink } from "components/buttons-and-links";
import {
    FaqSectionHeading,
    RentingDifference,
    RequiredDeposit,
    RequiredIncome,
    RequiredInfo,
    SharedOwnership,
} from "components/faqs";
import { routePaths } from "config/route-paths";

export const HomeFaqs: React.FC<IBaseComponent> = ({ className }) => (
    <Wrapper className={className}>
        <PageWidth>
            <FaqSectionHeading>Most frequently asked questions</FaqSectionHeading>
            <Faqs>
                <RequiredIncome />
                <SharedOwnership />
                <RequiredInfo />
                <RequiredDeposit />
                <RentingDifference />
            </Faqs>
            <SecondaryButton>
                <SecondaryLink to={routePaths.marketing.faqs.path} target="_blank" rel="noopener noreferrer">
                    See all FAQs
                </SecondaryLink>
            </SecondaryButton>
        </PageWidth>
    </Wrapper>
);

const Wrapper = styled.div`
    background: ${({ theme }) => theme.neutral75};
    padding: ${({ theme }) => theme.spacing40} 0;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing96} 0;
    }
`;

const Faqs = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing40};
    }
`;
