import React, { useEffect, useState } from "react";

import { Header4Css } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import IconMinus from "components/media/svg/icon-minus-grey.svg";
import IconPlus from "components/media/svg/icon-plus-black.svg";

export interface IAccordionProps extends IBaseComponent {
    title: string;
    id?: string;
    expandAll?: boolean | undefined;
}

export const AccordionWithLines: React.FC<IAccordionProps> = ({
    className,
    title,
    id,
    children,
    expandAll = undefined,
}) => {
    const { hash } = window.location;

    const [isShowing, setIsShowing] = useState<boolean>(false);

    useEffect(() => {
        const hashName = hash?.replace("#", "");

        if (hashName === id) {
            setIsShowing(true);
        }
    }, [hash, id]);

    useEffect(() => {
        if (expandAll !== undefined) setIsShowing(expandAll);
    }, [expandAll]);

    const handleToggle = () => {
        setIsShowing(!isShowing);
    };

    return (
        <Wrapper isShowing={isShowing} className={className} data-test-id="faq-item" id={id}>
            <AccordionHeader as="h3" isShowing={isShowing} onClick={handleToggle}>
                {title}
                <IconWrapper isShowing={isShowing} />
            </AccordionHeader>
            <AccordionText isShowing={isShowing}>{children}</AccordionText>
            <Line />
        </Wrapper>
    );
};

const Line = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.neutral100};
    margin: ${({ theme }) => theme.spacing16} 0;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin: ${({ theme }) => theme.spacing24} 0;
    }
`;

const AccordionHeader = styled.div<{ isShowing: boolean }>`
    ${Header4Css};
    color: ${({ theme, isShowing }) => (isShowing ? theme.neutral600 : theme.neutral800)};
    font-weight: 600;
    position: relative;
    cursor: pointer;

    &:before {
        position: absolute;
        content: " ";
        background: url(${({ isShowing }) => (isShowing ? IconMinus : IconPlus)}) center center no-repeat;
        color: ${({ theme }) => theme.neutral800};
        right: 0;
        top: 3px;
        width: ${({ theme }) => theme.spacing24};
        height: ${({ theme }) => theme.spacing24};
    }
`;

const IconWrapper = styled.span<{ isShowing: boolean }>``;

const AccordionText = styled.div<{ isShowing: boolean }>`
    display: ${({ isShowing }) => (isShowing ? "block" : "none")};
    color: ${({ theme }) => theme.neutral700};

    :last-child {
        margin-bottom: 0;
    }
`;

const Wrapper = styled.div<{ isShowing: boolean }>`
    scroll-margin-top: 84px;
`;

AccordionText.displayName = "AccordionText";
