import { Reducer } from "redux";

import { globaliseSelectors } from "utils/globalise-selectors";

import { UIActionTypes, UIState } from "./types";

const initialState: UIState = {
    isLoading: false,
    showApiError: false,
    apiErrorMessage: "",
    isLoginModalShowing: false,
    shouldRedirectOnLogin: true,
};

export const UIReducer: Reducer<UIState> = (state = initialState, action) => {
    switch (action.type) {
        case UIActionTypes.SHOW_LOADING:
            return {
                ...state,
                isLoading: true,
                showApiError: false,
            };

        case UIActionTypes.HIDE_LOADING:
            return {
                ...state,
                isLoading: false,
            };

        case UIActionTypes.SHOW_API_ERROR:
            return {
                ...state,
                isLoading: false,
                showApiError: true,
                apiErrorMessage: action.payload,
            };

        case UIActionTypes.HIDE_API_ERROR:
            return {
                ...state,
                showApiError: false,
                apiErrorMessage: "",
            };
        case UIActionTypes.OPEN_CLOSE_SIGN_MODAL:
            return {
                ...state,
                isLoginModalShowing: action?.payload ? action.payload.isLoginModalShowing : !state.isLoginModalShowing,
                shouldRedirectOnLogin: action?.payload
                    ? action.payload.shouldRedirectOnLogin
                    : state.shouldRedirectOnLogin,
            };

        default:
            return state;
    }
};

export const getUILoadingState = (state: UIState) => state.isLoading;

export const getApiErrorStatus = (state: UIState) => state.showApiError;

export const getApiErrorMessage = (state: UIState) => state.apiErrorMessage;

export const UISelectors = globaliseSelectors(
    {
        getUILoadingState,
        getApiErrorStatus,
        getApiErrorMessage,
    },
    "ui",
);
