import { RouterState, connectRouter, routerMiddleware } from "connected-react-router";
import { History, createBrowserHistory } from "history";
import { Store, applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";

import { interactionsSagas } from "./interactions";
import { ListingsState, listingsReducer, listingsSagas } from "./listings";
import { UIReducer, UISagas, UIState } from "./ui";
import { UserState, userReducer, userSagas } from "./user";

interface InitialState {
    user: UserState;
    listings: ListingsState;
    ui: UIState;
}
export type AppState = InitialState & { router: RouterState };

const rootReducer = (history: History) =>
    combineReducers<AppState>({
        router: connectRouter(history),
        user: userReducer,
        listings: listingsReducer,
        ui: UIReducer,
    });

function* rootSaga() {
    yield all([fork(listingsSagas), fork(UISagas), fork(interactionsSagas), fork(userSagas)]);
}

export const history = createBrowserHistory();

export const getConfiguredStore = (initialState?: InitialState, initialHistory = history): Store<AppState> => {
    const composeEnhancers = composeWithDevTools({});
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        rootReducer(initialHistory),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(initialHistory), sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);

    return store;
};
