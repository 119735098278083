import React from "react";

import { Accordion } from "components/faqs";

import { Paragraph } from "../../faq-styles";

export const RentingDifference = () => (
    <Accordion title="How is Wayhome different from renting?" id="rent-different">
        <Paragraph>
            It’s different because, although you don’t immediately buy 100% of the home, you can treat it as your own.
            Paint the walls, nail up pictures and get a pet.
        </Paragraph>
        <Paragraph>It’s similar to renting because you pay rent on the part you don’t yet own.</Paragraph>
    </Accordion>
);
