import React from "react";

import { Grid, GridItem, PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import IconHouse from "components/media/svg/icon-house.svg";
import IconKeys from "components/media/svg/icon-keys.svg";
import IconScale from "components/media/svg/icon-scale.svg";
import { NewHeader2 } from "config/theme";
import { IconWithText, IconWithTextContent, IconWithTextTitle, Paragraph } from "pages/home/icon-with-text";

export const KeyBenefits: React.FC<IBaseComponent> = ({ className }) => (
    <>
        <Wrapper className={className}>
            <PageWidth>
                <Title>Key benefits</Title>
                <Grid container spacing={8}>
                    <GridItem item md={4}>
                        <StyledIconWithText iconSrc={IconHouse}>
                            <IconWithTextTitle>Make it your own</IconWithTextTitle>
                            <IconWithTextContent>
                                <Paragraph>
                                    You have the freedom to paint the walls, change the carpet or get a pet. After all,
                                    it’s your home.
                                </Paragraph>
                            </IconWithTextContent>
                        </StyledIconWithText>
                    </GridItem>
                    <GridItem item md={4}>
                        <StyledIconWithText iconSrc={IconKeys}>
                            <IconWithTextTitle>Stay as long as you like</IconWithTextTitle>
                            <IconWithTextContent>
                                <Paragraph>
                                    It’s up to you if, or when, you leave. Nobody can ask you to leave as long as you
                                    pay rent.
                                </Paragraph>
                            </IconWithTextContent>
                        </StyledIconWithText>
                    </GridItem>
                    <GridItem item md={4}>
                        <StyledIconWithText iconSrc={IconScale}>
                            <IconWithTextTitle>Fair buying costs</IconWithTextTitle>
                            <IconWithTextContent>
                                <Paragraph>
                                    Normal costs like stamp duty are shared fairly. If you’re starting with the minimum
                                    5% deposit, you only pay 5% of costs like stamp duty.
                                </Paragraph>
                            </IconWithTextContent>
                        </StyledIconWithText>
                    </GridItem>
                </Grid>
            </PageWidth>
        </Wrapper>
    </>
);

const Wrapper = styled.div`
    overflow-x: hidden;
    padding: ${({ theme }) => theme.spacing40} 0;
    background: ${({ theme }) => theme.primary800};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        padding: ${({ theme }) => theme.spacing96} 0;
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    color: ${({ theme }) => theme.neutral0};
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing40};
    }
`;

const StyledIconWithText = styled(IconWithText)`
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing48};
    }
`;
