import React from "react";

import styled from "styled-components/macro";

import { NewLongParagraph } from "config/theme";

import { PayneFamily } from "./payne-family";

export const SelfEmployed = () => (
    <PayneFamily>
        <BoldParagraph>
            Being self-employed Robert and Aimee thought they might never get on the property ladder...until they found
            Wayhome
        </BoldParagraph>
        <LastParagraph>
            “For anyone who has good credit, reasonable household income but not a big enough deposit, this is the
            perfect route to getting on the ladder. It was important for us to own our home and have that sense of
            security, and we treat our staircasing payments just as we would a mortgage.”
        </LastParagraph>
    </PayneFamily>
);

const Paragraph = styled.p`
    ${NewLongParagraph};
    font-weight: 400;
    color: ${({ theme }) => theme.neutral0};
    margin-bottom: ${({ theme }) => theme.spacing8};
`;

const BoldParagraph = styled(Paragraph)`
    font-weight: 700;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing24};
    }
`;

const LastParagraph = styled(Paragraph)`
    margin-bottom: ${({ theme }) => theme.spacing24};
`;
