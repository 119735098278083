import React, { useState } from "react";

import { PageWidth } from "@wayhome-uk/design-system";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { ArrowBottom } from "components/icons/icon-arrow-bottom";
import { isSignUpViewAndUrl } from "utils/helpers";

import { HeaderLink } from "../types";

interface IMenuItemProps {
    link: HeaderLink;
    onClick?: () => void;
}

export const DefaultMenuItem = ({ link, onClick }: IMenuItemProps) => (
    <MenuItem>
        <MenuLink
            to={link.url}
            title={link.title}
            data-test-id={link.testId}
            exact={true}
            onClick={() => {
                if (onClick) {
                    onClick();
                }

                if (link.handleClick) {
                    link.handleClick();
                }
            }}
        >
            <PageWidth>
                <LinkWrapper>{link.title}</LinkWrapper>
            </PageWidth>
        </MenuLink>
    </MenuItem>
);

export const MenuItemWithSubMenu = ({ link, onClick }: IMenuItemProps) => {
    const [isShowing, handleToggle] = useState(false);
    return (
        <>
            <MenuItem>
                <PageWidth>
                    <ToggleButton onClick={() => handleToggle(!isShowing)} isShowing={isShowing}>
                        <LinkWrapper>{link.title}</LinkWrapper>
                        <ArrowBottom />
                    </ToggleButton>
                </PageWidth>
            </MenuItem>
            {isShowing && (
                <ul>
                    {link.subMenu &&
                        link.subMenu.map((subLink: HeaderLink) => (
                            <MenuItem key={`menu-item-${link.id}-sub-menu-item-${subLink.id}`} onClick={onClick}>
                                {subLink.isExternal ? (
                                    <SubMenuItemExternalLink
                                        href={subLink.url}
                                        title={subLink.title}
                                        data-test-id={subLink.testId}
                                        target={subLink.target}
                                        rel={subLink.target ? "noopener noreferrer" : ""}
                                    >
                                        <PageWidth>{subLink.title}</PageWidth>
                                    </SubMenuItemExternalLink>
                                ) : (
                                    <SubMenuLink
                                        exact
                                        to={subLink.url}
                                        title={subLink.title}
                                        data-test-id={subLink.testId}
                                        onClick={() => {
                                            handleToggle(false);
                                            if (subLink.handleClick) {
                                                subLink.handleClick();
                                            }
                                        }}
                                    >
                                        <PageWidth>{subLink.title}</PageWidth>
                                    </SubMenuLink>
                                )}
                            </MenuItem>
                        ))}
                </ul>
            )}
        </>
    );
};

export const ExternalMenuItem = ({ link, onClick }: IMenuItemProps) => (
    <MenuItem>
        <ExternalLink href={link.url} title={link.title} data-test-id={link.testId} onClick={onClick}>
            <PageWidth>
                <LinkWrapper>{link.title}</LinkWrapper>
            </PageWidth>
        </ExternalLink>
    </MenuItem>
);

export const MenuItemWithButton = ({ link, onClick }: IMenuItemProps) =>
    isSignUpViewAndUrl(link.url) ? null : (
        <MenuItem>
            <PageWidth>
                <StyledNavLink
                    exact
                    to={link.url}
                    title={link.title}
                    data-test-id="navbar-login-login-button"
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                        if (link.handleClick) {
                            link.handleClick();
                        }
                    }}
                >
                    {link.title}
                </StyledNavLink>
            </PageWidth>
        </MenuItem>
    );

const ParagraphTextCss = css`
    font-size: ${(props) => props.theme.type16};
    line-height: 1.6;
`;

const MenuLinkCss = css`
    ${ParagraphTextCss};
    max-width: 100%;
    display: block;
    padding: ${(props) => props.theme.spacing16} 0;
    font-weight: 600;
    color: ${(props) => props.theme.primary500};

    :focus,
    :hover {
        color: ${(props) => props.theme.primary800};
        text-decoration: underline;
    }
`;

const StyledNavLink = styled(NavLink)`
    display: block;
    width: fit-content;
    text-align: center;
    line-height: 1.6;
    font-weight: 700;
    padding: ${({ theme }) => `${theme.spacing8} ${theme.spacing16}`};
    margin: ${({ theme }) => `${theme.spacing8} 0`};
    background: ${({ theme }) => theme.primary500};
    color: ${({ theme }) => theme.neutral0};
    border-radius: ${({ theme }) => theme.spacing4};
`;

const MenuLink = styled(NavLink)`
    ${MenuLinkCss};
`;

const ExternalLink = styled.a`
    ${MenuLinkCss};
`;

const SubMenuItemExternalLink = styled.a`
    ${MenuLinkCss};
    padding-left: ${(props) => props.theme.spacing8};
    background-color: #f5f4f0;
`;

const SubMenuLink = styled(MenuLink)`
    padding-left: ${(props) => props.theme.spacing8};
    background-color: #f5f4f0;
`;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        transform: rotate(270deg);
        fill: ${(props) => props.theme.primary500};

        .active-link & {
            fill: ${(props) => props.theme.neutral500};
        }
    }
`;

const MenuItem = styled.li`
    border-top: 1px solid ${(props) => props.theme.neutral75};
`;

const ToggleButton = styled.span<{ isShowing?: boolean }>`
    ${MenuLinkCss};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: none;
    cursor: pointer;

    svg {
        fill: ${(props) => props.theme.primary500};
        display: inline-block;
        width: 10px;
        height: 6px;
        margin-left: ${(props) => props.theme.spacing8};
        transform: ${(props) => (props.isShowing ? "rotate(180deg)" : "rotate(0deg)")};
    }
`;
