import React from "react";

import { ShortLead } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components/macro";

import { NewLongParagraph } from "config/theme";

export interface IProps extends IBaseComponent {
    iconSrc: string;
    iconAltText?: string;
    children: React.ReactNode;
}

export const IconWithText: React.FC<IProps> = ({ className, iconSrc, iconAltText, children }) => (
    <div className={className}>
        <Icon>
            <img src={iconSrc} alt={iconAltText} />
        </Icon>
        {children}
    </div>
);

export const Paragraph = styled.p`
    ${NewLongParagraph};
    color: ${({ theme }) => theme.neutral0};
`;

const Icon = styled.span`
    display: inline-block;
    max-width: 88px;
    max-height: 88px;
    margin-bottom: ${({ theme }) => theme.spacing8};

    img {
        max-width: 100%;
        max-height: 100%;
    }

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing24};
    }
`;

export const IconWithTextTitle = styled(ShortLead)`
    color: ${({ theme }) => theme.neutral0};
    margin-bottom: ${({ theme }) => theme.spacing8};
    font-weight: 500;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing16};
    }
`;

export const IconWithTextContent = styled.div`
    ${Paragraph} {
        color: ${({ theme }) => theme.neutral0};
        margin-bottom: ${({ theme }) => theme.spacing16};

        :last-child {
            margin-bottom: 0;
        }
    }
`;
