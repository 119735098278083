import React from "react";
import { createContext, useContext } from "react";

import { TCookieFirstContext } from "./type";
import { initialContext, useConsent } from "./use-consent";
import { useLoadScript } from "./use-load-script";

interface ICookieFirstProviderProps {
    children: JSX.Element;
}

const CookieFirstContext = createContext<TCookieFirstContext>(initialContext());

export const CookieFirstProvider = ({ children }: ICookieFirstProviderProps) => {
    useLoadScript();
    const context = useConsent();

    return <CookieFirstContext.Provider value={context}>{children}</CookieFirstContext.Provider>;
};

export const useCookieFirst = () => useContext(CookieFirstContext);
