export { routes as routePaths } from "./routes";

export const routeWithoutParams = (route: string): string => {
    return route.replace(/:[a-zA-Z0-9]+\/?/g, "");
};

export const getRoutePathWithParamsAndQueryStrings = (path: string, params: { [key: string]: string }) => {
    return Object.keys(params).reduce((pathWithParams, paramKey) => {
        return pathWithParams.replace(`:${paramKey}`, params[paramKey]).replace(/\(.*?\)/, "");
    }, path);
};
