import React, { ComponentType, useCallback, useEffect } from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch, compose } from "redux";
import { AppState } from "store";
import { HOCWithInjectedProps } from "types";

import { useAuth } from "hooks/use-auth";
import { SendInteractionArgs, sendInteraction } from "store/interactions";
import { CurrentAreaInfo, CustomerListing, listingsSelectors } from "store/listings";
import { updateUserAction, userSelectors } from "store/user";
import analytics from "utils/analytics";
import { filterQueryStringByPrefix } from "utils/helpers";

interface WCInjectedProps {
    sendViewedListingEvent: (listingID: string, applicationId?: string, primaryApplicantId?: string) => void;
    sendLikedListingEvent: (listingID: string, applicationId?: string, primaryApplicantId?: string) => void;
    sendDislikedListingEvent: (listingID: string, applicationId?: string, primaryApplicantId?: string) => void;
    sendLoadedMatchesEvent: () => void;
    sendPageLoadedEvent: () => void;
    sendBookCallEvent: (listingID?: string) => void;
    sendSubmitSuitabilityFormEvent: (data: { [key: string]: number | string }) => void;
    sendInteraction: () => void;
}

interface OtherInjectedProps {
    dispatchSendInteractionAction: typeof sendInteraction;
    dispatchUpdateUser: (user: { isIdentified?: boolean; isInternal?: boolean; utmParams?: string | null }) => void;
    isUserIdentified: boolean;
    isInternalUser: boolean;
    utmParams: string | null;
    areaInfo: CurrentAreaInfo;
    totalResults: number;
    listings: CustomerListing[];
}

interface RouteParams {
    uuid: string;
}

type Props = WCInjectedProps & OtherInjectedProps & RouteComponentProps<RouteParams>;

export const withInteractionEvents =
    <WCProps extends WCInjectedProps>(WC: ComponentType<WCProps>) =>
    (props: Props) => {
        const { user } = useAuth();
        const uuidFromAuth = user?.uuid;
        const primaryApplicantId = user?.application?.primaryApplicantId;
        const applicationId = user?.application?.applicationId;

        const {
            isUserIdentified,
            isInternalUser,
            dispatchUpdateUser,
            dispatchSendInteractionAction,
            areaInfo,
            listings,
            totalResults,
            utmParams,
            location: { search, pathname },
            ...rest
        } = props;
        const { uuid: uuidFromURL } = props.match.params;
        const splitTestName = process.env.REACT_APP_DEPLOYED_BRANCH || "";

        const shouldTrack = useCallback(() => {
            return !isInternalUser && !search.includes("noTrack");
        }, [isInternalUser, search]);

        useEffect(() => {
            if (!shouldTrack()) {
                dispatchUpdateUser({ isInternal: isInternalUser });
            }
        }, [shouldTrack, isInternalUser, dispatchUpdateUser]);

        useEffect(() => {
            if (!isUserIdentified && shouldTrack()) {
                if (uuidFromAuth) {
                    analytics.identify(uuidFromAuth);
                    dispatchUpdateUser({ isIdentified: true });
                } else if (uuidFromURL) {
                    analytics.identify(uuidFromURL);
                    dispatchUpdateUser({ isIdentified: true });
                }
            }
        }, [shouldTrack, isUserIdentified, uuidFromAuth, uuidFromURL, dispatchUpdateUser]);

        useEffect(() => {
            if (!isUserIdentified) {
                analytics.recordSplitTestVersion(splitTestName, pathname);
            }
        }, [isUserIdentified, splitTestName, pathname]);

        const handleSendLoadedMatchesEvent = () => {
            if (shouldTrack()) {
                dispatchSendInteractionAction({
                    uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                    action: "list",
                    listings: listings.map((listing: CustomerListing) => listing.ID),
                    application: applicationId,
                    applicant: primaryApplicantId,
                });

                dispatchSendInteractionAction({
                    uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                    action: "total_results",
                    message: { currentArea: areaInfo.name, totalResults },
                    application: applicationId,
                    applicant: primaryApplicantId,
                });

                analytics.sendEvent("PropertyList-Shown", {
                    customerID: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                    numOfProperties: totalResults,
                });
                analytics.addUserProperties({ hasSeenProperties: totalResults > 0 ? "yes" : "no" });

                try {
                    analytics.sendEventToGA("conversion", "registration");
                } catch (error) {}
            }
        };

        const handlePageLoadedEvent = () => {
            const filteredString = filterQueryStringByPrefix(search, "utm_");

            if (shouldTrack() && filteredString) {
                if (uuidFromURL || uuidFromAuth) {
                    dispatchSendInteractionAction({
                        uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                        action: "utm",
                        message: filteredString,
                        application: applicationId,
                        applicant: primaryApplicantId,
                    });
                } else {
                    dispatchUpdateUser({ utmParams: filteredString });
                }
            }
        };

        const handleSendLikedListingEvent = (
            listingID: string,
            applicationId?: string,
            primaryApplicantId?: string,
        ) => {
            if (shouldTrack()) {
                dispatchSendInteractionAction({
                    uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                    action: "liked",
                    listings: [listingID],
                    application: applicationId,
                    applicant: primaryApplicantId,
                });

                try {
                    analytics.sendEventToFB("CustomizeProduct");
                } catch (error) {}

                try {
                    analytics.sendEventToGA("engagement", "like");
                } catch (error) {}
            }
        };

        const handleSendDislikedListingEvent = (
            listingID: string,
            applicationId?: string,
            primaryApplicantId?: string,
        ) => {
            if (shouldTrack()) {
                dispatchSendInteractionAction({
                    uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                    action: "disliked",
                    listings: [listingID],
                    application: applicationId,
                    applicant: primaryApplicantId,
                });
            }
        };

        const handleSendViewedListingEvent = (listingID: string, applicationId: string, primaryApplicantId: string) => {
            if (shouldTrack()) {
                dispatchSendInteractionAction({
                    uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                    action: "detail",
                    listings: [listingID],
                    application: applicationId,
                    applicant: primaryApplicantId,
                });

                analytics.addUserProperties({
                    viewedProperty: "true",
                });

                try {
                    analytics.sendEventToGA("engagement", "zoopla");
                } catch (error) {}
            }
        };

        const handleSendBookCallEvent = (listingID?: string) => {
            if (shouldTrack()) {
                dispatchSendInteractionAction({
                    uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                    action: "call_requested",
                    listings: listingID ? [listingID] : undefined,
                });

                try {
                    analytics.sendEventToFB("Contact");
                } catch (error) {}
            }
        };

        const handleSendSubmitSuitabilityFormEvent = (data: { [key: string]: number | string }) => {
            if (shouldTrack()) {
                try {
                    analytics.sendEventToFB("CompleteRegistration");
                    analytics.sendEventToGA("registration_complete", "true");
                } catch (error) {}

                analytics.addUserProperties(data);

                if (utmParams) {
                    dispatchSendInteractionAction({
                        uuid: uuidFromAuth ? uuidFromAuth : uuidFromURL,
                        action: "utm_register",
                        message: utmParams,
                        application: applicationId,
                        applicant: primaryApplicantId,
                    });
                    dispatchUpdateUser({ utmParams: null });
                }

                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).dataLayer.push({ event: "registrationComplete" });
            }
        };

        const handleSuitableUserSignUpEvent = () => {
            if (shouldTrack()) {
                try {
                    analytics.sendEventToFB("Suitable Registration", true);
                    analytics.sendEventToGA("registration_suitable", "true");
                } catch (error) {}
            }
        };

        const handleCreditDoneEvent = () => {
            if (shouldTrack()) {
                try {
                    analytics.sendEventToFB("Credit Complete", true);
                    analytics.sendEventToGA("credit_complete", "true");
                } catch (error) {}
            }
        };

        const handleRegistrationCompleteEvent = () => {
            if (shouldTrack()) {
                try {
                    analytics.sendEventToFB("Registration Form Completed", true);
                } catch (error) {}
            }
        };

        const handleUnsuitableRegistrationEvent = () => {
            if (shouldTrack()) {
                try {
                    analytics.sendEventToFB("Unsuitable Registration", true);
                } catch (error) {}
            }
        };

        const handleSecondStageFormComplete = () => {
            if (shouldTrack()) {
                try {
                    analytics.sendEventToFB("Second Stage Form Complete", true);
                    analytics.sendEventToGA("second_stage_from_complete", "true");
                } catch (error) {}
            }
        };

        const handleSendInteraction = useCallback(
            (args: SendInteractionArgs) => {
                if (shouldTrack()) {
                    dispatchSendInteractionAction(args);
                }
            },
            [shouldTrack, dispatchSendInteractionAction],
        );

        return (
            <WC
                sendViewedListingEvent={handleSendViewedListingEvent}
                sendLikedListingEvent={handleSendLikedListingEvent}
                sendDislikedListingEvent={handleSendDislikedListingEvent}
                sendLoadedMatchesEvent={handleSendLoadedMatchesEvent}
                sendBookCallEvent={handleSendBookCallEvent}
                sendSubmitSuitabilityFormEvent={handleSendSubmitSuitabilityFormEvent}
                sendSuitableUserSignUpEvent={handleSuitableUserSignUpEvent}
                sendInteraction={handleSendInteraction}
                sendPageLoadedEvent={handlePageLoadedEvent}
                sendCreditDoneEvent={handleCreditDoneEvent}
                sendRegistrationCompleteEvent={handleRegistrationCompleteEvent}
                sendUnsuitableRegistrationEvent={handleUnsuitableRegistrationEvent}
                sendSecondStageFormComplete={handleSecondStageFormComplete}
                location={props.location}
                match={props.match}
                areaInfo={areaInfo}
                {...(rest as any)}
            />
        );
    };

export const mapStateToProps = (state: AppState) => ({
    isUserIdentified: userSelectors.getUserIdentifiedStatus(state),
    isInternalUser: userSelectors.getIsInternalUser(state),
    utmParams: userSelectors.getUtmParams(state),
    areaInfo: listingsSelectors.getCurrentAreaInfo(state),
    listings: listingsSelectors.getAllListingsAsArray(state),
    totalResults: listingsSelectors.getTotalResults(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchSendInteractionAction: (args: SendInteractionArgs) => dispatch(sendInteraction(args)),
    dispatchUpdateUser: (user: { isIdentified?: boolean; isInternal?: boolean; utmParams?: string }) =>
        dispatch(
            updateUserAction({
                isIdentified: user.isIdentified,
                isInternal: user.isInternal,
                utmParams: user.utmParams,
            }),
        ),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withInteractionEvents,
) as HOCWithInjectedProps<WCInjectedProps>;
