import React from "react";

import styled from "styled-components";

import { HeaderLink } from "../types";
import { DefaultMenuItem, ExternalMenuItem, MenuItemWithButton, MenuItemWithSubMenu } from "./helpers";

export interface INavbarLoginMobileBodyProps {
    className?: string;
    isShowing: boolean;
    onClick?: () => void;
    mobileMenu: HeaderLink[];
}

export const NavbarLoginMobileBody = ({ className, isShowing, onClick, mobileMenu }: INavbarLoginMobileBodyProps) => (
    <Wrapper className={className} isShowing={isShowing} data-test-id="navbar-router-mobile-body">
        <StyledList data-test-id="navbar-router-mobile-menu">
            {mobileMenu.map((link: HeaderLink) => {
                if (link.hasSubMenu) {
                    return <MenuItemWithSubMenu link={link} onClick={onClick} key={`menu-item-${link.id}`} />;
                }

                if (link.isExternal) {
                    return <ExternalMenuItem link={link} onClick={onClick} key={`menu-item-${link.id}`} />;
                }

                if (link.isButton) {
                    return <MenuItemWithButton link={link} onClick={onClick} key={`menu-item-${link.id}`} />;
                }

                return <DefaultMenuItem link={link} onClick={onClick} key={`menu-item-${link.id}`} />;
            })}
        </StyledList>
    </Wrapper>
);

const Wrapper = styled.nav<{ isShowing?: boolean }>`
    display: none;
    ${({ isShowing, theme }) =>
        isShowing === true &&
        `
        display: block;
        background: ${theme.neutral0};
    `}
`;

const StyledList = styled.ul`
    max-height: calc(100vh - 71px);
    overflow: auto;
`;
