import React, { useCallback, useEffect } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import styled from "styled-components/macro";
import { ComponentWithInjectedProps } from "types";

import { Login } from "components/auth/login";
import { RouterFooter } from "components/router-footer";
import { routePaths } from "config/route-paths";
import withInteractionEvents from "hoc/with-interaction-events";

import { footerLinks } from "./footer-links";
import { getLayout, isProductBackground, urlWithSimpleNav } from "./helpers";

interface OwnProps {
    className?: string;
    children: React.ReactNode;
}

interface InjectedProps {
    sendPageLoadedEvent: () => void;
}

type Props = OwnProps & InjectedProps;

export const BranchPageLayout = ({ location, children, sendPageLoadedEvent }: Props & RouteComponentProps) => {
    const handleSendPageLoadedEvent = useCallback(sendPageLoadedEvent, []);

    const { pathname } = location;

    const basicLayoutPages = [routePaths.marketing.login.path];
    const isBasicLayoutPage = basicLayoutPages.includes(pathname);

    useEffect(() => {
        handleSendPageLoadedEvent();
    }, [handleSendPageLoadedEvent]);

    if (isBasicLayoutPage) {
        return <>{children}</>;
    }

    return (
        <PageWrapper>
            {!urlWithSimpleNav(pathname) && <Login />}

            <Wrapper isProductBackground={isProductBackground(pathname)}>{getLayout(pathname, children)}</Wrapper>

            <RouterFooter links={footerLinks} />
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
`;

const Wrapper = styled.div<{ isProductBackground: boolean }>`
    flex: 1 0 auto;

    ${({ theme, isProductBackground }) =>
        isProductBackground &&
        `
            background: ${theme.neutral75};
    `}
`;

export default compose(withRouter, withInteractionEvents)(BranchPageLayout) as ComponentWithInjectedProps<
    Props,
    InjectedProps
>;
